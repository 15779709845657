import CopyToClipboardButton from '@components/CopyToClipboardButton';
import { Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from '@nextui-org/react';

const tags: { description: string; tag: string }[] = [
  {
    description: "Employee's First Name",
    tag: '<%- locals.firstName %>',
  },
  {
    description: "Employee's Surname",
    tag: '<%- locals.surname %>',
  },
  {
    description: 'App Name',
    tag: '<%- locals.appName %>',
  },
  {
    description: 'App Url',
    tag: '<%- locals.appUrl %>',
  },
  {
    description: 'Agency Point Name',
    tag: '<%- locals.pointName %>',
  },
];

const TagTable = () => (
  <Table removeWrapper aria-label="SMS Tags">
    <TableHeader>
      <TableColumn>Description</TableColumn>
      <TableColumn>Tag</TableColumn>
      <TableColumn>
        <span></span>
      </TableColumn>
    </TableHeader>
    <TableBody>
      {tags.map(({ tag, description }, idx) => (
        <TableRow key={idx}>
          <TableCell>{description}</TableCell>
          <TableCell>{tag}</TableCell>
          <TableCell>
            <CopyToClipboardButton text={tag} />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

export default TagTable;
