import PageTitle from '@components/PageTitle';
import { Info, Search, Visibility } from '@mui/icons-material';
import {
  Button,
  Input,
  Spinner,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tabs,
  Tooltip,
  getKeyValue,
  useDisclosure,
} from '@nextui-org/react';
import { useInfiniteScroll } from '@nextui-org/use-infinite-scroll';
import { useAsyncList } from '@react-stately/data';
import { getAgencies, loginWithAgency } from '@services/agencies';
import { loginAsCaregiver } from '@services/agencies/index';
import type { AgencyObject, AgencyStatusCount } from '@services/agencies/types';
import { apiRoutes } from '@services/constants';
import { getStatusCount } from '@services/utils';
import { formatDate } from '@utils/date';
import debounce from 'lodash.debounce';
import type { ChangeEvent, Key } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AgenciesMenu } from './components/AgenciesMenu';
import { ModalAddAgency } from './components/modalAddAgency';
import * as S from './styles';
import { getAgencyColumns } from './utils';

export const handleViewAdminClick = async (agencyId: number): Promise<void> => {
  const response = await loginWithAgency(agencyId);

  window.open(response.url, '_blank');
};

export const handleViewCaregiverClick = async (agencyId: number): Promise<void> => {
  const response = await loginAsCaregiver(agencyId);

  window.open(response.url, '_blank');
};

const Agencies = () => {
  const [filter, setFilter] = useState<string>('');
  const [selected, setSelected] = useState<any>('active');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [statusCount, setStatusCount] = useState<AgencyStatusCount>();
  const [firstLoad, setFirstLoad] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const list = useAsyncList({
    async load({ cursor }) {
      if (cursor) {
        setIsLoading(false);
      }

      setIsLoading(true);
      setFirstLoad(true);

      const res = await getAgencies(cursor, '25', selected, filter);

      setIsLoading(false);
      setFirstLoad(false);

      setHasMore(res.length === 25);

      return {
        items: res,
        cursor: cursor ? (parseInt(cursor, 10) + 1).toString() : '2',
      };
    },
  });

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    list.items = [];
    setIsLoading(true);
    setFilter(e.target.value);
  };

  const getAgencyStatusCount = async () => {
    const statusCountResponse: AgencyStatusCount = await getStatusCount<AgencyStatusCount>(
      apiRoutes.agencies,
      '',
      filter,
    );

    setStatusCount(statusCountResponse);
  };

  useEffect(() => {
    if (!firstLoad) {
      list.reload();
    }

    getAgencyStatusCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, filter]);

  const handleAgencyDetailsClick = (id: number) => {
    navigate(`/agencies/${id}`);
  };

  const debounceOnChange = debounce(handleOnChange, 500);

  const [loaderRef, scrollerRef] = useInfiniteScroll({
    hasMore,
    onLoadMore: list.loadMore,
  });

  const refresh = () => {
    list.reload();
    getAgencyStatusCount();
  };

  const renderCell = useCallback((agency: AgencyObject, columnKey: Key) => {
    const color = status !== 'inactive' ? 'secondary' : 'disabled';

    switch (columnKey) {
      case 'launchDate':
        return formatDate(agency.launchDate);

      case 'created':
        return formatDate(agency.created);

      case 'deactivatedDate':
        return formatDate(agency.deactivatedDate);

      case 'actions':
        return (
          <div>
            <Tooltip content="Details">
              <Info
                color={color}
                style={{ cursor: 'pointer' }}
                onClick={() => handleAgencyDetailsClick(agency.id)}
              />
            </Tooltip>
            <Tooltip content="View Admin">
              <Visibility
                color={color}
                style={{ cursor: 'pointer' }}
                onClick={() => handleViewAdminClick(agency.id)}
              />
            </Tooltip>
          </div>
        );

      default:
        return getKeyValue(agency, columnKey.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <S.Container>
        <PageTitle>Agencies</PageTitle>
        <div className="flex flex-row gap-2">
          <Button
            onClick={onOpen}
            style={{
              background: '#6558F5',
              color: 'white',
            }}
          >
            Add Agency
          </Button>
          <AgenciesMenu />
        </div>
      </S.Container>
      <Input
        aria-label="Search"
        size="sm"
        startContent={<Search />}
        onChange={debounceOnChange}
        placeholder="Search..."
        className="w-72 mt-8 mb-4"
      />
      <Tabs
        color="secondary"
        className="pl-3"
        aria-label="Tabs colors"
        size="sm"
        onSelectionChange={setSelected}
      >
        <Tab key="active" title={`Active (${statusCount?.active || `-`})`} />
        <Tab key="preLaunch" title={`Pre-Launch (${statusCount?.preLaunch || `-`})`} />
        <Tab key="inactive" title={`Inactive (${statusCount?.inactive || `-`})`} />
      </Tabs>
      <Table
        baseRef={scrollerRef}
        aria-label="Agencies table"
        isStriped
        shadow="md"
        bottomContent={
          hasMore ? (
            <div className="flex justify-center w-full">
              <Spinner ref={loaderRef} color="primary" />
            </div>
          ) : null
        }
        classNames={{
          base: 'max-h-[70vh] overflow-auto p-3',
          table: 'max-h-[50vh]',
        }}
      >
        <TableHeader columns={getAgencyColumns(selected)}>
          {(column) => (
            <TableColumn className="text-white bg-purple-500" key={column.key}>
              {column.label}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody
          isLoading={isLoading}
          items={list.items as AgencyObject[]}
          loadingContent={<Spinner color="secondary" />}
          emptyContent={'No rows to display.'}
        >
          {(item: AgencyObject) => (
            <TableRow key={item.id}>
              {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
            </TableRow>
          )}
        </TableBody>
      </Table>
      <ModalAddAgency isOpen={isOpen} refreshFunc={refresh} onClose={onClose} />
    </div>
  );
};

export default Agencies;
