import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Spinner,
} from '@nextui-org/react';
import { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { createAgency } from 'src/api/agency';
import type { CreateAgencyRequest } from 'src/api/agency/types';
import type { FormattedUnassociatedProposal } from 'src/api/proposal/types';
import * as S from './styles';

export function ModalAddAgency({
  isOpen,
  refreshFunc,
  onClose,
}: {
  isOpen: boolean;
  refreshFunc: Function;
  onClose: () => void;
}) {
  const [agencyName, setAgencyName] = useState<string>('');
  const [appName, setAppName] = useState<string>('');
  const [coinName, setCoinName] = useState<string>('');
  const [emr, setEMR] = useState<string>('');
  const [proposalList, setProposalList] = useState<FormattedUnassociatedProposal[]>([]);
  const [proposalAffinityId, setProposalAffinityId] = useState<number>();
  const [querying, setQuerying] = useState<boolean>(false);

  async function refreshProposalUnassociatedList() {
    const formattedPorposal: FormattedUnassociatedProposal[] = [];
    // const result = await getProposalUnassociatedList();
    // const formattedPorposal: FormattedUnassociatedProposal[] = result.map((proposal) => ({
    //   name: proposal.name,
    //   id: proposal.id,
    //   affinityId: proposal.affinityOpportunityId ?? 0,
    // }));

    setProposalList(formattedPorposal);
  }

  useEffect(() => {
    refreshProposalUnassociatedList();
  }, []);

  function clearInputs() {
    setAgencyName('');
    setAppName('');
    setCoinName('');
    setEMR('');
  }

  async function submit() {
    try {
      setQuerying(true);

      if (proposalAffinityId == null) {
        throw new Error('Proposal Affinity ID is required');
      }

      const createAgencyRequest: CreateAgencyRequest = {
        agencyName,
        appName,
        pointName: coinName,
        emr,
        affinityId: proposalAffinityId.toString(),
      };

      await createAgency(createAgencyRequest);
      toast.success('Agency succesfully created');
      clearInputs();
      refreshFunc();
      refreshProposalUnassociatedList();
      setProposalAffinityId(undefined);
      onClose();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      toast.error('Error while creating agency');
    } finally {
      setQuerying(false);
    }
  }

  function validate() {
    if (!agencyName || !appName || !coinName || !emr || !proposalAffinityId) return true;

    return false;
  }

  return (
    <Modal isOpen={isOpen} hideCloseButton={true}>
      <ModalContent>
        <Fragment>
          <ModalHeader>Adding Agency</ModalHeader>
          <ModalBody>
            <Input
              onChange={(e) => setAgencyName(e.target.value)}
              value={agencyName}
              type="text"
              label="Agency Name"
              placeholder="Enter the agency name"
            />
            <Input
              onChange={(e) => setAppName(e.target.value)}
              value={appName}
              type="text"
              label="App Name"
              placeholder="Enter the app name"
            />
            <Input
              onChange={(e) => setCoinName(e.target.value)}
              value={coinName}
              type="text"
              label="Coin Name"
              placeholder="Enter the coin name"
            />
            <Input
              onChange={(e) => setEMR(e.target.value)}
              value={emr}
              type="text"
              label="EMR"
              placeholder="Enter the EMR"
            />
            <S.DropdownContainer>
              <S.DropdownText>Proposal</S.DropdownText>
              <FormControl style={{ flexGrow: 1 }}>
                <InputLabel>Proposal</InputLabel>
                <Select
                  value={proposalAffinityId}
                  label="Proposal"
                  onChange={(e) => setProposalAffinityId(e.target.value as number)}
                >
                  {proposalList.map((proposal, index: number) => (
                    <MenuItem key={`menu-item-${index}`} value={proposal.affinityId}>
                      {proposal.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </S.DropdownContainer>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="light"
              onPress={() => {
                setProposalAffinityId(undefined);
                onClose();
              }}
            >
              Cancel
            </Button>
            <Button isDisabled={validate()} color="primary" onPress={submit}>
              {querying ? <Spinner color="white" /> : 'Create'}
            </Button>
          </ModalFooter>
        </Fragment>
      </ModalContent>
    </Modal>
  );
}
