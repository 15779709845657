import { apiRoutes } from '@services/constants';
import * as fetch from '@utils/fetch';
import type { JobPostObject, JobPostsResponse } from './types';

export const getJobPosts = async (
  cursor: string,
  limit: string,
  agencyId: string,
  filter?: string,
): Promise<JobPostObject[]> => {
  const response = await fetch.get<JobPostsResponse>(apiRoutes.jobPosts.getAll, {
    page: cursor,
    agencyId,
    limit,
    filter: filter || '',
  });

  return response.items;
};

export const updateJobPostDeleteStatus = async (
  publicId: string,
  setIsDeletedTo: boolean,
): Promise<void> =>
  fetch.patch(apiRoutes.jobPosts.updateDeleteStatus, { publicId, setIsDeletedTo });
