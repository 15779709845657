import PageTitle from '@components/PageTitle';
import { Info, PersonOff, Search } from '@mui/icons-material';
import {
  Input,
  Spinner,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tabs,
  Tooltip,
  getKeyValue,
} from '@nextui-org/react';
import { useInfiniteScroll } from '@nextui-org/use-infinite-scroll';
import { useAsyncList } from '@react-stately/data';
import { getAgencies } from '@services/agencies';
import type { AgencyObject } from '@services/agencies/types';
import { apiRoutes } from '@services/constants';
import { getUsers } from '@services/users';
import type { UserAgency, UserStatusCount, UsersObject } from '@services/users/types';
import { getStatusCount } from '@services/utils';
import { formatPhoneNumber } from '@utils/phone';
import debounce from 'lodash.debounce';
import type { ChangeEvent, Key } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DeactivateUserModal } from './DeactivateUserModal';
import { usersColumns } from './utils';

const Users = () => {
  const [filter, setFilter] = useState<string>('');
  const [selected, setSelected] = useState<any>('active');
  const [isLoading, setIsLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [agencies, setAgencies] = useState<any>([]);
  const [selectedAgency, setSelectedAgency] = useState<string>('');
  const [statusCount, setStatusCount] = useState<UserStatusCount>();
  const [selectedUser, setSelectedUser] = useState<UsersObject>();
  const [deactivateModalOpen, setDeactivateModalOpen] = useState<boolean>(false);

  const navigate = useNavigate();

  const list = useAsyncList({
    async load({ cursor }) {
      try {
        setFirstLoad(false);

        if (cursor) {
          setIsLoading(false);
        }

        const res = await getUsers(cursor, '25', selected, selectedAgency, filter);

        setIsLoading(false);
        setHasMore(res.users.length === 25);

        return {
          items: res.users,
          cursor: cursor ? (parseInt(cursor, 10) + 1).toString() : '2',
        };
      } catch (error) {
        setIsLoading(false);
        setHasMore(false);
        throw error;
      }
    },
  });

  const getAllAgencies = async () => {
    const agenciesResponse: AgencyObject[] = await getAgencies(null, '', '');

    if (agenciesResponse) setAgencies(agenciesResponse);
  };

  const getUserStatusCount = async () => {
    const statusCountResponse: UserStatusCount = await getStatusCount<UserStatusCount>(
      apiRoutes.users,
      selectedAgency,
      filter,
    );

    setStatusCount(statusCountResponse);
  };

  useEffect(() => {
    getAllAgencies();
    getUserStatusCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openDeactivateUserModal = (user: UsersObject) => {
    setSelectedUser(user);
    setDeactivateModalOpen(true);
  };

  const closeDeactivateModal = () => {
    setSelectedUser(undefined);
    setDeactivateModalOpen(false);
    list.reload();
  };

  const renderCell = useCallback(
    (user: UsersObject, columnKey: Key) => {
      switch (columnKey) {
        case 'phoneNumber':
          return <span className="whitespace-nowrap">{formatPhoneNumber(user.phoneNumber)}</span>;

        case 'activated':
          return user.activated ? 'Yes' : 'No';

        case 'agencyName':
          return user.agency?.name;

        case 'firstName':
          return `${user.firstName} ${user.surname}`;

        case 'actions':
          return (
            <div className="flex gap-1">
              <Tooltip content="Details">
                <Info
                  color="secondary"
                  style={{ cursor: 'pointer' }}
                  onClick={() => navigate(`/users/${user.id}`)}
                />
              </Tooltip>
              {!user.offboardingDate && (
                <Tooltip content="Deactivate">
                  <PersonOff
                    color="secondary"
                    style={{ cursor: 'pointer' }}
                    onClick={() => openDeactivateUserModal(user)}
                  />
                </Tooltip>
              )}
            </div>
          );

        default:
          return getKeyValue(user, columnKey.toString());
      }
    },
    [navigate],
  );

  const handleOnChangeFilter = (e: ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
  };

  useEffect(() => {
    if (firstLoad) return;

    list.items = [];
    setIsLoading(true);
    getUserStatusCount();
    list.reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, filter, selectedAgency]);

  const debounceOnChange = debounce(handleOnChangeFilter, 500);

  const [loaderRef, scrollerRef] = useInfiniteScroll({
    hasMore,
    onLoadMore: list.loadMore,
  });

  return (
    <>
      <PageTitle>Users</PageTitle>
      <div className="flex content-center gap-5 my-5">
        <Input
          aria-label="Search"
          size="sm"
          startContent={<Search />}
          onChange={debounceOnChange}
          placeholder="Search..."
          className="w-72"
        />
        <select
          className="border-slate-200 pl-2 border-2 rounded-lg"
          onChange={(e: any) => {
            setSelectedAgency(e.target.value);
          }}
        >
          <option key={null} value={''}>
            All Agencies
          </option>
          {agencies.map((agency: UserAgency) => (
            <option key={agency.id} value={agency.id}>
              {agency.name}
            </option>
          ))}
        </select>
      </div>
      <Tabs
        color="secondary"
        className="pl-3"
        aria-label="Tabs colors"
        size="sm"
        onSelectionChange={setSelected}
      >
        <Tab key="active" title={`Active (${statusCount?.active || `-`})`} />
        <Tab key="pending" title={`Pending (${statusCount?.pending || `-`})`} />
        <Tab key="inactive" title={`Inactive (${statusCount?.inactive || `-`})`} />
      </Tabs>
      <Table
        baseRef={scrollerRef}
        aria-label="Users table"
        isStriped
        shadow="md"
        bottomContent={
          hasMore ? (
            <div className="flex justify-center w-full">
              <Spinner ref={loaderRef} color="primary" />
            </div>
          ) : null
        }
        classNames={{
          base: 'max-h-[70vh] overflow-auto p-3',
          table: 'max-h-[50vh]',
        }}
      >
        <TableHeader columns={usersColumns}>
          {(column) => (
            <TableColumn className="text-white bg-purple-500" key={column.key}>
              {column.label}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody
          isLoading={isLoading}
          items={list.items as UsersObject[]}
          loadingContent={<Spinner color="secondary" />}
          emptyContent={'No rows to display.'}
        >
          {(item: UsersObject) => (
            <TableRow key={item.id}>
              {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
            </TableRow>
          )}
        </TableBody>
      </Table>
      {selectedUser ? (
        <DeactivateUserModal
          isOpen={deactivateModalOpen}
          onClose={closeDeactivateModal}
          user={selectedUser}
        />
      ) : null}
    </>
  );
};

export default Users;
