export const campaignsColumns = [
  {
    key: 'name',
    label: 'NAME',
  },
  {
    key: 'type',
    label: 'TYPE',
  },
  {
    key: 'status',
    label: 'STATUS',
  },
  {
    key: 'actions',
    label: 'ACTIONS',
  },
];
