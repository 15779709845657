import type { CampaignStatus } from '@perry/db';

export enum CampaignType {
  REENGAGEMENT = 'REENGAGEMENT',
}

export type ListCampaignsResponse = {
  status: string;
  page: number;
  campaigns: CampaignConfiguration[];
};

export type GetCampaignResponse = {
  status: string;
  campaign: CampaignConfiguration;
};

export type UpdateCampaignResponse = {
  status: string;
  campaign: CampaignConfiguration;
  error?: string[];
  code?: number;
};

export type SaveCampaignResponse = {
  status: string;
  campaign: CampaignConfiguration;
  error?: string[];
  code?: number;
};

export type CampaignConfiguration = {
  id: number;
  name: string;
  type: CampaignType;
  status: CampaignStatus;
  messageTemplate: string;
  daysBetweenSends: number;
  templateSuffix?: string;
  profilesTableName: string;
  abTestEnabled: boolean;
  abTestMessage?: string;
  agencies: {
    id: number;
    name: string;
    publicId: string;
  }[];
};

export type CampaignConfigurationWithoutId = Omit<CampaignConfiguration, 'id'>;

export type ListKpiTablesResponse = {
  status: string;
  tables: string[];
};
