import { apiRoutes } from '@services/constants';
import { getToken } from '@utils/auth';
import * as fetch from '@utils/fetch';
import type {
  CampaignConfigurationWithoutId,
  GetCampaignResponse,
  ListCampaignsResponse,
  ListKpiTablesResponse,
  SaveCampaignResponse,
  UpdateCampaignResponse,
} from './types';

const handleResponse = async <T extends { code?: number; error?: any }>(
  response: T,
): Promise<T> => {
  if (response.error) {
    throw new Error(`HTTP error! status: ${response.code} | ${response.error}`);
  }
  return response;
};

export const getCampaigns = (status?: string, filter?: string): Promise<ListCampaignsResponse> =>
  fetch.get<ListCampaignsResponse>(
    apiRoutes.campaigns,
    {
      ...(status && { status }),
      filter: filter ? filter.toLowerCase() : '',
    },
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    },
  );

export const getCampaign = (id: string): Promise<GetCampaignResponse> =>
  fetch.get<GetCampaignResponse>(
    `${apiRoutes.campaigns}/${id}`,
    {},
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    },
  );

export const updateCampaign = (
  id: string,
  campaign: CampaignConfigurationWithoutId,
  sendToAllAgencies: boolean,
): Promise<UpdateCampaignResponse> =>
  fetch
    .put<
      { campaign: CampaignConfigurationWithoutId; sendToAllAgencies: boolean },
      UpdateCampaignResponse
    >(
      `${apiRoutes.campaigns}/${id}`,
      { campaign, sendToAllAgencies },
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      },
    )
    .then(handleResponse);

export const createCampaign = (
  campaign: CampaignConfigurationWithoutId,
): Promise<SaveCampaignResponse> =>
  fetch
    .post<{ campaign: CampaignConfigurationWithoutId }, SaveCampaignResponse>(
      apiRoutes.campaigns,
      { campaign },
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      },
    )
    .then(handleResponse);

export const getKpiTables = (): Promise<ListKpiTablesResponse> =>
  fetch.get<ListKpiTablesResponse>(
    `${apiRoutes.campaigns}/kpi-tables`,
    {},
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    },
  );
