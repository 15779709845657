export const theme = {
  type: 'light',
  colors: {
    secondary: '#9f2480',
    mainBg: '#FAFAFA',
    lightBg: '#F2F2F2',
    border: '#b0b0b0',
    white: '#FAFAFA',
    black: '#1F1F1F',
    lightGray: '#EAEAEA',
    secondGray: '#f1f3f5',
    darkGray: '#2E2F42',
    primaryLight: '$green200',
    primaryLightHover: '$green300',
    primaryLightActive: '$green400',
    primaryLightContrast: '$green600',
    primary: '#7921A2',
    primaryBorder: '$green500',
    primaryBorderHover: '$green600',
    primarySolidHover: '$green700',
    primarySolidContrast: '$white',
    primaryShadow: '$green500',
    lightPink: '#F5D6F5',
    gradient: 'linear-gradient(112deg, $blue100 -25%, $pink500 -10%, $purple500 80%)',
    link: '#5E1DAD',
    neutral: {
      black: '#000000',
      white: '#FFFFFF',
    },
    gray: {
      50: '#F5F5F9',
      100: '#ECECFB',
      200: '#DFE1F4',
      300: '#BABCD2',
      400: '#9FA2B9',
      500: '#5E6077',
      600: '#383A4D',
      700: '#2A2B3A',
      800: '#171825',
      900: '#010314',
    },
    red: {
      50: '#FFECEF',
      100: '#FFECEF',
      200: '#F6CCCB',
      300: '#F0A9A7',
      400: '#E77975',
      500: '#E23453',
      600: '#CC2B46',
      700: '#AB1D35',
      800: '#AB1D35',
      900: '#660A16',
    },
    orange: {
      50: '#FFFDF2',
      100: '#FFFBE6',
      200: '#FFF3BF',
      300: '#FFEB99',
      400: '#FFD54D',
      500: '#FFB700',
      600: '#E69D00',
      700: '#E28800',
      800: '#995700',
      900: '#733B00',
    },
    green: {
      50: '#EDFAF7',
      100: '#E4F7F3',
      200: '#B9EBDF',
      300: '#91DBC9',
      400: '#4FC29E',
      500: '#19A874',
      600: '#159663',
      700: '#0E7D4B',
      800: '#096638',
      900: '#054D27',
    },
    blue: {
      50: '#F0F6FE',
      100: '#DEE9FC',
      200: '#C4DAFB',
      300: '#9DC4F8',
      400: '#70A3F3',
      500: '#1B68CA',
      600: '#1658B5',
      700: '#0F4396',
      800: '#0A3078',
      900: '#06215C',
    },
    success: {
      200: '#A6F4C5',
      300: '#6CE9A6',
    },
    warning: {
      25: '#FFFCF5',
      300: '#FEC84B',
      500: '#F79009',
      600: '#DC6803',
      700: '#B54708',
    },
    error: {
      25: '#FFFBFA',
      300: '#FDA29B',
      500: '#F04438',
      600: '#D92D20',
      700: '#B42318',
    },
    rose: {
      100: '#D3EFFC',
      500: '#89CCEB',
      600: '#222222',
    },
    overlay: 'rgba(0, 0, 0, 0.40)',
  },
  borders: {
    radius: {
      small: '8px',
      medium: '12px',
      large: '16px',
    },
  },
  shadows: {
    default: `0px 1px 2px 0px rgba(0, 0, 0, 0.06) inset,
        0px 1px 2px 1px #FFF inset, 0px 1px 0px 0px #FFF inset,
        0px 0px 0px 1px rgba(33, 41, 156, 0.15), 
        0px 2px 2px -1px rgba(0, 0, 0, 0.08), 
        0px 1px 1px -1px rgba(0, 0, 0, 0.08)`,
  },
  breakpoints: {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    mobileXL: '480px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1528px',
    desktop: '2560px',
  },
  space: {},
  fonts: {},
};
