"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineRecurringTaskFactory = exports.defineQuestConfigFactory = exports.defineQuestFactory = exports.defineProposalContactFactory = exports.defineProposalFactory = exports.defineProfileTeamFactory = exports.defineProfileGroupFactory = exports.defineProfileFreezePeriodsFactory = exports.defineProfileFactory = exports.defineIntegrationProfileFactory = exports.definePostLikeFactory = exports.definePostAgencyLocationFactory = exports.definePostFactory = exports.definePopupBannerFactory = exports.definePointRewardClaimFactory = exports.definePointRewardFactory = exports.definePayrollEntryFactory = exports.definePasswordSetupLinkFactory = exports.defineOpenShiftApplicationFactory = exports.defineOpenShiftFactory = exports.defineNotificationFactory = exports.defineNotePinFactory = exports.defineInvoiceItemFactory = exports.defineInvoiceFactory = exports.defineInvoiceLineItemFactory = exports.defineGroupAgencyAdminsFactory = exports.defineGroupFactory = exports.defineGiftCardRedeemFactory = exports.defineFeatureFlagFactory = exports.defineExternalTransactionFactory = exports.defineEventLogFactory = exports.defineDailyTipFactory = exports.defineCommunicationLogFactory = exports.defineAgencyTransactionFactory = exports.defineAgencyScrapingErrorFactory = exports.defineAgencyRewardLocationFactory = exports.defineAgencyRewardFactory = exports.defineAgencyLocationFactory = exports.defineAgencyJobPostingFactory = exports.defineAgencyCredentialsFactory = exports.defineAgencyAdminLocationFactory = exports.defineAgencyAdminFactory = exports.defineAgencyActionItemFactory = exports.defineAgencyRewardBalanceFactory = exports.defineAgencyDataIntegrationFactory = exports.defineAgencyFactory = exports.initialize = exports.resetScalarFieldValueGenerator = exports.registerScalarFieldValueGenerator = exports.resetSequence = void 0;
exports.defineCampaignAgencyFactory = exports.defineCampaignConfigurationFactory = exports.defineUsersNoGiftCardRedeemedFactory = exports.defineUnenrolledProfilesFactory = exports.defineAgencyAdminSpotRewardLedgerFactory = exports.defineVerbalOrderSentFactory = exports.defineCommunicationNotesFactory = exports.defineAdmissionWithReferralDataFactory = exports.defineSpotRewardTypeFactory = exports.defineReconciliationProfileFactory = exports.defineMissionConfigFactory = exports.defineRewardMissionFactory = exports.defineRewardTemplateFactory = exports.defineRewardConfigFactory = exports.defineRewardEventFactory = exports.defineIntegrationSyncEventFactory = exports.defineNotificationProfileSettingFactory = exports.defineAgencyRewardLedgerFactory = exports.defineAgencyLocationSpotRewardLedgerFactory = exports.defineNotificationEventFactory = exports.defineNotificationSettingFactory = exports.defineProfileUpdateHistoryFactory = exports.defineEmrFactory = exports.defineSurveyFactory = exports.defineAgencyPointMultiplierFactory = exports.defineAgencyInvoiceCreditFactory = exports.defineAgencyRewardBalanceTargetChangeFactory = exports.defineInvoicingConfigurationFactory = exports.defineAgencyAdminNotificationFactory = exports.defineProfilePointBalanceRecalculationFactory = exports.defineProfilePointBalanceSunkCostFactory = exports.defineProfilePointBalanceFactory = exports.defineIntervalAuditLogFactory = exports.defineVisitChangeLogFactory = exports.defineVisitFactory = exports.defineTaskLogFactory = exports.defineSurveyResultFactory = exports.defineSmsLinkFactory = exports.defineRtpRoutingNumberFactory = exports.defineRoleGroupsFactory = exports.defineRoleAgencyAdminsFactory = exports.defineRoleFactory = exports.defineRewardsBankTransactionsFactory = exports.defineReportFactory = exports.defineReferralRewardFactory = exports.defineReferralFactory = void 0;
const internal_1 = require("@quramy/prisma-fabbrica/lib/internal");
var internal_2 = require("@quramy/prisma-fabbrica/lib/internal");
Object.defineProperty(exports, "resetSequence", { enumerable: true, get: function () { return internal_2.resetSequence; } });
Object.defineProperty(exports, "registerScalarFieldValueGenerator", { enumerable: true, get: function () { return internal_2.registerScalarFieldValueGenerator; } });
Object.defineProperty(exports, "resetScalarFieldValueGenerator", { enumerable: true, get: function () { return internal_2.resetScalarFieldValueGenerator; } });
const initializer = (0, internal_1.createInitializer)();
const { getClient } = initializer;
exports.initialize = initializer.initialize;
const modelFieldDefinitions = [{
        name: "Agency",
        fields: [{
                name: "agencyActionItem",
                type: "AgencyActionItem",
                relationName: "AgencyToAgencyActionItem"
            }, {
                name: "agencyAdmin",
                type: "AgencyAdmin",
                relationName: "AgencyToAgencyAdmin"
            }, {
                name: "agencyCredentials",
                type: "AgencyCredentials",
                relationName: "AgencyToAgencyCredentials"
            }, {
                name: "agencyJobPosting",
                type: "AgencyJobPosting",
                relationName: "AgencyToAgencyJobPosting"
            }, {
                name: "agencyLocation",
                type: "AgencyLocation",
                relationName: "AgencyToAgencyLocation"
            }, {
                name: "agencyReward",
                type: "AgencyReward",
                relationName: "AgencyToAgencyReward"
            }, {
                name: "agencyScrapingError",
                type: "AgencyScrapingError",
                relationName: "AgencyToAgencyScrapingError"
            }, {
                name: "agencyTransaction",
                type: "AgencyTransaction",
                relationName: "AgencyToAgencyTransaction"
            }, {
                name: "dailyTip",
                type: "DailyTip",
                relationName: "AgencyToDailyTip"
            }, {
                name: "featureFlag",
                type: "FeatureFlag",
                relationName: "AgencyToFeatureFlag"
            }, {
                name: "invoice",
                type: "Invoice",
                relationName: "AgencyToInvoice"
            }, {
                name: "openShift",
                type: "OpenShift",
                relationName: "AgencyToOpenShift"
            }, {
                name: "openShiftApplication",
                type: "OpenShiftApplication",
                relationName: "AgencyToOpenShiftApplication"
            }, {
                name: "pointReward",
                type: "PointReward",
                relationName: "AgencyToPointReward"
            }, {
                name: "popupBanner",
                type: "PopupBanner",
                relationName: "AgencyToPopupBanner"
            }, {
                name: "profile",
                type: "Profile",
                relationName: "AgencyToProfile"
            }, {
                name: "profileFreezePeriods",
                type: "ProfileFreezePeriods",
                relationName: "AgencyToProfileFreezePeriods"
            }, {
                name: "profileGroup",
                type: "ProfileGroup",
                relationName: "AgencyToProfileGroup"
            }, {
                name: "profileTeam",
                type: "ProfileTeam",
                relationName: "AgencyToProfileTeam"
            }, {
                name: "proposal",
                type: "Proposal",
                relationName: "AgencyToProposal"
            }, {
                name: "questConfig",
                type: "QuestConfig",
                relationName: "AgencyToQuestConfig"
            }, {
                name: "referral",
                type: "Referral",
                relationName: "AgencyToReferral"
            }, {
                name: "report",
                type: "Report",
                relationName: "AgencyToReport"
            }, {
                name: "rewardsBankTransactions",
                type: "RewardsBankTransactions",
                relationName: "AgencyToRewardsBankTransactions"
            }, {
                name: "visit",
                type: "Visit",
                relationName: "AgencyToVisit"
            }, {
                name: "visitChangeLog",
                type: "VisitChangeLog",
                relationName: "AgencyToVisitChangeLog"
            }, {
                name: "agencyRewardBalances",
                type: "AgencyRewardBalance",
                relationName: "AgencyToAgencyRewardBalance"
            }, {
                name: "intervalAuditLogs",
                type: "IntervalAuditLog",
                relationName: "AgencyToIntervalAuditLog"
            }, {
                name: "invoicingConfiguration",
                type: "InvoicingConfiguration",
                relationName: "AgencyToInvoicingConfiguration"
            }, {
                name: "pointMultipliers",
                type: "AgencyPointMultiplier",
                relationName: "AgencyToAgencyPointMultiplier"
            }, {
                name: "agencyInvoiceCreditHistory",
                type: "AgencyInvoiceCredit",
                relationName: "AgencyToAgencyInvoiceCredit"
            }, {
                name: "rewardBalanceTargetChanges",
                type: "AgencyRewardBalanceTargetChange",
                relationName: "AgencyToAgencyRewardBalanceTargetChange"
            }, {
                name: "agencySurveys",
                type: "Survey",
                relationName: "AgencyToSurvey"
            }, {
                name: "NotificationSetting",
                type: "NotificationSetting",
                relationName: "AgencyToNotificationSetting"
            }, {
                name: "NotificationEvent",
                type: "NotificationEvent",
                relationName: "AgencyToNotificationEvent"
            }, {
                name: "integrationSyncEvents",
                type: "IntegrationSyncEvent",
                relationName: "AgencyToIntegrationSyncEvent"
            }, {
                name: "integrationProfiles",
                type: "IntegrationProfile",
                relationName: "AgencyToIntegrationProfile"
            }, {
                name: "dataIntegrations",
                type: "AgencyDataIntegration",
                relationName: "AgencyToAgencyDataIntegration"
            }, {
                name: "rewardConfigs",
                type: "RewardConfig",
                relationName: "AgencyToRewardConfig"
            }, {
                name: "spotRewardTypes",
                type: "SpotRewardType",
                relationName: "AgencyToSpotRewardType"
            }, {
                name: "AdmissionWithReferralData",
                type: "AdmissionWithReferralData",
                relationName: "AdmissionWithReferralDataToAgency"
            }, {
                name: "CommunicationNotes",
                type: "CommunicationNotes",
                relationName: "AgencyToCommunicationNotes"
            }, {
                name: "VerbalOrderSent",
                type: "VerbalOrderSent",
                relationName: "AgencyToVerbalOrderSent"
            }, {
                name: "campaigns",
                type: "CampaignAgency",
                relationName: "AgencyToCampaignAgency"
            }]
    }, {
        name: "AgencyDataIntegration",
        fields: [{
                name: "agency",
                type: "Agency",
                relationName: "AgencyToAgencyDataIntegration"
            }]
    }, {
        name: "AgencyRewardBalance",
        fields: [{
                name: "rewardTransaction",
                type: "RewardsBankTransactions",
                relationName: "AgencyRewardBalanceToRewardsBankTransactions"
            }, {
                name: "pointTransaction",
                type: "PointReward",
                relationName: "AgencyRewardBalanceToPointReward"
            }, {
                name: "agency",
                type: "Agency",
                relationName: "AgencyToAgencyRewardBalance"
            }]
    }, {
        name: "AgencyActionItem",
        fields: [{
                name: "agency",
                type: "Agency",
                relationName: "AgencyToAgencyActionItem"
            }]
    }, {
        name: "AgencyAdmin",
        fields: [{
                name: "agency",
                type: "Agency",
                relationName: "AgencyToAgencyAdmin"
            }, {
                name: "agencyAdminLocation",
                type: "AgencyAdminLocation",
                relationName: "AgencyAdminToAgencyAdminLocation"
            }, {
                name: "communicationLog",
                type: "CommunicationLog",
                relationName: "AgencyAdminToCommunicationLog"
            }, {
                name: "featureFlag",
                type: "FeatureFlag",
                relationName: "AgencyAdminToFeatureFlag"
            }, {
                name: "groupAgencyAdmins",
                type: "GroupAgencyAdmins",
                relationName: "AgencyAdminToGroupAgencyAdmins"
            }, {
                name: "passwordSetupLink",
                type: "PasswordSetupLink",
                relationName: "AgencyAdminToPasswordSetupLink"
            }, {
                name: "pointReward",
                type: "PointReward",
                relationName: "AgencyAdminToPointReward"
            }, {
                name: "post",
                type: "Post",
                relationName: "AgencyAdminToPost"
            }, {
                name: "postLike",
                type: "PostLike",
                relationName: "AgencyAdminToPostLike"
            }, {
                name: "referral",
                type: "Referral",
                relationName: "AgencyAdminToReferral"
            }, {
                name: "role_agency_admins",
                type: "RoleAgencyAdmins",
                relationName: "AgencyAdminToRoleAgencyAdmins"
            }, {
                name: "agencyAdminNotification",
                type: "AgencyAdminNotification",
                relationName: "AgencyAdminToAgencyAdminNotification"
            }, {
                name: "SpotRewardType",
                type: "SpotRewardType",
                relationName: "AgencyAdminToSpotRewardType"
            }, {
                name: "AgencyAdminSpotRewardLedger",
                type: "AgencyAdminSpotRewardLedger",
                relationName: "AgencyAdminToAgencyAdminSpotRewardLedger"
            }]
    }, {
        name: "AgencyAdminLocation",
        fields: [{
                name: "agencyAdmin",
                type: "AgencyAdmin",
                relationName: "AgencyAdminToAgencyAdminLocation"
            }, {
                name: "agencyLocation",
                type: "AgencyLocation",
                relationName: "AgencyAdminLocationToAgencyLocation"
            }]
    }, {
        name: "AgencyCredentials",
        fields: [{
                name: "agency",
                type: "Agency",
                relationName: "AgencyToAgencyCredentials"
            }]
    }, {
        name: "AgencyJobPosting",
        fields: [{
                name: "agency",
                type: "Agency",
                relationName: "AgencyToAgencyJobPosting"
            }, {
                name: "agency_location",
                type: "AgencyLocation",
                relationName: "AgencyJobPostingToAgencyLocation"
            }, {
                name: "referral",
                type: "Referral",
                relationName: "AgencyJobPostingToReferral"
            }]
    }, {
        name: "AgencyLocation",
        fields: [{
                name: "agencyAdminLocation",
                type: "AgencyAdminLocation",
                relationName: "AgencyAdminLocationToAgencyLocation"
            }, {
                name: "agencyJobPosting",
                type: "AgencyJobPosting",
                relationName: "AgencyJobPostingToAgencyLocation"
            }, {
                name: "agency",
                type: "Agency",
                relationName: "AgencyToAgencyLocation"
            }, {
                name: "post_agency_location",
                type: "PostAgencyLocation",
                relationName: "AgencyLocationToPostAgencyLocation"
            }, {
                name: "profile",
                type: "Profile",
                relationName: "AgencyLocationToProfile"
            }, {
                name: "AgencyLocationSpotRewardLedger",
                type: "AgencyLocationSpotRewardLedger",
                relationName: "AgencyLocationToAgencyLocationSpotRewardLedger"
            }, {
                name: "AgencyRewardLocation",
                type: "AgencyRewardLocation",
                relationName: "AgencyLocationToAgencyRewardLocation"
            }]
    }, {
        name: "AgencyReward",
        fields: [{
                name: "agency",
                type: "Agency",
                relationName: "AgencyToAgencyReward"
            }, {
                name: "AgencyRewardLedger",
                type: "AgencyRewardLedger",
                relationName: "AgencyRewardToAgencyRewardLedger"
            }, {
                name: "AgencyRewardLocation",
                type: "AgencyRewardLocation",
                relationName: "AgencyRewardToAgencyRewardLocation"
            }]
    }, {
        name: "AgencyRewardLocation",
        fields: [{
                name: "agencyReward",
                type: "AgencyReward",
                relationName: "AgencyRewardToAgencyRewardLocation"
            }, {
                name: "agencyLocation",
                type: "AgencyLocation",
                relationName: "AgencyLocationToAgencyRewardLocation"
            }]
    }, {
        name: "AgencyScrapingError",
        fields: [{
                name: "agency",
                type: "Agency",
                relationName: "AgencyToAgencyScrapingError"
            }]
    }, {
        name: "AgencyTransaction",
        fields: [{
                name: "agency",
                type: "Agency",
                relationName: "AgencyToAgencyTransaction"
            }]
    }, {
        name: "CommunicationLog",
        fields: [{
                name: "agencyAdmin",
                type: "AgencyAdmin",
                relationName: "AgencyAdminToCommunicationLog"
            }, {
                name: "profile",
                type: "Profile",
                relationName: "CommunicationLogToProfile"
            }]
    }, {
        name: "DailyTip",
        fields: [{
                name: "agency",
                type: "Agency",
                relationName: "AgencyToDailyTip"
            }, {
                name: "pointReward",
                type: "PointReward",
                relationName: "DailyTipToPointReward"
            }]
    }, {
        name: "EventLog",
        fields: [{
                name: "profile",
                type: "Profile",
                relationName: "EventLogToProfile"
            }]
    }, {
        name: "ExternalTransaction",
        fields: [{
                name: "profile",
                type: "Profile",
                relationName: "ExternalTransactionToProfile"
            }]
    }, {
        name: "FeatureFlag",
        fields: [{
                name: "agencyAdmin",
                type: "AgencyAdmin",
                relationName: "AgencyAdminToFeatureFlag"
            }, {
                name: "agency",
                type: "Agency",
                relationName: "AgencyToFeatureFlag"
            }]
    }, {
        name: "GiftCardRedeem",
        fields: [{
                name: "profile",
                type: "Profile",
                relationName: "GiftCardRedeemToProfile"
            }, {
                name: "post",
                type: "Post",
                relationName: "GiftCardRedeemToPost"
            }, {
                name: "rewardsBankTransactions",
                type: "RewardsBankTransactions",
                relationName: "GiftCardRedeemToRewardsBankTransactions"
            }, {
                name: "profilePointBalanceTransaction",
                type: "ProfilePointBalance",
                relationName: "GiftCardRedeemToProfilePointBalance"
            }]
    }, {
        name: "Group",
        fields: [{
                name: "groupAgencyAdmins",
                type: "GroupAgencyAdmins",
                relationName: "GroupToGroupAgencyAdmins"
            }, {
                name: "roleGroups",
                type: "RoleGroups",
                relationName: "GroupToRoleGroups"
            }]
    }, {
        name: "GroupAgencyAdmins",
        fields: [{
                name: "agencyAdmin",
                type: "AgencyAdmin",
                relationName: "AgencyAdminToGroupAgencyAdmins"
            }, {
                name: "group",
                type: "Group",
                relationName: "GroupToGroupAgencyAdmins"
            }]
    }, {
        name: "InvoiceLineItem",
        fields: [{
                name: "profile",
                type: "Profile",
                relationName: "InvoiceLineItemToProfile"
            }, {
                name: "invoice",
                type: "Invoice",
                relationName: "InvoiceToInvoiceLineItem"
            }]
    }, {
        name: "Invoice",
        fields: [{
                name: "agency",
                type: "Agency",
                relationName: "AgencyToInvoice"
            }, {
                name: "invoiceItem",
                type: "InvoiceItem",
                relationName: "InvoiceToInvoiceItem"
            }, {
                name: "rewardsBankTransactions",
                type: "RewardsBankTransactions",
                relationName: "InvoiceToRewardsBankTransactions"
            }, {
                name: "invoicingConfiguration",
                type: "InvoicingConfiguration",
                relationName: "InvoiceToInvoicingConfiguration"
            }, {
                name: "invoiceLineItems",
                type: "InvoiceLineItem",
                relationName: "InvoiceToInvoiceLineItem"
            }]
    }, {
        name: "InvoiceItem",
        fields: [{
                name: "invoice",
                type: "Invoice",
                relationName: "InvoiceToInvoiceItem"
            }]
    }, {
        name: "NotePin",
        fields: [{
                name: "profile",
                type: "Profile",
                relationName: "NotePinToProfile"
            }, {
                name: "visit",
                type: "Visit",
                relationName: "NotePinToVisit"
            }]
    }, {
        name: "Notification",
        fields: [{
                name: "profile",
                type: "Profile",
                relationName: "NotificationToProfile"
            }]
    }, {
        name: "OpenShift",
        fields: [{
                name: "agency",
                type: "Agency",
                relationName: "AgencyToOpenShift"
            }, {
                name: "openShiftApplication",
                type: "OpenShiftApplication",
                relationName: "OpenShiftToOpenShiftApplication"
            }]
    }, {
        name: "OpenShiftApplication",
        fields: [{
                name: "agency",
                type: "Agency",
                relationName: "AgencyToOpenShiftApplication"
            }, {
                name: "openShift",
                type: "OpenShift",
                relationName: "OpenShiftToOpenShiftApplication"
            }, {
                name: "profile",
                type: "Profile",
                relationName: "OpenShiftApplicationToProfile"
            }]
    }, {
        name: "PasswordSetupLink",
        fields: [{
                name: "agencyAdmin",
                type: "AgencyAdmin",
                relationName: "AgencyAdminToPasswordSetupLink"
            }]
    }, {
        name: "PayrollEntry",
        fields: [{
                name: "profile",
                type: "Profile",
                relationName: "PayrollEntryToProfile"
            }]
    }, {
        name: "PointReward",
        fields: [{
                name: "agencyPointMultiplier",
                type: "AgencyPointMultiplier",
                relationName: "AgencyPointMultiplierToPointReward"
            }, {
                name: "agencyAdmin",
                type: "AgencyAdmin",
                relationName: "AgencyAdminToPointReward"
            }, {
                name: "agency",
                type: "Agency",
                relationName: "AgencyToPointReward"
            }, {
                name: "dailyTip",
                type: "DailyTip",
                relationName: "DailyTipToPointReward"
            }, {
                name: "profile",
                type: "Profile",
                relationName: "PointRewardToProfile"
            }, {
                name: "post",
                type: "Post",
                relationName: "PointRewardToPost"
            }, {
                name: "quest",
                type: "Quest",
                relationName: "PointRewardToQuest"
            }, {
                name: "rewardsBankTransactions",
                type: "RewardsBankTransactions",
                relationName: "PointRewardToRewardsBankTransactions"
            }, {
                name: "agencyRewardBalance",
                type: "AgencyRewardBalance",
                relationName: "AgencyRewardBalanceToPointReward"
            }, {
                name: "pointRewardClaim",
                type: "PointRewardClaim",
                relationName: "PointRewardToPointRewardClaim"
            }, {
                name: "profilePointBalanceTransaction",
                type: "ProfilePointBalance",
                relationName: "PointRewardToProfilePointBalance"
            }, {
                name: "events",
                type: "RewardEvent",
                relationName: "PointRewardToRewardEvent"
            }, {
                name: "rewardMission",
                type: "RewardMission",
                relationName: "PointRewardToRewardMission"
            }]
    }, {
        name: "PointRewardClaim",
        fields: [{
                name: "PointReward",
                type: "PointReward",
                relationName: "PointRewardToPointRewardClaim"
            }]
    }, {
        name: "PopupBanner",
        fields: [{
                name: "agency",
                type: "Agency",
                relationName: "AgencyToPopupBanner"
            }]
    }, {
        name: "Post",
        fields: [{
                name: "giftCardRedeem",
                type: "GiftCardRedeem",
                relationName: "GiftCardRedeemToPost"
            }, {
                name: "pointReward",
                type: "PointReward",
                relationName: "PointRewardToPost"
            }, {
                name: "agencyAdmin",
                type: "AgencyAdmin",
                relationName: "AgencyAdminToPost"
            }, {
                name: "profile",
                type: "Profile",
                relationName: "PostToProfile"
            }, {
                name: "quest",
                type: "Quest",
                relationName: "PostToQuest"
            }, {
                name: "postAgencyLocation",
                type: "PostAgencyLocation",
                relationName: "PostToPostAgencyLocation"
            }, {
                name: "postLike",
                type: "PostLike",
                relationName: "PostToPostLike"
            }]
    }, {
        name: "PostAgencyLocation",
        fields: [{
                name: "agencyLocation",
                type: "AgencyLocation",
                relationName: "AgencyLocationToPostAgencyLocation"
            }, {
                name: "post",
                type: "Post",
                relationName: "PostToPostAgencyLocation"
            }]
    }, {
        name: "PostLike",
        fields: [{
                name: "agencyAdmin",
                type: "AgencyAdmin",
                relationName: "AgencyAdminToPostLike"
            }, {
                name: "profile",
                type: "Profile",
                relationName: "PostLikeToProfile"
            }, {
                name: "post",
                type: "Post",
                relationName: "PostToPostLike"
            }]
    }, {
        name: "IntegrationProfile",
        fields: [{
                name: "profile",
                type: "Profile",
                relationName: "IntegrationProfileToProfile"
            }, {
                name: "sponsor",
                type: "Agency",
                relationName: "AgencyToIntegrationProfile"
            }, {
                name: "emr",
                type: "Emr",
                relationName: "EmrToIntegrationProfile"
            }]
    }, {
        name: "Profile",
        fields: [{
                name: "communicationLog",
                type: "CommunicationLog",
                relationName: "CommunicationLogToProfile"
            }, {
                name: "eventLog",
                type: "EventLog",
                relationName: "EventLogToProfile"
            }, {
                name: "externalTransaction",
                type: "ExternalTransaction",
                relationName: "ExternalTransactionToProfile"
            }, {
                name: "giftCardRedeem",
                type: "GiftCardRedeem",
                relationName: "GiftCardRedeemToProfile"
            }, {
                name: "notePin",
                type: "NotePin",
                relationName: "NotePinToProfile"
            }, {
                name: "notification",
                type: "Notification",
                relationName: "NotificationToProfile"
            }, {
                name: "openShiftApplication",
                type: "OpenShiftApplication",
                relationName: "OpenShiftApplicationToProfile"
            }, {
                name: "payrollEntry",
                type: "PayrollEntry",
                relationName: "PayrollEntryToProfile"
            }, {
                name: "pointReward",
                type: "PointReward",
                relationName: "PointRewardToProfile"
            }, {
                name: "post",
                type: "Post",
                relationName: "PostToProfile"
            }, {
                name: "postLike",
                type: "PostLike",
                relationName: "PostLikeToProfile"
            }, {
                name: "agency",
                type: "Agency",
                relationName: "AgencyToProfile"
            }, {
                name: "agencyLocation",
                type: "AgencyLocation",
                relationName: "AgencyLocationToProfile"
            }, {
                name: "profileGroup",
                type: "ProfileGroup",
                relationName: "ProfileToProfileGroup"
            }, {
                name: "profileTeam",
                type: "ProfileTeam",
                relationName: "ProfileToProfileTeam"
            }, {
                name: "profileFreezePeriods",
                type: "ProfileFreezePeriods",
                relationName: "ProfileToProfileFreezePeriods"
            }, {
                name: "quest",
                type: "Quest",
                relationName: "ProfileToQuest"
            }, {
                name: "recurringTask",
                type: "RecurringTask",
                relationName: "ProfileToRecurringTask"
            }, {
                name: "referralReferralReferencedIdToProfile",
                type: "Referral",
                relationName: "referral_referenced_idToprofile"
            }, {
                name: "referralReferralReferrerIdToProfile",
                type: "Referral",
                relationName: "referral_referrer_idToprofile"
            }, {
                name: "referralReward",
                type: "ReferralReward",
                relationName: "ProfileToReferralReward"
            }, {
                name: "smsLink",
                type: "SmsLink",
                relationName: "ProfileToSmsLink"
            }, {
                name: "surveyResult",
                type: "SurveyResult",
                relationName: "ProfileToSurveyResult"
            }, {
                name: "taskLog",
                type: "TaskLog",
                relationName: "ProfileToTaskLog"
            }, {
                name: "visit",
                type: "Visit",
                relationName: "ProfileToVisit"
            }, {
                name: "intervalAuditLogs",
                type: "IntervalAuditLog",
                relationName: "IntervalAuditLogToProfile"
            }, {
                name: "profilePointBalanceTransactions",
                type: "ProfilePointBalance",
                relationName: "ProfileToProfilePointBalance"
            }, {
                name: "profilePointBalanceSunkCost",
                type: "ProfilePointBalanceSunkCost",
                relationName: "ProfileToProfilePointBalanceSunkCost"
            }, {
                name: "profilePointBalanceRecalculation",
                type: "ProfilePointBalanceRecalculation",
                relationName: "ProfileToProfilePointBalanceRecalculation"
            }, {
                name: "emr",
                type: "Emr",
                relationName: "EmrToProfile"
            }, {
                name: "profileUpdates",
                type: "ProfileUpdateHistory",
                relationName: "ProfileToProfileUpdateHistory"
            }, {
                name: "NotificationEvent",
                type: "NotificationEvent",
                relationName: "NotificationEventToProfile"
            }, {
                name: "NotificationProfileSetting",
                type: "NotificationProfileSetting",
                relationName: "NotificationProfileSettingToProfile"
            }, {
                name: "integrationProfiles",
                type: "IntegrationProfile",
                relationName: "IntegrationProfileToProfile"
            }, {
                name: "ReconciliationProfile",
                type: "ReconciliationProfile",
                relationName: "ProfileToReconciliationProfile"
            }, {
                name: "AdmissionWithReferralData",
                type: "AdmissionWithReferralData",
                relationName: "AdmissionWithReferralDataToProfile"
            }, {
                name: "CommunicationNotes",
                type: "CommunicationNotes",
                relationName: "CommunicationNotesToProfile"
            }, {
                name: "VerbalOrderSent",
                type: "VerbalOrderSent",
                relationName: "ProfileToVerbalOrderSent"
            }, {
                name: "rewardEvents",
                type: "RewardEvent",
                relationName: "ProfileToRewardEvent"
            }, {
                name: "rewardMissions",
                type: "RewardMission",
                relationName: "ProfileToRewardMission"
            }, {
                name: "invoiceLineItems",
                type: "InvoiceLineItem",
                relationName: "InvoiceLineItemToProfile"
            }]
    }, {
        name: "ProfileFreezePeriods",
        fields: [{
                name: "agency",
                type: "Agency",
                relationName: "AgencyToProfileFreezePeriods"
            }, {
                name: "profile",
                type: "Profile",
                relationName: "ProfileToProfileFreezePeriods"
            }]
    }, {
        name: "ProfileGroup",
        fields: [{
                name: "profile",
                type: "Profile",
                relationName: "ProfileToProfileGroup"
            }, {
                name: "agency",
                type: "Agency",
                relationName: "AgencyToProfileGroup"
            }]
    }, {
        name: "ProfileTeam",
        fields: [{
                name: "profile",
                type: "Profile",
                relationName: "ProfileToProfileTeam"
            }, {
                name: "agency",
                type: "Agency",
                relationName: "AgencyToProfileTeam"
            }]
    }, {
        name: "Proposal",
        fields: [{
                name: "agency",
                type: "Agency",
                relationName: "AgencyToProposal"
            }, {
                name: "proposalContact",
                type: "ProposalContact",
                relationName: "ProposalToProposalContact"
            }]
    }, {
        name: "ProposalContact",
        fields: [{
                name: "proposal",
                type: "Proposal",
                relationName: "ProposalToProposalContact"
            }]
    }, {
        name: "Quest",
        fields: [{
                name: "post",
                type: "Post",
                relationName: "PostToQuest"
            }, {
                name: "profile",
                type: "Profile",
                relationName: "ProfileToQuest"
            }, {
                name: "pointReward",
                type: "PointReward",
                relationName: "PointRewardToQuest"
            }]
    }, {
        name: "QuestConfig",
        fields: [{
                name: "agency",
                type: "Agency",
                relationName: "AgencyToQuestConfig"
            }]
    }, {
        name: "RecurringTask",
        fields: [{
                name: "profile",
                type: "Profile",
                relationName: "ProfileToRecurringTask"
            }]
    }, {
        name: "Referral",
        fields: [{
                name: "agency",
                type: "Agency",
                relationName: "AgencyToReferral"
            }, {
                name: "agencyJobPosting",
                type: "AgencyJobPosting",
                relationName: "AgencyJobPostingToReferral"
            }, {
                name: "referenced",
                type: "Profile",
                relationName: "referral_referenced_idToprofile"
            }, {
                name: "referrer",
                type: "Profile",
                relationName: "referral_referrer_idToprofile"
            }, {
                name: "agencyAdmin",
                type: "AgencyAdmin",
                relationName: "AgencyAdminToReferral"
            }, {
                name: "referralRewards",
                type: "ReferralReward",
                relationName: "ReferralToReferralReward"
            }]
    }, {
        name: "ReferralReward",
        fields: [{
                name: "referral",
                type: "Referral",
                relationName: "ReferralToReferralReward"
            }, {
                name: "profile",
                type: "Profile",
                relationName: "ProfileToReferralReward"
            }]
    }, {
        name: "Report",
        fields: [{
                name: "agency",
                type: "Agency",
                relationName: "AgencyToReport"
            }]
    }, {
        name: "RewardsBankTransactions",
        fields: [{
                name: "agency",
                type: "Agency",
                relationName: "AgencyToRewardsBankTransactions"
            }, {
                name: "giftCardRedeem",
                type: "GiftCardRedeem",
                relationName: "GiftCardRedeemToRewardsBankTransactions"
            }, {
                name: "invoice",
                type: "Invoice",
                relationName: "InvoiceToRewardsBankTransactions"
            }, {
                name: "pointReward",
                type: "PointReward",
                relationName: "PointRewardToRewardsBankTransactions"
            }, {
                name: "agencyBalanceTransaction",
                type: "AgencyRewardBalance",
                relationName: "AgencyRewardBalanceToRewardsBankTransactions"
            }]
    }, {
        name: "Role",
        fields: [{
                name: "roleAgencyAdmins",
                type: "RoleAgencyAdmins",
                relationName: "RoleToRoleAgencyAdmins"
            }, {
                name: "roleGroups",
                type: "RoleGroups",
                relationName: "RoleToRoleGroups"
            }]
    }, {
        name: "RoleAgencyAdmins",
        fields: [{
                name: "agencyAdmin",
                type: "AgencyAdmin",
                relationName: "AgencyAdminToRoleAgencyAdmins"
            }, {
                name: "role",
                type: "Role",
                relationName: "RoleToRoleAgencyAdmins"
            }]
    }, {
        name: "RoleGroups",
        fields: [{
                name: "group",
                type: "Group",
                relationName: "GroupToRoleGroups"
            }, {
                name: "role",
                type: "Role",
                relationName: "RoleToRoleGroups"
            }]
    }, {
        name: "RtpRoutingNumber",
        fields: []
    }, {
        name: "SmsLink",
        fields: [{
                name: "profile",
                type: "Profile",
                relationName: "ProfileToSmsLink"
            }]
    }, {
        name: "SurveyResult",
        fields: [{
                name: "profile",
                type: "Profile",
                relationName: "ProfileToSurveyResult"
            }, {
                name: "survey",
                type: "Survey",
                relationName: "SurveyToSurveyResult"
            }]
    }, {
        name: "TaskLog",
        fields: [{
                name: "profile",
                type: "Profile",
                relationName: "ProfileToTaskLog"
            }, {
                name: "visit",
                type: "Visit",
                relationName: "TaskLogToVisit"
            }]
    }, {
        name: "Visit",
        fields: [{
                name: "notePin",
                type: "NotePin",
                relationName: "NotePinToVisit"
            }, {
                name: "taskLog",
                type: "TaskLog",
                relationName: "TaskLogToVisit"
            }, {
                name: "agency",
                type: "Agency",
                relationName: "AgencyToVisit"
            }, {
                name: "profile",
                type: "Profile",
                relationName: "ProfileToVisit"
            }, {
                name: "emr",
                type: "Emr",
                relationName: "EmrToVisit"
            }]
    }, {
        name: "VisitChangeLog",
        fields: [{
                name: "agency",
                type: "Agency",
                relationName: "AgencyToVisitChangeLog"
            }]
    }, {
        name: "IntervalAuditLog",
        fields: [{
                name: "agency",
                type: "Agency",
                relationName: "AgencyToIntervalAuditLog"
            }, {
                name: "profile",
                type: "Profile",
                relationName: "IntervalAuditLogToProfile"
            }]
    }, {
        name: "ProfilePointBalance",
        fields: [{
                name: "profile",
                type: "Profile",
                relationName: "ProfileToProfilePointBalance"
            }, {
                name: "pointReward",
                type: "PointReward",
                relationName: "PointRewardToProfilePointBalance"
            }, {
                name: "giftCardRedeem",
                type: "GiftCardRedeem",
                relationName: "GiftCardRedeemToProfilePointBalance"
            }, {
                name: "profilePointBalanceParent",
                type: "ProfilePointBalance",
                relationName: "profile_point_balance_parent_id_foreign"
            }, {
                name: "profilePointBalanceSunkCost",
                type: "ProfilePointBalanceSunkCost",
                relationName: "ProfilePointBalanceToProfilePointBalanceSunkCost"
            }, {
                name: "profilePointBalanceChildren",
                type: "ProfilePointBalance",
                relationName: "profile_point_balance_parent_id_foreign"
            }]
    }, {
        name: "ProfilePointBalanceSunkCost",
        fields: [{
                name: "profile",
                type: "Profile",
                relationName: "ProfileToProfilePointBalanceSunkCost"
            }, {
                name: "profilePointBalance",
                type: "ProfilePointBalance",
                relationName: "ProfilePointBalanceToProfilePointBalanceSunkCost"
            }]
    }, {
        name: "ProfilePointBalanceRecalculation",
        fields: [{
                name: "profile",
                type: "Profile",
                relationName: "ProfileToProfilePointBalanceRecalculation"
            }]
    }, {
        name: "AgencyAdminNotification",
        fields: [{
                name: "agencyAdmin",
                type: "AgencyAdmin",
                relationName: "AgencyAdminToAgencyAdminNotification"
            }]
    }, {
        name: "InvoicingConfiguration",
        fields: [{
                name: "lastInvoice",
                type: "Invoice",
                relationName: "InvoiceToInvoicingConfiguration"
            }, {
                name: "agency",
                type: "Agency",
                relationName: "AgencyToInvoicingConfiguration"
            }]
    }, {
        name: "AgencyRewardBalanceTargetChange",
        fields: [{
                name: "agency",
                type: "Agency",
                relationName: "AgencyToAgencyRewardBalanceTargetChange"
            }]
    }, {
        name: "AgencyInvoiceCredit",
        fields: [{
                name: "agency",
                type: "Agency",
                relationName: "AgencyToAgencyInvoiceCredit"
            }]
    }, {
        name: "AgencyPointMultiplier",
        fields: [{
                name: "agency",
                type: "Agency",
                relationName: "AgencyToAgencyPointMultiplier"
            }, {
                name: "previousMultiplier",
                type: "AgencyPointMultiplier",
                relationName: "PreviousAgencyPointMultiplier"
            }, {
                name: "nextMultiplier",
                type: "AgencyPointMultiplier",
                relationName: "PreviousAgencyPointMultiplier"
            }, {
                name: "pointRewards",
                type: "PointReward",
                relationName: "AgencyPointMultiplierToPointReward"
            }]
    }, {
        name: "Survey",
        fields: [{
                name: "agency",
                type: "Agency",
                relationName: "AgencyToSurvey"
            }, {
                name: "SurveyResult",
                type: "SurveyResult",
                relationName: "SurveyToSurveyResult"
            }]
    }, {
        name: "Emr",
        fields: [{
                name: "Profile",
                type: "Profile",
                relationName: "EmrToProfile"
            }, {
                name: "Visit",
                type: "Visit",
                relationName: "EmrToVisit"
            }, {
                name: "integrationProfiles",
                type: "IntegrationProfile",
                relationName: "EmrToIntegrationProfile"
            }, {
                name: "AdmissionWithReferralData",
                type: "AdmissionWithReferralData",
                relationName: "AdmissionWithReferralDataToEmr"
            }, {
                name: "VerbalOrderSent",
                type: "VerbalOrderSent",
                relationName: "EmrToVerbalOrderSent"
            }, {
                name: "CommunicationNotes",
                type: "CommunicationNotes",
                relationName: "CommunicationNotesToEmr"
            }]
    }, {
        name: "ProfileUpdateHistory",
        fields: [{
                name: "profile",
                type: "Profile",
                relationName: "ProfileToProfileUpdateHistory"
            }]
    }, {
        name: "NotificationSetting",
        fields: [{
                name: "agency",
                type: "Agency",
                relationName: "AgencyToNotificationSetting"
            }]
    }, {
        name: "NotificationEvent",
        fields: [{
                name: "agency",
                type: "Agency",
                relationName: "AgencyToNotificationEvent"
            }, {
                name: "profile",
                type: "Profile",
                relationName: "NotificationEventToProfile"
            }]
    }, {
        name: "AgencyLocationSpotRewardLedger",
        fields: [{
                name: "AgencyLocation",
                type: "AgencyLocation",
                relationName: "AgencyLocationToAgencyLocationSpotRewardLedger"
            }]
    }, {
        name: "AgencyRewardLedger",
        fields: [{
                name: "AgencyReward",
                type: "AgencyReward",
                relationName: "AgencyRewardToAgencyRewardLedger"
            }]
    }, {
        name: "NotificationProfileSetting",
        fields: [{
                name: "profile",
                type: "Profile",
                relationName: "NotificationProfileSettingToProfile"
            }]
    }, {
        name: "IntegrationSyncEvent",
        fields: [{
                name: "agency",
                type: "Agency",
                relationName: "AgencyToIntegrationSyncEvent"
            }]
    }, {
        name: "RewardEvent",
        fields: [{
                name: "profile",
                type: "Profile",
                relationName: "ProfileToRewardEvent"
            }, {
                name: "pointReward",
                type: "PointReward",
                relationName: "PointRewardToRewardEvent"
            }, {
                name: "mission",
                type: "RewardMission",
                relationName: "RewardEventToRewardMission"
            }, {
                name: "rewardConfig",
                type: "RewardConfig",
                relationName: "RewardConfigToRewardEvent"
            }]
    }, {
        name: "RewardConfig",
        fields: [{
                name: "sponsor",
                type: "Agency",
                relationName: "AgencyToRewardConfig"
            }, {
                name: "missionConfigs",
                type: "MissionConfig",
                relationName: "reward_mission_configs"
            }, {
                name: "rewardEvents",
                type: "RewardEvent",
                relationName: "RewardConfigToRewardEvent"
            }]
    }, {
        name: "RewardTemplate",
        fields: []
    }, {
        name: "RewardMission",
        fields: [{
                name: "events",
                type: "RewardEvent",
                relationName: "RewardEventToRewardMission"
            }, {
                name: "profile",
                type: "Profile",
                relationName: "ProfileToRewardMission"
            }, {
                name: "missionConfig",
                type: "MissionConfig",
                relationName: "MissionConfigToRewardMission"
            }, {
                name: "pointReward",
                type: "PointReward",
                relationName: "PointRewardToRewardMission"
            }]
    }, {
        name: "MissionConfig",
        fields: [{
                name: "missions",
                type: "RewardMission",
                relationName: "MissionConfigToRewardMission"
            }, {
                name: "rewardConfigs",
                type: "RewardConfig",
                relationName: "reward_mission_configs"
            }]
    }, {
        name: "ReconciliationProfile",
        fields: [{
                name: "profile",
                type: "Profile",
                relationName: "ProfileToReconciliationProfile"
            }]
    }, {
        name: "SpotRewardType",
        fields: [{
                name: "agency",
                type: "Agency",
                relationName: "AgencyToSpotRewardType"
            }, {
                name: "agencyAdmin",
                type: "AgencyAdmin",
                relationName: "AgencyAdminToSpotRewardType"
            }]
    }, {
        name: "AdmissionWithReferralData",
        fields: [{
                name: "agency",
                type: "Agency",
                relationName: "AdmissionWithReferralDataToAgency"
            }, {
                name: "profile",
                type: "Profile",
                relationName: "AdmissionWithReferralDataToProfile"
            }, {
                name: "emr",
                type: "Emr",
                relationName: "AdmissionWithReferralDataToEmr"
            }]
    }, {
        name: "CommunicationNotes",
        fields: [{
                name: "agency",
                type: "Agency",
                relationName: "AgencyToCommunicationNotes"
            }, {
                name: "profile",
                type: "Profile",
                relationName: "CommunicationNotesToProfile"
            }, {
                name: "emr",
                type: "Emr",
                relationName: "CommunicationNotesToEmr"
            }]
    }, {
        name: "VerbalOrderSent",
        fields: [{
                name: "agency",
                type: "Agency",
                relationName: "AgencyToVerbalOrderSent"
            }, {
                name: "profile",
                type: "Profile",
                relationName: "ProfileToVerbalOrderSent"
            }, {
                name: "emr",
                type: "Emr",
                relationName: "EmrToVerbalOrderSent"
            }]
    }, {
        name: "AgencyAdminSpotRewardLedger",
        fields: [{
                name: "agencyAdmin",
                type: "AgencyAdmin",
                relationName: "AgencyAdminToAgencyAdminSpotRewardLedger"
            }]
    }, {
        name: "UnenrolledProfiles",
        fields: []
    }, {
        name: "UsersNoGiftCardRedeemed",
        fields: []
    }, {
        name: "CampaignConfiguration",
        fields: [{
                name: "agencies",
                type: "CampaignAgency",
                relationName: "CampaignAgencyToCampaignConfiguration"
            }]
    }, {
        name: "CampaignAgency",
        fields: [{
                name: "campaign",
                type: "CampaignConfiguration",
                relationName: "CampaignAgencyToCampaignConfiguration"
            }, {
                name: "agency",
                type: "Agency",
                relationName: "AgencyToCampaignAgency"
            }]
    }];
function isAgencyinvoicingConfigurationFactory(x) {
    return x?._factoryFor === "InvoicingConfiguration";
}
function autoGenerateAgencyScalarsOrEnums({ seq }) {
    return {
        publicId: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "Agency", fieldName: "publicId", isId: false, isUnique: true, seq }),
        name: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "Agency", fieldName: "name", isId: false, isUnique: true, seq })
    };
}
function defineAgencyFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("Agency", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateAgencyScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver ?? {});
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                invoicingConfiguration: isAgencyinvoicingConfigurationFactory(defaultData.invoicingConfiguration) ? {
                    create: await defaultData.invoicingConfiguration.build()
                } : defaultData.invoicingConfiguration
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().agency.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "Agency",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link Agency} model.
 *
 * @param options
 * @returns factory {@link AgencyFactoryInterface}
 */
exports.defineAgencyFactory = ((options) => {
    return defineAgencyFactoryInternal(options ?? {}, {});
});
exports.defineAgencyFactory.withTransientFields = defaultTransientFieldValues => options => defineAgencyFactoryInternal(options ?? {}, defaultTransientFieldValues);
function isAgencyDataIntegrationagencyFactory(x) {
    return x?._factoryFor === "Agency";
}
function autoGenerateAgencyDataIntegrationScalarsOrEnums({ seq }) {
    return {
        publicId: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "AgencyDataIntegration", fieldName: "publicId", isId: false, isUnique: true, seq }),
        accountToken: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "AgencyDataIntegration", fieldName: "accountToken", isId: false, isUnique: false, seq }),
        name: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "AgencyDataIntegration", fieldName: "name", isId: false, isUnique: false, seq }),
        integrationType: "MERGE"
    };
}
function defineAgencyDataIntegrationFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("AgencyDataIntegration", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateAgencyDataIntegrationScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                agency: isAgencyDataIntegrationagencyFactory(defaultData.agency) ? {
                    create: await defaultData.agency.build()
                } : defaultData.agency
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().agencyDataIntegration.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "AgencyDataIntegration",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link AgencyDataIntegration} model.
 *
 * @param options
 * @returns factory {@link AgencyDataIntegrationFactoryInterface}
 */
exports.defineAgencyDataIntegrationFactory = ((options) => {
    return defineAgencyDataIntegrationFactoryInternal(options, {});
});
exports.defineAgencyDataIntegrationFactory.withTransientFields = defaultTransientFieldValues => options => defineAgencyDataIntegrationFactoryInternal(options, defaultTransientFieldValues);
function isAgencyRewardBalancerewardTransactionFactory(x) {
    return x?._factoryFor === "RewardsBankTransactions";
}
function isAgencyRewardBalancepointTransactionFactory(x) {
    return x?._factoryFor === "PointReward";
}
function isAgencyRewardBalanceagencyFactory(x) {
    return x?._factoryFor === "Agency";
}
function autoGenerateAgencyRewardBalanceScalarsOrEnums({ seq }) {
    return {
        amount: (0, internal_1.getScalarFieldValueGenerator)().Int({ modelName: "AgencyRewardBalance", fieldName: "amount", isId: false, isUnique: false, seq }),
        transactionType: "Debit",
        transactionAmount: (0, internal_1.getScalarFieldValueGenerator)().Int({ modelName: "AgencyRewardBalance", fieldName: "transactionAmount", isId: false, isUnique: false, seq }),
        source: "Invoice",
        idempotencyKey: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "AgencyRewardBalance", fieldName: "idempotencyKey", isId: false, isUnique: true, seq })
    };
}
function defineAgencyRewardBalanceFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("AgencyRewardBalance", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateAgencyRewardBalanceScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                rewardTransaction: isAgencyRewardBalancerewardTransactionFactory(defaultData.rewardTransaction) ? {
                    create: await defaultData.rewardTransaction.build()
                } : defaultData.rewardTransaction,
                pointTransaction: isAgencyRewardBalancepointTransactionFactory(defaultData.pointTransaction) ? {
                    create: await defaultData.pointTransaction.build()
                } : defaultData.pointTransaction,
                agency: isAgencyRewardBalanceagencyFactory(defaultData.agency) ? {
                    create: await defaultData.agency.build()
                } : defaultData.agency
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().agencyRewardBalance.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "AgencyRewardBalance",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link AgencyRewardBalance} model.
 *
 * @param options
 * @returns factory {@link AgencyRewardBalanceFactoryInterface}
 */
exports.defineAgencyRewardBalanceFactory = ((options) => {
    return defineAgencyRewardBalanceFactoryInternal(options, {});
});
exports.defineAgencyRewardBalanceFactory.withTransientFields = defaultTransientFieldValues => options => defineAgencyRewardBalanceFactoryInternal(options, defaultTransientFieldValues);
function isAgencyActionItemagencyFactory(x) {
    return x?._factoryFor === "Agency";
}
function autoGenerateAgencyActionItemScalarsOrEnums({ seq }) {
    return {
        publicId: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "AgencyActionItem", fieldName: "publicId", isId: false, isUnique: true, seq }),
        title: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "AgencyActionItem", fieldName: "title", isId: false, isUnique: false, seq }),
        description: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "AgencyActionItem", fieldName: "description", isId: false, isUnique: false, seq }),
        reference: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "AgencyActionItem", fieldName: "reference", isId: false, isUnique: true, seq }),
        isPositiveAction: (0, internal_1.getScalarFieldValueGenerator)().Boolean({ modelName: "AgencyActionItem", fieldName: "isPositiveAction", isId: false, isUnique: false, seq })
    };
}
function defineAgencyActionItemFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("AgencyActionItem", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateAgencyActionItemScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                agency: isAgencyActionItemagencyFactory(defaultData.agency) ? {
                    create: await defaultData.agency.build()
                } : defaultData.agency
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().agencyActionItem.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "AgencyActionItem",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link AgencyActionItem} model.
 *
 * @param options
 * @returns factory {@link AgencyActionItemFactoryInterface}
 */
exports.defineAgencyActionItemFactory = ((options) => {
    return defineAgencyActionItemFactoryInternal(options, {});
});
exports.defineAgencyActionItemFactory.withTransientFields = defaultTransientFieldValues => options => defineAgencyActionItemFactoryInternal(options, defaultTransientFieldValues);
function isAgencyAdminagencyFactory(x) {
    return x?._factoryFor === "Agency";
}
function autoGenerateAgencyAdminScalarsOrEnums({ seq }) {
    return {
        publicId: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "AgencyAdmin", fieldName: "publicId", isId: false, isUnique: true, seq }),
        email: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "AgencyAdmin", fieldName: "email", isId: false, isUnique: true, seq }),
        language: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "AgencyAdmin", fieldName: "language", isId: false, isUnique: false, seq }),
        firstName: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "AgencyAdmin", fieldName: "firstName", isId: false, isUnique: false, seq }),
        surname: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "AgencyAdmin", fieldName: "surname", isId: false, isUnique: false, seq }),
        pronouns: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "AgencyAdmin", fieldName: "pronouns", isId: false, isUnique: false, seq })
    };
}
function defineAgencyAdminFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("AgencyAdmin", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateAgencyAdminScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                agency: isAgencyAdminagencyFactory(defaultData.agency) ? {
                    create: await defaultData.agency.build()
                } : defaultData.agency
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().agencyAdmin.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "AgencyAdmin",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link AgencyAdmin} model.
 *
 * @param options
 * @returns factory {@link AgencyAdminFactoryInterface}
 */
exports.defineAgencyAdminFactory = ((options) => {
    return defineAgencyAdminFactoryInternal(options, {});
});
exports.defineAgencyAdminFactory.withTransientFields = defaultTransientFieldValues => options => defineAgencyAdminFactoryInternal(options, defaultTransientFieldValues);
function isAgencyAdminLocationagencyAdminFactory(x) {
    return x?._factoryFor === "AgencyAdmin";
}
function isAgencyAdminLocationagencyLocationFactory(x) {
    return x?._factoryFor === "AgencyLocation";
}
function autoGenerateAgencyAdminLocationScalarsOrEnums({ seq }) {
    return {};
}
function defineAgencyAdminLocationFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("AgencyAdminLocation", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateAgencyAdminLocationScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                agencyAdmin: isAgencyAdminLocationagencyAdminFactory(defaultData.agencyAdmin) ? {
                    create: await defaultData.agencyAdmin.build()
                } : defaultData.agencyAdmin,
                agencyLocation: isAgencyAdminLocationagencyLocationFactory(defaultData.agencyLocation) ? {
                    create: await defaultData.agencyLocation.build()
                } : defaultData.agencyLocation
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().agencyAdminLocation.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "AgencyAdminLocation",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link AgencyAdminLocation} model.
 *
 * @param options
 * @returns factory {@link AgencyAdminLocationFactoryInterface}
 */
exports.defineAgencyAdminLocationFactory = ((options) => {
    return defineAgencyAdminLocationFactoryInternal(options, {});
});
exports.defineAgencyAdminLocationFactory.withTransientFields = defaultTransientFieldValues => options => defineAgencyAdminLocationFactoryInternal(options, defaultTransientFieldValues);
function isAgencyCredentialsagencyFactory(x) {
    return x?._factoryFor === "Agency";
}
function autoGenerateAgencyCredentialsScalarsOrEnums({ seq }) {
    return {
        username: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "AgencyCredentials", fieldName: "username", isId: false, isUnique: false, seq }),
        password: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "AgencyCredentials", fieldName: "password", isId: false, isUnique: false, seq }),
        type: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "AgencyCredentials", fieldName: "type", isId: false, isUnique: false, seq })
    };
}
function defineAgencyCredentialsFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("AgencyCredentials", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateAgencyCredentialsScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                agency: isAgencyCredentialsagencyFactory(defaultData.agency) ? {
                    create: await defaultData.agency.build()
                } : defaultData.agency
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().agencyCredentials.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "AgencyCredentials",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link AgencyCredentials} model.
 *
 * @param options
 * @returns factory {@link AgencyCredentialsFactoryInterface}
 */
exports.defineAgencyCredentialsFactory = ((options) => {
    return defineAgencyCredentialsFactoryInternal(options, {});
});
exports.defineAgencyCredentialsFactory.withTransientFields = defaultTransientFieldValues => options => defineAgencyCredentialsFactoryInternal(options, defaultTransientFieldValues);
function isAgencyJobPostingagencyFactory(x) {
    return x?._factoryFor === "Agency";
}
function isAgencyJobPostingagency_locationFactory(x) {
    return x?._factoryFor === "AgencyLocation";
}
function autoGenerateAgencyJobPostingScalarsOrEnums({ seq }) {
    return {
        publicId: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "AgencyJobPosting", fieldName: "publicId", isId: false, isUnique: true, seq }),
        title: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "AgencyJobPosting", fieldName: "title", isId: false, isUnique: false, seq }),
        description: (0, internal_1.getScalarFieldValueGenerator)().Json({ modelName: "AgencyJobPosting", fieldName: "description", isId: false, isUnique: false, seq })
    };
}
function defineAgencyJobPostingFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("AgencyJobPosting", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateAgencyJobPostingScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                agency: isAgencyJobPostingagencyFactory(defaultData.agency) ? {
                    create: await defaultData.agency.build()
                } : defaultData.agency,
                agency_location: isAgencyJobPostingagency_locationFactory(defaultData.agency_location) ? {
                    create: await defaultData.agency_location.build()
                } : defaultData.agency_location
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().agencyJobPosting.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "AgencyJobPosting",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link AgencyJobPosting} model.
 *
 * @param options
 * @returns factory {@link AgencyJobPostingFactoryInterface}
 */
exports.defineAgencyJobPostingFactory = ((options) => {
    return defineAgencyJobPostingFactoryInternal(options, {});
});
exports.defineAgencyJobPostingFactory.withTransientFields = defaultTransientFieldValues => options => defineAgencyJobPostingFactoryInternal(options, defaultTransientFieldValues);
function isAgencyLocationagencyFactory(x) {
    return x?._factoryFor === "Agency";
}
function autoGenerateAgencyLocationScalarsOrEnums({ seq }) {
    return {
        publicId: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "AgencyLocation", fieldName: "publicId", isId: false, isUnique: true, seq }),
        name: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "AgencyLocation", fieldName: "name", isId: false, isUnique: false, seq })
    };
}
function defineAgencyLocationFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("AgencyLocation", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateAgencyLocationScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                agency: isAgencyLocationagencyFactory(defaultData.agency) ? {
                    create: await defaultData.agency.build()
                } : defaultData.agency
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().agencyLocation.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "AgencyLocation",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link AgencyLocation} model.
 *
 * @param options
 * @returns factory {@link AgencyLocationFactoryInterface}
 */
exports.defineAgencyLocationFactory = ((options) => {
    return defineAgencyLocationFactoryInternal(options, {});
});
exports.defineAgencyLocationFactory.withTransientFields = defaultTransientFieldValues => options => defineAgencyLocationFactoryInternal(options, defaultTransientFieldValues);
function isAgencyRewardagencyFactory(x) {
    return x?._factoryFor === "Agency";
}
function autoGenerateAgencyRewardScalarsOrEnums({ seq }) {
    return {
        name: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "AgencyReward", fieldName: "name", isId: false, isUnique: false, seq }),
        type: "ANNIVERSARY",
        value: (0, internal_1.getScalarFieldValueGenerator)().Int({ modelName: "AgencyReward", fieldName: "value", isId: false, isUnique: false, seq })
    };
}
function defineAgencyRewardFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("AgencyReward", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateAgencyRewardScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                agency: isAgencyRewardagencyFactory(defaultData.agency) ? {
                    create: await defaultData.agency.build()
                } : defaultData.agency
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().agencyReward.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "AgencyReward",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link AgencyReward} model.
 *
 * @param options
 * @returns factory {@link AgencyRewardFactoryInterface}
 */
exports.defineAgencyRewardFactory = ((options) => {
    return defineAgencyRewardFactoryInternal(options, {});
});
exports.defineAgencyRewardFactory.withTransientFields = defaultTransientFieldValues => options => defineAgencyRewardFactoryInternal(options, defaultTransientFieldValues);
function isAgencyRewardLocationagencyRewardFactory(x) {
    return x?._factoryFor === "AgencyReward";
}
function isAgencyRewardLocationagencyLocationFactory(x) {
    return x?._factoryFor === "AgencyLocation";
}
function autoGenerateAgencyRewardLocationScalarsOrEnums({ seq }) {
    return {};
}
function defineAgencyRewardLocationFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("AgencyRewardLocation", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateAgencyRewardLocationScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                agencyReward: isAgencyRewardLocationagencyRewardFactory(defaultData.agencyReward) ? {
                    create: await defaultData.agencyReward.build()
                } : defaultData.agencyReward,
                agencyLocation: isAgencyRewardLocationagencyLocationFactory(defaultData.agencyLocation) ? {
                    create: await defaultData.agencyLocation.build()
                } : defaultData.agencyLocation
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().agencyRewardLocation.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "AgencyRewardLocation",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link AgencyRewardLocation} model.
 *
 * @param options
 * @returns factory {@link AgencyRewardLocationFactoryInterface}
 */
exports.defineAgencyRewardLocationFactory = ((options) => {
    return defineAgencyRewardLocationFactoryInternal(options, {});
});
exports.defineAgencyRewardLocationFactory.withTransientFields = defaultTransientFieldValues => options => defineAgencyRewardLocationFactoryInternal(options, defaultTransientFieldValues);
function isAgencyScrapingErroragencyFactory(x) {
    return x?._factoryFor === "Agency";
}
function autoGenerateAgencyScrapingErrorScalarsOrEnums({ seq }) {
    return {
        errorContent: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "AgencyScrapingError", fieldName: "errorContent", isId: false, isUnique: false, seq })
    };
}
function defineAgencyScrapingErrorFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("AgencyScrapingError", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateAgencyScrapingErrorScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                agency: isAgencyScrapingErroragencyFactory(defaultData.agency) ? {
                    create: await defaultData.agency.build()
                } : defaultData.agency
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().agencyScrapingError.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "AgencyScrapingError",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link AgencyScrapingError} model.
 *
 * @param options
 * @returns factory {@link AgencyScrapingErrorFactoryInterface}
 */
exports.defineAgencyScrapingErrorFactory = ((options) => {
    return defineAgencyScrapingErrorFactoryInternal(options, {});
});
exports.defineAgencyScrapingErrorFactory.withTransientFields = defaultTransientFieldValues => options => defineAgencyScrapingErrorFactoryInternal(options, defaultTransientFieldValues);
function isAgencyTransactionagencyFactory(x) {
    return x?._factoryFor === "Agency";
}
function autoGenerateAgencyTransactionScalarsOrEnums({ seq }) {
    return {
        externalId: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "AgencyTransaction", fieldName: "externalId", isId: false, isUnique: true, seq }),
        amount: (0, internal_1.getScalarFieldValueGenerator)().Int({ modelName: "AgencyTransaction", fieldName: "amount", isId: false, isUnique: false, seq }),
        accountId: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "AgencyTransaction", fieldName: "accountId", isId: false, isUnique: false, seq }),
        accountNumber: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "AgencyTransaction", fieldName: "accountNumber", isId: false, isUnique: false, seq }),
        companyName: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "AgencyTransaction", fieldName: "companyName", isId: false, isUnique: false, seq }),
        type: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "AgencyTransaction", fieldName: "type", isId: false, isUnique: false, seq }),
        funding: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "AgencyTransaction", fieldName: "funding", isId: false, isUnique: false, seq })
    };
}
function defineAgencyTransactionFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("AgencyTransaction", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateAgencyTransactionScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                agency: isAgencyTransactionagencyFactory(defaultData.agency) ? {
                    create: await defaultData.agency.build()
                } : defaultData.agency
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().agencyTransaction.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "AgencyTransaction",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link AgencyTransaction} model.
 *
 * @param options
 * @returns factory {@link AgencyTransactionFactoryInterface}
 */
exports.defineAgencyTransactionFactory = ((options) => {
    return defineAgencyTransactionFactoryInternal(options, {});
});
exports.defineAgencyTransactionFactory.withTransientFields = defaultTransientFieldValues => options => defineAgencyTransactionFactoryInternal(options, defaultTransientFieldValues);
function isCommunicationLogagencyAdminFactory(x) {
    return x?._factoryFor === "AgencyAdmin";
}
function isCommunicationLogprofileFactory(x) {
    return x?._factoryFor === "Profile";
}
function autoGenerateCommunicationLogScalarsOrEnums({ seq }) {
    return {
        type: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "CommunicationLog", fieldName: "type", isId: false, isUnique: false, seq }),
        template: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "CommunicationLog", fieldName: "template", isId: false, isUnique: false, seq })
    };
}
function defineCommunicationLogFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("CommunicationLog", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateCommunicationLogScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver ?? {});
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                agencyAdmin: isCommunicationLogagencyAdminFactory(defaultData.agencyAdmin) ? {
                    create: await defaultData.agencyAdmin.build()
                } : defaultData.agencyAdmin,
                profile: isCommunicationLogprofileFactory(defaultData.profile) ? {
                    create: await defaultData.profile.build()
                } : defaultData.profile
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().communicationLog.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "CommunicationLog",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link CommunicationLog} model.
 *
 * @param options
 * @returns factory {@link CommunicationLogFactoryInterface}
 */
exports.defineCommunicationLogFactory = ((options) => {
    return defineCommunicationLogFactoryInternal(options ?? {}, {});
});
exports.defineCommunicationLogFactory.withTransientFields = defaultTransientFieldValues => options => defineCommunicationLogFactoryInternal(options ?? {}, defaultTransientFieldValues);
function isDailyTipagencyFactory(x) {
    return x?._factoryFor === "Agency";
}
function autoGenerateDailyTipScalarsOrEnums({ seq }) {
    return {
        title: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "DailyTip", fieldName: "title", isId: false, isUnique: false, seq }),
        description: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "DailyTip", fieldName: "description", isId: false, isUnique: false, seq }),
        date: (0, internal_1.getScalarFieldValueGenerator)().DateTime({ modelName: "DailyTip", fieldName: "date", isId: false, isUnique: false, seq })
    };
}
function defineDailyTipFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("DailyTip", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateDailyTipScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                agency: isDailyTipagencyFactory(defaultData.agency) ? {
                    create: await defaultData.agency.build()
                } : defaultData.agency
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().dailyTip.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "DailyTip",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link DailyTip} model.
 *
 * @param options
 * @returns factory {@link DailyTipFactoryInterface}
 */
exports.defineDailyTipFactory = ((options) => {
    return defineDailyTipFactoryInternal(options, {});
});
exports.defineDailyTipFactory.withTransientFields = defaultTransientFieldValues => options => defineDailyTipFactoryInternal(options, defaultTransientFieldValues);
function isEventLogprofileFactory(x) {
    return x?._factoryFor === "Profile";
}
function autoGenerateEventLogScalarsOrEnums({ seq }) {
    return {
        name: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "EventLog", fieldName: "name", isId: false, isUnique: false, seq }),
        description: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "EventLog", fieldName: "description", isId: false, isUnique: false, seq })
    };
}
function defineEventLogFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("EventLog", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateEventLogScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                profile: isEventLogprofileFactory(defaultData.profile) ? {
                    create: await defaultData.profile.build()
                } : defaultData.profile
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().eventLog.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "EventLog",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link EventLog} model.
 *
 * @param options
 * @returns factory {@link EventLogFactoryInterface}
 */
exports.defineEventLogFactory = ((options) => {
    return defineEventLogFactoryInternal(options, {});
});
exports.defineEventLogFactory.withTransientFields = defaultTransientFieldValues => options => defineEventLogFactoryInternal(options, defaultTransientFieldValues);
function isExternalTransactionprofileFactory(x) {
    return x?._factoryFor === "Profile";
}
function autoGenerateExternalTransactionScalarsOrEnums({ seq }) {
    return {
        publicId: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "ExternalTransaction", fieldName: "publicId", isId: false, isUnique: true, seq }),
        transactionType: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "ExternalTransaction", fieldName: "transactionType", isId: false, isUnique: false, seq }),
        status: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "ExternalTransaction", fieldName: "status", isId: false, isUnique: false, seq }),
        payoutMethod: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "ExternalTransaction", fieldName: "payoutMethod", isId: false, isUnique: false, seq }),
        transactionAmount: (0, internal_1.getScalarFieldValueGenerator)().Int({ modelName: "ExternalTransaction", fieldName: "transactionAmount", isId: false, isUnique: false, seq }),
        transferredAmount: (0, internal_1.getScalarFieldValueGenerator)().Int({ modelName: "ExternalTransaction", fieldName: "transferredAmount", isId: false, isUnique: false, seq }),
        transactionFee: (0, internal_1.getScalarFieldValueGenerator)().Int({ modelName: "ExternalTransaction", fieldName: "transactionFee", isId: false, isUnique: false, seq }),
        internalCost: (0, internal_1.getScalarFieldValueGenerator)().Int({ modelName: "ExternalTransaction", fieldName: "internalCost", isId: false, isUnique: false, seq })
    };
}
function defineExternalTransactionFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("ExternalTransaction", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateExternalTransactionScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                profile: isExternalTransactionprofileFactory(defaultData.profile) ? {
                    create: await defaultData.profile.build()
                } : defaultData.profile
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().externalTransaction.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "ExternalTransaction",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link ExternalTransaction} model.
 *
 * @param options
 * @returns factory {@link ExternalTransactionFactoryInterface}
 */
exports.defineExternalTransactionFactory = ((options) => {
    return defineExternalTransactionFactoryInternal(options, {});
});
exports.defineExternalTransactionFactory.withTransientFields = defaultTransientFieldValues => options => defineExternalTransactionFactoryInternal(options, defaultTransientFieldValues);
function isFeatureFlagagencyAdminFactory(x) {
    return x?._factoryFor === "AgencyAdmin";
}
function isFeatureFlagagencyFactory(x) {
    return x?._factoryFor === "Agency";
}
function autoGenerateFeatureFlagScalarsOrEnums({ seq }) {
    return {
        name: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "FeatureFlag", fieldName: "name", isId: false, isUnique: false, seq })
    };
}
function defineFeatureFlagFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("FeatureFlag", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateFeatureFlagScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                agencyAdmin: isFeatureFlagagencyAdminFactory(defaultData.agencyAdmin) ? {
                    create: await defaultData.agencyAdmin.build()
                } : defaultData.agencyAdmin,
                agency: isFeatureFlagagencyFactory(defaultData.agency) ? {
                    create: await defaultData.agency.build()
                } : defaultData.agency
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().featureFlag.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "FeatureFlag",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link FeatureFlag} model.
 *
 * @param options
 * @returns factory {@link FeatureFlagFactoryInterface}
 */
exports.defineFeatureFlagFactory = ((options) => {
    return defineFeatureFlagFactoryInternal(options, {});
});
exports.defineFeatureFlagFactory.withTransientFields = defaultTransientFieldValues => options => defineFeatureFlagFactoryInternal(options, defaultTransientFieldValues);
function isGiftCardRedeemprofileFactory(x) {
    return x?._factoryFor === "Profile";
}
function isGiftCardRedeempostFactory(x) {
    return x?._factoryFor === "Post";
}
function isGiftCardRedeemrewardsBankTransactionsFactory(x) {
    return x?._factoryFor === "RewardsBankTransactions";
}
function isGiftCardRedeemprofilePointBalanceTransactionFactory(x) {
    return x?._factoryFor === "ProfilePointBalance";
}
function autoGenerateGiftCardRedeemScalarsOrEnums({ seq }) {
    return {
        publicId: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "GiftCardRedeem", fieldName: "publicId", isId: false, isUnique: true, seq }),
        type: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "GiftCardRedeem", fieldName: "type", isId: false, isUnique: false, seq }),
        giftcardType: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "GiftCardRedeem", fieldName: "giftcardType", isId: false, isUnique: false, seq }),
        points: (0, internal_1.getScalarFieldValueGenerator)().Int({ modelName: "GiftCardRedeem", fieldName: "points", isId: false, isUnique: false, seq }),
        giftcardValue: (0, internal_1.getScalarFieldValueGenerator)().Int({ modelName: "GiftCardRedeem", fieldName: "giftcardValue", isId: false, isUnique: false, seq })
    };
}
function defineGiftCardRedeemFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("GiftCardRedeem", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateGiftCardRedeemScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                profile: isGiftCardRedeemprofileFactory(defaultData.profile) ? {
                    create: await defaultData.profile.build()
                } : defaultData.profile,
                post: isGiftCardRedeempostFactory(defaultData.post) ? {
                    create: await defaultData.post.build()
                } : defaultData.post,
                rewardsBankTransactions: isGiftCardRedeemrewardsBankTransactionsFactory(defaultData.rewardsBankTransactions) ? {
                    create: await defaultData.rewardsBankTransactions.build()
                } : defaultData.rewardsBankTransactions,
                profilePointBalanceTransaction: isGiftCardRedeemprofilePointBalanceTransactionFactory(defaultData.profilePointBalanceTransaction) ? {
                    create: await defaultData.profilePointBalanceTransaction.build()
                } : defaultData.profilePointBalanceTransaction
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().giftCardRedeem.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "GiftCardRedeem",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link GiftCardRedeem} model.
 *
 * @param options
 * @returns factory {@link GiftCardRedeemFactoryInterface}
 */
exports.defineGiftCardRedeemFactory = ((options) => {
    return defineGiftCardRedeemFactoryInternal(options, {});
});
exports.defineGiftCardRedeemFactory.withTransientFields = defaultTransientFieldValues => options => defineGiftCardRedeemFactoryInternal(options, defaultTransientFieldValues);
function autoGenerateGroupScalarsOrEnums({ seq }) {
    return {
        name: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "Group", fieldName: "name", isId: false, isUnique: false, seq })
    };
}
function defineGroupFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("Group", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateGroupScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver ?? {});
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {};
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().group.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "Group",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link Group} model.
 *
 * @param options
 * @returns factory {@link GroupFactoryInterface}
 */
exports.defineGroupFactory = ((options) => {
    return defineGroupFactoryInternal(options ?? {}, {});
});
exports.defineGroupFactory.withTransientFields = defaultTransientFieldValues => options => defineGroupFactoryInternal(options ?? {}, defaultTransientFieldValues);
function isGroupAgencyAdminsagencyAdminFactory(x) {
    return x?._factoryFor === "AgencyAdmin";
}
function isGroupAgencyAdminsgroupFactory(x) {
    return x?._factoryFor === "Group";
}
function autoGenerateGroupAgencyAdminsScalarsOrEnums({ seq }) {
    return {};
}
function defineGroupAgencyAdminsFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("GroupAgencyAdmins", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateGroupAgencyAdminsScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                agencyAdmin: isGroupAgencyAdminsagencyAdminFactory(defaultData.agencyAdmin) ? {
                    create: await defaultData.agencyAdmin.build()
                } : defaultData.agencyAdmin,
                group: isGroupAgencyAdminsgroupFactory(defaultData.group) ? {
                    create: await defaultData.group.build()
                } : defaultData.group
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            groupId: inputData.groupId,
            agencyAdminId: inputData.agencyAdminId
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().groupAgencyAdmins.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "GroupAgencyAdmins",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link GroupAgencyAdmins} model.
 *
 * @param options
 * @returns factory {@link GroupAgencyAdminsFactoryInterface}
 */
exports.defineGroupAgencyAdminsFactory = ((options) => {
    return defineGroupAgencyAdminsFactoryInternal(options, {});
});
exports.defineGroupAgencyAdminsFactory.withTransientFields = defaultTransientFieldValues => options => defineGroupAgencyAdminsFactoryInternal(options, defaultTransientFieldValues);
function isInvoiceLineItemprofileFactory(x) {
    return x?._factoryFor === "Profile";
}
function isInvoiceLineIteminvoiceFactory(x) {
    return x?._factoryFor === "Invoice";
}
function autoGenerateInvoiceLineItemScalarsOrEnums({ seq }) {
    return {};
}
function defineInvoiceLineItemFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("InvoiceLineItem", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateInvoiceLineItemScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                profile: isInvoiceLineItemprofileFactory(defaultData.profile) ? {
                    create: await defaultData.profile.build()
                } : defaultData.profile,
                invoice: isInvoiceLineIteminvoiceFactory(defaultData.invoice) ? {
                    create: await defaultData.invoice.build()
                } : defaultData.invoice
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().invoiceLineItem.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "InvoiceLineItem",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link InvoiceLineItem} model.
 *
 * @param options
 * @returns factory {@link InvoiceLineItemFactoryInterface}
 */
exports.defineInvoiceLineItemFactory = ((options) => {
    return defineInvoiceLineItemFactoryInternal(options, {});
});
exports.defineInvoiceLineItemFactory.withTransientFields = defaultTransientFieldValues => options => defineInvoiceLineItemFactoryInternal(options, defaultTransientFieldValues);
function isInvoiceagencyFactory(x) {
    return x?._factoryFor === "Agency";
}
function isInvoiceinvoicingConfigurationFactory(x) {
    return x?._factoryFor === "InvoicingConfiguration";
}
function autoGenerateInvoiceScalarsOrEnums({ seq }) {
    return {
        stripeInvoiceId: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "Invoice", fieldName: "stripeInvoiceId", isId: false, isUnique: true, seq }),
        status: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "Invoice", fieldName: "status", isId: false, isUnique: false, seq })
    };
}
function defineInvoiceFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("Invoice", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateInvoiceScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                agency: isInvoiceagencyFactory(defaultData.agency) ? {
                    create: await defaultData.agency.build()
                } : defaultData.agency,
                invoicingConfiguration: isInvoiceinvoicingConfigurationFactory(defaultData.invoicingConfiguration) ? {
                    create: await defaultData.invoicingConfiguration.build()
                } : defaultData.invoicingConfiguration
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().invoice.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "Invoice",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link Invoice} model.
 *
 * @param options
 * @returns factory {@link InvoiceFactoryInterface}
 */
exports.defineInvoiceFactory = ((options) => {
    return defineInvoiceFactoryInternal(options, {});
});
exports.defineInvoiceFactory.withTransientFields = defaultTransientFieldValues => options => defineInvoiceFactoryInternal(options, defaultTransientFieldValues);
function isInvoiceIteminvoiceFactory(x) {
    return x?._factoryFor === "Invoice";
}
function autoGenerateInvoiceItemScalarsOrEnums({ seq }) {
    return {
        type: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "InvoiceItem", fieldName: "type", isId: false, isUnique: false, seq })
    };
}
function defineInvoiceItemFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("InvoiceItem", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateInvoiceItemScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                invoice: isInvoiceIteminvoiceFactory(defaultData.invoice) ? {
                    create: await defaultData.invoice.build()
                } : defaultData.invoice
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().invoiceItem.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "InvoiceItem",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link InvoiceItem} model.
 *
 * @param options
 * @returns factory {@link InvoiceItemFactoryInterface}
 */
exports.defineInvoiceItemFactory = ((options) => {
    return defineInvoiceItemFactoryInternal(options, {});
});
exports.defineInvoiceItemFactory.withTransientFields = defaultTransientFieldValues => options => defineInvoiceItemFactoryInternal(options, defaultTransientFieldValues);
function isNotePinprofileFactory(x) {
    return x?._factoryFor === "Profile";
}
function isNotePinvisitFactory(x) {
    return x?._factoryFor === "Visit";
}
function autoGenerateNotePinScalarsOrEnums({ seq }) {
    return {};
}
function defineNotePinFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("NotePin", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateNotePinScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                profile: isNotePinprofileFactory(defaultData.profile) ? {
                    create: await defaultData.profile.build()
                } : defaultData.profile,
                visit: isNotePinvisitFactory(defaultData.visit) ? {
                    create: await defaultData.visit.build()
                } : defaultData.visit
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            profileId: inputData.profileId,
            visitId: inputData.visitId
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().notePin.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "NotePin",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link NotePin} model.
 *
 * @param options
 * @returns factory {@link NotePinFactoryInterface}
 */
exports.defineNotePinFactory = ((options) => {
    return defineNotePinFactoryInternal(options, {});
});
exports.defineNotePinFactory.withTransientFields = defaultTransientFieldValues => options => defineNotePinFactoryInternal(options, defaultTransientFieldValues);
function isNotificationprofileFactory(x) {
    return x?._factoryFor === "Profile";
}
function autoGenerateNotificationScalarsOrEnums({ seq }) {
    return {
        publicId: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "Notification", fieldName: "publicId", isId: false, isUnique: true, seq }),
        type: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "Notification", fieldName: "type", isId: false, isUnique: false, seq }),
        data: (0, internal_1.getScalarFieldValueGenerator)().Json({ modelName: "Notification", fieldName: "data", isId: false, isUnique: false, seq })
    };
}
function defineNotificationFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("Notification", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateNotificationScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                profile: isNotificationprofileFactory(defaultData.profile) ? {
                    create: await defaultData.profile.build()
                } : defaultData.profile
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().notification.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "Notification",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link Notification} model.
 *
 * @param options
 * @returns factory {@link NotificationFactoryInterface}
 */
exports.defineNotificationFactory = ((options) => {
    return defineNotificationFactoryInternal(options, {});
});
exports.defineNotificationFactory.withTransientFields = defaultTransientFieldValues => options => defineNotificationFactoryInternal(options, defaultTransientFieldValues);
function isOpenShiftagencyFactory(x) {
    return x?._factoryFor === "Agency";
}
function autoGenerateOpenShiftScalarsOrEnums({ seq }) {
    return {
        externalId: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "OpenShift", fieldName: "externalId", isId: false, isUnique: true, seq }),
        startsAt: (0, internal_1.getScalarFieldValueGenerator)().DateTime({ modelName: "OpenShift", fieldName: "startsAt", isId: false, isUnique: false, seq }),
        finishesAt: (0, internal_1.getScalarFieldValueGenerator)().DateTime({ modelName: "OpenShift", fieldName: "finishesAt", isId: false, isUnique: false, seq })
    };
}
function defineOpenShiftFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("OpenShift", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateOpenShiftScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                agency: isOpenShiftagencyFactory(defaultData.agency) ? {
                    create: await defaultData.agency.build()
                } : defaultData.agency
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().openShift.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "OpenShift",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link OpenShift} model.
 *
 * @param options
 * @returns factory {@link OpenShiftFactoryInterface}
 */
exports.defineOpenShiftFactory = ((options) => {
    return defineOpenShiftFactoryInternal(options, {});
});
exports.defineOpenShiftFactory.withTransientFields = defaultTransientFieldValues => options => defineOpenShiftFactoryInternal(options, defaultTransientFieldValues);
function isOpenShiftApplicationagencyFactory(x) {
    return x?._factoryFor === "Agency";
}
function isOpenShiftApplicationopenShiftFactory(x) {
    return x?._factoryFor === "OpenShift";
}
function isOpenShiftApplicationprofileFactory(x) {
    return x?._factoryFor === "Profile";
}
function autoGenerateOpenShiftApplicationScalarsOrEnums({ seq }) {
    return {};
}
function defineOpenShiftApplicationFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("OpenShiftApplication", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateOpenShiftApplicationScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                agency: isOpenShiftApplicationagencyFactory(defaultData.agency) ? {
                    create: await defaultData.agency.build()
                } : defaultData.agency,
                openShift: isOpenShiftApplicationopenShiftFactory(defaultData.openShift) ? {
                    create: await defaultData.openShift.build()
                } : defaultData.openShift,
                profile: isOpenShiftApplicationprofileFactory(defaultData.profile) ? {
                    create: await defaultData.profile.build()
                } : defaultData.profile
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().openShiftApplication.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "OpenShiftApplication",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link OpenShiftApplication} model.
 *
 * @param options
 * @returns factory {@link OpenShiftApplicationFactoryInterface}
 */
exports.defineOpenShiftApplicationFactory = ((options) => {
    return defineOpenShiftApplicationFactoryInternal(options, {});
});
exports.defineOpenShiftApplicationFactory.withTransientFields = defaultTransientFieldValues => options => defineOpenShiftApplicationFactoryInternal(options, defaultTransientFieldValues);
function isPasswordSetupLinkagencyAdminFactory(x) {
    return x?._factoryFor === "AgencyAdmin";
}
function autoGeneratePasswordSetupLinkScalarsOrEnums({ seq }) {
    return {
        token: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "PasswordSetupLink", fieldName: "token", isId: false, isUnique: true, seq })
    };
}
function definePasswordSetupLinkFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("PasswordSetupLink", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGeneratePasswordSetupLinkScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                agencyAdmin: isPasswordSetupLinkagencyAdminFactory(defaultData.agencyAdmin) ? {
                    create: await defaultData.agencyAdmin.build()
                } : defaultData.agencyAdmin
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().passwordSetupLink.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "PasswordSetupLink",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link PasswordSetupLink} model.
 *
 * @param options
 * @returns factory {@link PasswordSetupLinkFactoryInterface}
 */
exports.definePasswordSetupLinkFactory = ((options) => {
    return definePasswordSetupLinkFactoryInternal(options, {});
});
exports.definePasswordSetupLinkFactory.withTransientFields = defaultTransientFieldValues => options => definePasswordSetupLinkFactoryInternal(options, defaultTransientFieldValues);
function isPayrollEntryprofileFactory(x) {
    return x?._factoryFor === "Profile";
}
function autoGeneratePayrollEntryScalarsOrEnums({ seq }) {
    return {
        earnedInUsd: (0, internal_1.getScalarFieldValueGenerator)().Int({ modelName: "PayrollEntry", fieldName: "earnedInUsd", isId: false, isUnique: false, seq }),
        date: (0, internal_1.getScalarFieldValueGenerator)().DateTime({ modelName: "PayrollEntry", fieldName: "date", isId: false, isUnique: false, seq })
    };
}
function definePayrollEntryFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("PayrollEntry", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGeneratePayrollEntryScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                profile: isPayrollEntryprofileFactory(defaultData.profile) ? {
                    create: await defaultData.profile.build()
                } : defaultData.profile
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().payrollEntry.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "PayrollEntry",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link PayrollEntry} model.
 *
 * @param options
 * @returns factory {@link PayrollEntryFactoryInterface}
 */
exports.definePayrollEntryFactory = ((options) => {
    return definePayrollEntryFactoryInternal(options, {});
});
exports.definePayrollEntryFactory.withTransientFields = defaultTransientFieldValues => options => definePayrollEntryFactoryInternal(options, defaultTransientFieldValues);
function isPointRewardagencyPointMultiplierFactory(x) {
    return x?._factoryFor === "AgencyPointMultiplier";
}
function isPointRewardagencyAdminFactory(x) {
    return x?._factoryFor === "AgencyAdmin";
}
function isPointRewardagencyFactory(x) {
    return x?._factoryFor === "Agency";
}
function isPointRewarddailyTipFactory(x) {
    return x?._factoryFor === "DailyTip";
}
function isPointRewardprofileFactory(x) {
    return x?._factoryFor === "Profile";
}
function isPointRewardpostFactory(x) {
    return x?._factoryFor === "Post";
}
function isPointRewardquestFactory(x) {
    return x?._factoryFor === "Quest";
}
function isPointRewardrewardsBankTransactionsFactory(x) {
    return x?._factoryFor === "RewardsBankTransactions";
}
function isPointRewardagencyRewardBalanceFactory(x) {
    return x?._factoryFor === "AgencyRewardBalance";
}
function isPointRewardpointRewardClaimFactory(x) {
    return x?._factoryFor === "PointRewardClaim";
}
function isPointRewardprofilePointBalanceTransactionFactory(x) {
    return x?._factoryFor === "ProfilePointBalance";
}
function isPointRewardeventsFactory(x) {
    return x?._factoryFor === "RewardEvent";
}
function isPointRewardrewardMissionFactory(x) {
    return x?._factoryFor === "RewardMission";
}
function autoGeneratePointRewardScalarsOrEnums({ seq }) {
    return {
        publicId: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "PointReward", fieldName: "publicId", isId: false, isUnique: true, seq }),
        type: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "PointReward", fieldName: "type", isId: false, isUnique: false, seq }),
        points: (0, internal_1.getScalarFieldValueGenerator)().Int({ modelName: "PointReward", fieldName: "points", isId: false, isUnique: false, seq }),
        awarded: (0, internal_1.getScalarFieldValueGenerator)().DateTime({ modelName: "PointReward", fieldName: "awarded", isId: false, isUnique: false, seq })
    };
}
function definePointRewardFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("PointReward", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGeneratePointRewardScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                agencyPointMultiplier: isPointRewardagencyPointMultiplierFactory(defaultData.agencyPointMultiplier) ? {
                    create: await defaultData.agencyPointMultiplier.build()
                } : defaultData.agencyPointMultiplier,
                agencyAdmin: isPointRewardagencyAdminFactory(defaultData.agencyAdmin) ? {
                    create: await defaultData.agencyAdmin.build()
                } : defaultData.agencyAdmin,
                agency: isPointRewardagencyFactory(defaultData.agency) ? {
                    create: await defaultData.agency.build()
                } : defaultData.agency,
                dailyTip: isPointRewarddailyTipFactory(defaultData.dailyTip) ? {
                    create: await defaultData.dailyTip.build()
                } : defaultData.dailyTip,
                profile: isPointRewardprofileFactory(defaultData.profile) ? {
                    create: await defaultData.profile.build()
                } : defaultData.profile,
                post: isPointRewardpostFactory(defaultData.post) ? {
                    create: await defaultData.post.build()
                } : defaultData.post,
                quest: isPointRewardquestFactory(defaultData.quest) ? {
                    create: await defaultData.quest.build()
                } : defaultData.quest,
                rewardsBankTransactions: isPointRewardrewardsBankTransactionsFactory(defaultData.rewardsBankTransactions) ? {
                    create: await defaultData.rewardsBankTransactions.build()
                } : defaultData.rewardsBankTransactions,
                agencyRewardBalance: isPointRewardagencyRewardBalanceFactory(defaultData.agencyRewardBalance) ? {
                    create: await defaultData.agencyRewardBalance.build()
                } : defaultData.agencyRewardBalance,
                pointRewardClaim: isPointRewardpointRewardClaimFactory(defaultData.pointRewardClaim) ? {
                    create: await defaultData.pointRewardClaim.build()
                } : defaultData.pointRewardClaim,
                profilePointBalanceTransaction: isPointRewardprofilePointBalanceTransactionFactory(defaultData.profilePointBalanceTransaction) ? {
                    create: await defaultData.profilePointBalanceTransaction.build()
                } : defaultData.profilePointBalanceTransaction,
                events: isPointRewardeventsFactory(defaultData.events) ? {
                    create: await defaultData.events.build()
                } : defaultData.events,
                rewardMission: isPointRewardrewardMissionFactory(defaultData.rewardMission) ? {
                    create: await defaultData.rewardMission.build()
                } : defaultData.rewardMission
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().pointReward.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "PointReward",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link PointReward} model.
 *
 * @param options
 * @returns factory {@link PointRewardFactoryInterface}
 */
exports.definePointRewardFactory = ((options) => {
    return definePointRewardFactoryInternal(options, {});
});
exports.definePointRewardFactory.withTransientFields = defaultTransientFieldValues => options => definePointRewardFactoryInternal(options, defaultTransientFieldValues);
function isPointRewardClaimPointRewardFactory(x) {
    return x?._factoryFor === "PointReward";
}
function autoGeneratePointRewardClaimScalarsOrEnums({ seq }) {
    return {
        publicId: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "PointRewardClaim", fieldName: "publicId", isId: false, isUnique: true, seq }),
        claimedAt: (0, internal_1.getScalarFieldValueGenerator)().DateTime({ modelName: "PointRewardClaim", fieldName: "claimedAt", isId: false, isUnique: false, seq })
    };
}
function definePointRewardClaimFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("PointRewardClaim", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGeneratePointRewardClaimScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                PointReward: isPointRewardClaimPointRewardFactory(defaultData.PointReward) ? {
                    create: await defaultData.PointReward.build()
                } : defaultData.PointReward
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().pointRewardClaim.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "PointRewardClaim",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link PointRewardClaim} model.
 *
 * @param options
 * @returns factory {@link PointRewardClaimFactoryInterface}
 */
exports.definePointRewardClaimFactory = ((options) => {
    return definePointRewardClaimFactoryInternal(options, {});
});
exports.definePointRewardClaimFactory.withTransientFields = defaultTransientFieldValues => options => definePointRewardClaimFactoryInternal(options, defaultTransientFieldValues);
function isPopupBanneragencyFactory(x) {
    return x?._factoryFor === "Agency";
}
function autoGeneratePopupBannerScalarsOrEnums({ seq }) {
    return {
        recurrence: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "PopupBanner", fieldName: "recurrence", isId: false, isUnique: false, seq }),
        imageUrl: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "PopupBanner", fieldName: "imageUrl", isId: false, isUnique: false, seq })
    };
}
function definePopupBannerFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("PopupBanner", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGeneratePopupBannerScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                agency: isPopupBanneragencyFactory(defaultData.agency) ? {
                    create: await defaultData.agency.build()
                } : defaultData.agency
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().popupBanner.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "PopupBanner",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link PopupBanner} model.
 *
 * @param options
 * @returns factory {@link PopupBannerFactoryInterface}
 */
exports.definePopupBannerFactory = ((options) => {
    return definePopupBannerFactoryInternal(options, {});
});
exports.definePopupBannerFactory.withTransientFields = defaultTransientFieldValues => options => definePopupBannerFactoryInternal(options, defaultTransientFieldValues);
function isPostgiftCardRedeemFactory(x) {
    return x?._factoryFor === "GiftCardRedeem";
}
function isPostpointRewardFactory(x) {
    return x?._factoryFor === "PointReward";
}
function isPostagencyAdminFactory(x) {
    return x?._factoryFor === "AgencyAdmin";
}
function isPostprofileFactory(x) {
    return x?._factoryFor === "Profile";
}
function isPostquestFactory(x) {
    return x?._factoryFor === "Quest";
}
function autoGeneratePostScalarsOrEnums({ seq }) {
    return {
        publicId: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "Post", fieldName: "publicId", isId: false, isUnique: true, seq }),
        type: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "Post", fieldName: "type", isId: false, isUnique: false, seq })
    };
}
function definePostFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("Post", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGeneratePostScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver ?? {});
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                giftCardRedeem: isPostgiftCardRedeemFactory(defaultData.giftCardRedeem) ? {
                    create: await defaultData.giftCardRedeem.build()
                } : defaultData.giftCardRedeem,
                pointReward: isPostpointRewardFactory(defaultData.pointReward) ? {
                    create: await defaultData.pointReward.build()
                } : defaultData.pointReward,
                agencyAdmin: isPostagencyAdminFactory(defaultData.agencyAdmin) ? {
                    create: await defaultData.agencyAdmin.build()
                } : defaultData.agencyAdmin,
                profile: isPostprofileFactory(defaultData.profile) ? {
                    create: await defaultData.profile.build()
                } : defaultData.profile,
                quest: isPostquestFactory(defaultData.quest) ? {
                    create: await defaultData.quest.build()
                } : defaultData.quest
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().post.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "Post",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link Post} model.
 *
 * @param options
 * @returns factory {@link PostFactoryInterface}
 */
exports.definePostFactory = ((options) => {
    return definePostFactoryInternal(options ?? {}, {});
});
exports.definePostFactory.withTransientFields = defaultTransientFieldValues => options => definePostFactoryInternal(options ?? {}, defaultTransientFieldValues);
function isPostAgencyLocationagencyLocationFactory(x) {
    return x?._factoryFor === "AgencyLocation";
}
function isPostAgencyLocationpostFactory(x) {
    return x?._factoryFor === "Post";
}
function autoGeneratePostAgencyLocationScalarsOrEnums({ seq }) {
    return {};
}
function definePostAgencyLocationFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("PostAgencyLocation", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGeneratePostAgencyLocationScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                agencyLocation: isPostAgencyLocationagencyLocationFactory(defaultData.agencyLocation) ? {
                    create: await defaultData.agencyLocation.build()
                } : defaultData.agencyLocation,
                post: isPostAgencyLocationpostFactory(defaultData.post) ? {
                    create: await defaultData.post.build()
                } : defaultData.post
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().postAgencyLocation.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "PostAgencyLocation",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link PostAgencyLocation} model.
 *
 * @param options
 * @returns factory {@link PostAgencyLocationFactoryInterface}
 */
exports.definePostAgencyLocationFactory = ((options) => {
    return definePostAgencyLocationFactoryInternal(options, {});
});
exports.definePostAgencyLocationFactory.withTransientFields = defaultTransientFieldValues => options => definePostAgencyLocationFactoryInternal(options, defaultTransientFieldValues);
function isPostLikeagencyAdminFactory(x) {
    return x?._factoryFor === "AgencyAdmin";
}
function isPostLikeprofileFactory(x) {
    return x?._factoryFor === "Profile";
}
function isPostLikepostFactory(x) {
    return x?._factoryFor === "Post";
}
function autoGeneratePostLikeScalarsOrEnums({ seq }) {
    return {
        publicId: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "PostLike", fieldName: "publicId", isId: false, isUnique: true, seq })
    };
}
function definePostLikeFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("PostLike", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGeneratePostLikeScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                agencyAdmin: isPostLikeagencyAdminFactory(defaultData.agencyAdmin) ? {
                    create: await defaultData.agencyAdmin.build()
                } : defaultData.agencyAdmin,
                profile: isPostLikeprofileFactory(defaultData.profile) ? {
                    create: await defaultData.profile.build()
                } : defaultData.profile,
                post: isPostLikepostFactory(defaultData.post) ? {
                    create: await defaultData.post.build()
                } : defaultData.post
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().postLike.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "PostLike",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link PostLike} model.
 *
 * @param options
 * @returns factory {@link PostLikeFactoryInterface}
 */
exports.definePostLikeFactory = ((options) => {
    return definePostLikeFactoryInternal(options, {});
});
exports.definePostLikeFactory.withTransientFields = defaultTransientFieldValues => options => definePostLikeFactoryInternal(options, defaultTransientFieldValues);
function isIntegrationProfileprofileFactory(x) {
    return x?._factoryFor === "Profile";
}
function isIntegrationProfilesponsorFactory(x) {
    return x?._factoryFor === "Agency";
}
function isIntegrationProfileemrFactory(x) {
    return x?._factoryFor === "Emr";
}
function autoGenerateIntegrationProfileScalarsOrEnums({ seq }) {
    return {
        phoneNumber: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "IntegrationProfile", fieldName: "phoneNumber", isId: false, isUnique: false, seq }),
        onboardingDate: (0, internal_1.getScalarFieldValueGenerator)().DateTime({ modelName: "IntegrationProfile", fieldName: "onboardingDate", isId: false, isUnique: false, seq })
    };
}
function defineIntegrationProfileFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("IntegrationProfile", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateIntegrationProfileScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                profile: isIntegrationProfileprofileFactory(defaultData.profile) ? {
                    create: await defaultData.profile.build()
                } : defaultData.profile,
                sponsor: isIntegrationProfilesponsorFactory(defaultData.sponsor) ? {
                    create: await defaultData.sponsor.build()
                } : defaultData.sponsor,
                emr: isIntegrationProfileemrFactory(defaultData.emr) ? {
                    create: await defaultData.emr.build()
                } : defaultData.emr
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().integrationProfile.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "IntegrationProfile",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link IntegrationProfile} model.
 *
 * @param options
 * @returns factory {@link IntegrationProfileFactoryInterface}
 */
exports.defineIntegrationProfileFactory = ((options) => {
    return defineIntegrationProfileFactoryInternal(options, {});
});
exports.defineIntegrationProfileFactory.withTransientFields = defaultTransientFieldValues => options => defineIntegrationProfileFactoryInternal(options, defaultTransientFieldValues);
function isProfileagencyFactory(x) {
    return x?._factoryFor === "Agency";
}
function isProfileagencyLocationFactory(x) {
    return x?._factoryFor === "AgencyLocation";
}
function isProfileprofileGroupFactory(x) {
    return x?._factoryFor === "ProfileGroup";
}
function isProfileprofileTeamFactory(x) {
    return x?._factoryFor === "ProfileTeam";
}
function isProfilereferralReferralReferencedIdToProfileFactory(x) {
    return x?._factoryFor === "Referral";
}
function isProfileemrFactory(x) {
    return x?._factoryFor === "Emr";
}
function autoGenerateProfileScalarsOrEnums({ seq }) {
    return {
        publicId: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "Profile", fieldName: "publicId", isId: false, isUnique: true, seq }),
        firstName: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "Profile", fieldName: "firstName", isId: false, isUnique: false, seq }),
        surname: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "Profile", fieldName: "surname", isId: false, isUnique: false, seq }),
        phoneNumber: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "Profile", fieldName: "phoneNumber", isId: false, isUnique: true, seq }),
        language: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "Profile", fieldName: "language", isId: false, isUnique: false, seq }),
        timezone: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "Profile", fieldName: "timezone", isId: false, isUnique: false, seq }),
        onboardingDate: (0, internal_1.getScalarFieldValueGenerator)().DateTime({ modelName: "Profile", fieldName: "onboardingDate", isId: false, isUnique: false, seq }),
        lastFeedVisit: (0, internal_1.getScalarFieldValueGenerator)().DateTime({ modelName: "Profile", fieldName: "lastFeedVisit", isId: false, isUnique: false, seq })
    };
}
function defineProfileFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("Profile", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateProfileScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                agency: isProfileagencyFactory(defaultData.agency) ? {
                    create: await defaultData.agency.build()
                } : defaultData.agency,
                agencyLocation: isProfileagencyLocationFactory(defaultData.agencyLocation) ? {
                    create: await defaultData.agencyLocation.build()
                } : defaultData.agencyLocation,
                profileGroup: isProfileprofileGroupFactory(defaultData.profileGroup) ? {
                    create: await defaultData.profileGroup.build()
                } : defaultData.profileGroup,
                profileTeam: isProfileprofileTeamFactory(defaultData.profileTeam) ? {
                    create: await defaultData.profileTeam.build()
                } : defaultData.profileTeam,
                referralReferralReferencedIdToProfile: isProfilereferralReferralReferencedIdToProfileFactory(defaultData.referralReferralReferencedIdToProfile) ? {
                    create: await defaultData.referralReferralReferencedIdToProfile.build()
                } : defaultData.referralReferralReferencedIdToProfile,
                emr: isProfileemrFactory(defaultData.emr) ? {
                    create: await defaultData.emr.build()
                } : defaultData.emr
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().profile.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "Profile",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link Profile} model.
 *
 * @param options
 * @returns factory {@link ProfileFactoryInterface}
 */
exports.defineProfileFactory = ((options) => {
    return defineProfileFactoryInternal(options, {});
});
exports.defineProfileFactory.withTransientFields = defaultTransientFieldValues => options => defineProfileFactoryInternal(options, defaultTransientFieldValues);
function isProfileFreezePeriodsagencyFactory(x) {
    return x?._factoryFor === "Agency";
}
function isProfileFreezePeriodsprofileFactory(x) {
    return x?._factoryFor === "Profile";
}
function autoGenerateProfileFreezePeriodsScalarsOrEnums({ seq }) {
    return {
        publicId: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "ProfileFreezePeriods", fieldName: "publicId", isId: false, isUnique: true, seq }),
        frozenAt: (0, internal_1.getScalarFieldValueGenerator)().DateTime({ modelName: "ProfileFreezePeriods", fieldName: "frozenAt", isId: false, isUnique: false, seq })
    };
}
function defineProfileFreezePeriodsFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("ProfileFreezePeriods", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateProfileFreezePeriodsScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                agency: isProfileFreezePeriodsagencyFactory(defaultData.agency) ? {
                    create: await defaultData.agency.build()
                } : defaultData.agency,
                profile: isProfileFreezePeriodsprofileFactory(defaultData.profile) ? {
                    create: await defaultData.profile.build()
                } : defaultData.profile
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().profileFreezePeriods.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "ProfileFreezePeriods",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link ProfileFreezePeriods} model.
 *
 * @param options
 * @returns factory {@link ProfileFreezePeriodsFactoryInterface}
 */
exports.defineProfileFreezePeriodsFactory = ((options) => {
    return defineProfileFreezePeriodsFactoryInternal(options, {});
});
exports.defineProfileFreezePeriodsFactory.withTransientFields = defaultTransientFieldValues => options => defineProfileFreezePeriodsFactoryInternal(options, defaultTransientFieldValues);
function isProfileGroupagencyFactory(x) {
    return x?._factoryFor === "Agency";
}
function autoGenerateProfileGroupScalarsOrEnums({ seq }) {
    return {
        name: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "ProfileGroup", fieldName: "name", isId: false, isUnique: false, seq })
    };
}
function defineProfileGroupFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("ProfileGroup", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateProfileGroupScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                agency: isProfileGroupagencyFactory(defaultData.agency) ? {
                    create: await defaultData.agency.build()
                } : defaultData.agency
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().profileGroup.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "ProfileGroup",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link ProfileGroup} model.
 *
 * @param options
 * @returns factory {@link ProfileGroupFactoryInterface}
 */
exports.defineProfileGroupFactory = ((options) => {
    return defineProfileGroupFactoryInternal(options, {});
});
exports.defineProfileGroupFactory.withTransientFields = defaultTransientFieldValues => options => defineProfileGroupFactoryInternal(options, defaultTransientFieldValues);
function isProfileTeamagencyFactory(x) {
    return x?._factoryFor === "Agency";
}
function autoGenerateProfileTeamScalarsOrEnums({ seq }) {
    return {
        name: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "ProfileTeam", fieldName: "name", isId: false, isUnique: false, seq })
    };
}
function defineProfileTeamFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("ProfileTeam", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateProfileTeamScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                agency: isProfileTeamagencyFactory(defaultData.agency) ? {
                    create: await defaultData.agency.build()
                } : defaultData.agency
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().profileTeam.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "ProfileTeam",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link ProfileTeam} model.
 *
 * @param options
 * @returns factory {@link ProfileTeamFactoryInterface}
 */
exports.defineProfileTeamFactory = ((options) => {
    return defineProfileTeamFactoryInternal(options, {});
});
exports.defineProfileTeamFactory.withTransientFields = defaultTransientFieldValues => options => defineProfileTeamFactoryInternal(options, defaultTransientFieldValues);
function isProposalagencyFactory(x) {
    return x?._factoryFor === "Agency";
}
function isProposalproposalContactFactory(x) {
    return x?._factoryFor === "ProposalContact";
}
function autoGenerateProposalScalarsOrEnums({ seq }) {
    return {
        implementationFee: (0, internal_1.getScalarFieldValueGenerator)().Int({ modelName: "Proposal", fieldName: "implementationFee", isId: false, isUnique: false, seq }),
        finalImplementationFee: (0, internal_1.getScalarFieldValueGenerator)().Int({ modelName: "Proposal", fieldName: "finalImplementationFee", isId: false, isUnique: false, seq }),
        averageMonthlyReward: (0, internal_1.getScalarFieldValueGenerator)().Int({ modelName: "Proposal", fieldName: "averageMonthlyReward", isId: false, isUnique: false, seq }),
        totalEmployee: (0, internal_1.getScalarFieldValueGenerator)().Int({ modelName: "Proposal", fieldName: "totalEmployee", isId: false, isUnique: false, seq }),
        closeDate: (0, internal_1.getScalarFieldValueGenerator)().DateTime({ modelName: "Proposal", fieldName: "closeDate", isId: false, isUnique: false, seq }),
        platformFee: (0, internal_1.getScalarFieldValueGenerator)().Float({ modelName: "Proposal", fieldName: "platformFee", isId: false, isUnique: false, seq }),
        finalPlatformFee: (0, internal_1.getScalarFieldValueGenerator)().Float({ modelName: "Proposal", fieldName: "finalPlatformFee", isId: false, isUnique: false, seq }),
        name: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "Proposal", fieldName: "name", isId: false, isUnique: false, seq }),
        owner: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "Proposal", fieldName: "owner", isId: false, isUnique: false, seq })
    };
}
function defineProposalFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("Proposal", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateProposalScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                agency: isProposalagencyFactory(defaultData.agency) ? {
                    create: await defaultData.agency.build()
                } : defaultData.agency,
                proposalContact: isProposalproposalContactFactory(defaultData.proposalContact) ? {
                    create: await defaultData.proposalContact.build()
                } : defaultData.proposalContact
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().proposal.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "Proposal",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link Proposal} model.
 *
 * @param options
 * @returns factory {@link ProposalFactoryInterface}
 */
exports.defineProposalFactory = ((options) => {
    return defineProposalFactoryInternal(options, {});
});
exports.defineProposalFactory.withTransientFields = defaultTransientFieldValues => options => defineProposalFactoryInternal(options, defaultTransientFieldValues);
function autoGenerateProposalContactScalarsOrEnums({ seq }) {
    return {
        name: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "ProposalContact", fieldName: "name", isId: false, isUnique: false, seq }),
        email: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "ProposalContact", fieldName: "email", isId: false, isUnique: false, seq }),
        position: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "ProposalContact", fieldName: "position", isId: false, isUnique: false, seq })
    };
}
function defineProposalContactFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("ProposalContact", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateProposalContactScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver ?? {});
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {};
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().proposalContact.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "ProposalContact",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link ProposalContact} model.
 *
 * @param options
 * @returns factory {@link ProposalContactFactoryInterface}
 */
exports.defineProposalContactFactory = ((options) => {
    return defineProposalContactFactoryInternal(options ?? {}, {});
});
exports.defineProposalContactFactory.withTransientFields = defaultTransientFieldValues => options => defineProposalContactFactoryInternal(options ?? {}, defaultTransientFieldValues);
function isQuestprofileFactory(x) {
    return x?._factoryFor === "Profile";
}
function isQuestpointRewardFactory(x) {
    return x?._factoryFor === "PointReward";
}
function autoGenerateQuestScalarsOrEnums({ seq }) {
    return {
        publicId: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "Quest", fieldName: "publicId", isId: false, isUnique: true, seq }),
        type: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "Quest", fieldName: "type", isId: false, isUnique: false, seq }),
        reward: (0, internal_1.getScalarFieldValueGenerator)().Int({ modelName: "Quest", fieldName: "reward", isId: false, isUnique: false, seq })
    };
}
function defineQuestFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("Quest", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateQuestScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                profile: isQuestprofileFactory(defaultData.profile) ? {
                    create: await defaultData.profile.build()
                } : defaultData.profile,
                pointReward: isQuestpointRewardFactory(defaultData.pointReward) ? {
                    create: await defaultData.pointReward.build()
                } : defaultData.pointReward
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().quest.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "Quest",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link Quest} model.
 *
 * @param options
 * @returns factory {@link QuestFactoryInterface}
 */
exports.defineQuestFactory = ((options) => {
    return defineQuestFactoryInternal(options, {});
});
exports.defineQuestFactory.withTransientFields = defaultTransientFieldValues => options => defineQuestFactoryInternal(options, defaultTransientFieldValues);
function isQuestConfigagencyFactory(x) {
    return x?._factoryFor === "Agency";
}
function autoGenerateQuestConfigScalarsOrEnums({ seq }) {
    return {
        name: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "QuestConfig", fieldName: "name", isId: false, isUnique: false, seq }),
        goal: (0, internal_1.getScalarFieldValueGenerator)().Int({ modelName: "QuestConfig", fieldName: "goal", isId: false, isUnique: false, seq }),
        availability: (0, internal_1.getScalarFieldValueGenerator)().Int({ modelName: "QuestConfig", fieldName: "availability", isId: false, isUnique: false, seq }),
        timeToCompleteInDays: (0, internal_1.getScalarFieldValueGenerator)().Int({ modelName: "QuestConfig", fieldName: "timeToCompleteInDays", isId: false, isUnique: false, seq })
    };
}
function defineQuestConfigFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("QuestConfig", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateQuestConfigScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                agency: isQuestConfigagencyFactory(defaultData.agency) ? {
                    create: await defaultData.agency.build()
                } : defaultData.agency
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().questConfig.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "QuestConfig",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link QuestConfig} model.
 *
 * @param options
 * @returns factory {@link QuestConfigFactoryInterface}
 */
exports.defineQuestConfigFactory = ((options) => {
    return defineQuestConfigFactoryInternal(options, {});
});
exports.defineQuestConfigFactory.withTransientFields = defaultTransientFieldValues => options => defineQuestConfigFactoryInternal(options, defaultTransientFieldValues);
function isRecurringTaskprofileFactory(x) {
    return x?._factoryFor === "Profile";
}
function autoGenerateRecurringTaskScalarsOrEnums({ seq }) {
    return {
        publicId: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "RecurringTask", fieldName: "publicId", isId: false, isUnique: true, seq }),
        type: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "RecurringTask", fieldName: "type", isId: false, isUnique: false, seq })
    };
}
function defineRecurringTaskFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("RecurringTask", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateRecurringTaskScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                profile: isRecurringTaskprofileFactory(defaultData.profile) ? {
                    create: await defaultData.profile.build()
                } : defaultData.profile
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().recurringTask.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "RecurringTask",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link RecurringTask} model.
 *
 * @param options
 * @returns factory {@link RecurringTaskFactoryInterface}
 */
exports.defineRecurringTaskFactory = ((options) => {
    return defineRecurringTaskFactoryInternal(options, {});
});
exports.defineRecurringTaskFactory.withTransientFields = defaultTransientFieldValues => options => defineRecurringTaskFactoryInternal(options, defaultTransientFieldValues);
function isReferralagencyFactory(x) {
    return x?._factoryFor === "Agency";
}
function isReferralagencyJobPostingFactory(x) {
    return x?._factoryFor === "AgencyJobPosting";
}
function isReferralreferencedFactory(x) {
    return x?._factoryFor === "Profile";
}
function isReferralreferrerFactory(x) {
    return x?._factoryFor === "Profile";
}
function isReferralagencyAdminFactory(x) {
    return x?._factoryFor === "AgencyAdmin";
}
function autoGenerateReferralScalarsOrEnums({ seq }) {
    return {
        publicId: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "Referral", fieldName: "publicId", isId: false, isUnique: true, seq }),
        lastUpdate: (0, internal_1.getScalarFieldValueGenerator)().DateTime({ modelName: "Referral", fieldName: "lastUpdate", isId: false, isUnique: false, seq }),
        inviteCode: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "Referral", fieldName: "inviteCode", isId: false, isUnique: true, seq })
    };
}
function defineReferralFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("Referral", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateReferralScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                agency: isReferralagencyFactory(defaultData.agency) ? {
                    create: await defaultData.agency.build()
                } : defaultData.agency,
                agencyJobPosting: isReferralagencyJobPostingFactory(defaultData.agencyJobPosting) ? {
                    create: await defaultData.agencyJobPosting.build()
                } : defaultData.agencyJobPosting,
                referenced: isReferralreferencedFactory(defaultData.referenced) ? {
                    create: await defaultData.referenced.build()
                } : defaultData.referenced,
                referrer: isReferralreferrerFactory(defaultData.referrer) ? {
                    create: await defaultData.referrer.build()
                } : defaultData.referrer,
                agencyAdmin: isReferralagencyAdminFactory(defaultData.agencyAdmin) ? {
                    create: await defaultData.agencyAdmin.build()
                } : defaultData.agencyAdmin
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().referral.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "Referral",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link Referral} model.
 *
 * @param options
 * @returns factory {@link ReferralFactoryInterface}
 */
exports.defineReferralFactory = ((options) => {
    return defineReferralFactoryInternal(options, {});
});
exports.defineReferralFactory.withTransientFields = defaultTransientFieldValues => options => defineReferralFactoryInternal(options, defaultTransientFieldValues);
function isReferralRewardreferralFactory(x) {
    return x?._factoryFor === "Referral";
}
function isReferralRewardprofileFactory(x) {
    return x?._factoryFor === "Profile";
}
function autoGenerateReferralRewardScalarsOrEnums({ seq }) {
    return {
        publicId: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "ReferralReward", fieldName: "publicId", isId: false, isUnique: true, seq }),
        step: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "ReferralReward", fieldName: "step", isId: false, isUnique: false, seq }),
        rewardAmount: (0, internal_1.getScalarFieldValueGenerator)().Int({ modelName: "ReferralReward", fieldName: "rewardAmount", isId: false, isUnique: false, seq })
    };
}
function defineReferralRewardFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("ReferralReward", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateReferralRewardScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                referral: isReferralRewardreferralFactory(defaultData.referral) ? {
                    create: await defaultData.referral.build()
                } : defaultData.referral,
                profile: isReferralRewardprofileFactory(defaultData.profile) ? {
                    create: await defaultData.profile.build()
                } : defaultData.profile
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().referralReward.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "ReferralReward",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link ReferralReward} model.
 *
 * @param options
 * @returns factory {@link ReferralRewardFactoryInterface}
 */
exports.defineReferralRewardFactory = ((options) => {
    return defineReferralRewardFactoryInternal(options, {});
});
exports.defineReferralRewardFactory.withTransientFields = defaultTransientFieldValues => options => defineReferralRewardFactoryInternal(options, defaultTransientFieldValues);
function isReportagencyFactory(x) {
    return x?._factoryFor === "Agency";
}
function autoGenerateReportScalarsOrEnums({ seq }) {
    return {
        type: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "Report", fieldName: "type", isId: false, isUnique: false, seq }),
        name: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "Report", fieldName: "name", isId: false, isUnique: false, seq })
    };
}
function defineReportFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("Report", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateReportScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                agency: isReportagencyFactory(defaultData.agency) ? {
                    create: await defaultData.agency.build()
                } : defaultData.agency
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().report.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "Report",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link Report} model.
 *
 * @param options
 * @returns factory {@link ReportFactoryInterface}
 */
exports.defineReportFactory = ((options) => {
    return defineReportFactoryInternal(options, {});
});
exports.defineReportFactory.withTransientFields = defaultTransientFieldValues => options => defineReportFactoryInternal(options, defaultTransientFieldValues);
function isRewardsBankTransactionsagencyFactory(x) {
    return x?._factoryFor === "Agency";
}
function isRewardsBankTransactionsgiftCardRedeemFactory(x) {
    return x?._factoryFor === "GiftCardRedeem";
}
function isRewardsBankTransactionsinvoiceFactory(x) {
    return x?._factoryFor === "Invoice";
}
function isRewardsBankTransactionspointRewardFactory(x) {
    return x?._factoryFor === "PointReward";
}
function isRewardsBankTransactionsagencyBalanceTransactionFactory(x) {
    return x?._factoryFor === "AgencyRewardBalance";
}
function autoGenerateRewardsBankTransactionsScalarsOrEnums({ seq }) {
    return {
        type: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "RewardsBankTransactions", fieldName: "type", isId: false, isUnique: false, seq }),
        description: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "RewardsBankTransactions", fieldName: "description", isId: false, isUnique: false, seq }),
        amount: (0, internal_1.getScalarFieldValueGenerator)().Int({ modelName: "RewardsBankTransactions", fieldName: "amount", isId: false, isUnique: false, seq })
    };
}
function defineRewardsBankTransactionsFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("RewardsBankTransactions", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateRewardsBankTransactionsScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                agency: isRewardsBankTransactionsagencyFactory(defaultData.agency) ? {
                    create: await defaultData.agency.build()
                } : defaultData.agency,
                giftCardRedeem: isRewardsBankTransactionsgiftCardRedeemFactory(defaultData.giftCardRedeem) ? {
                    create: await defaultData.giftCardRedeem.build()
                } : defaultData.giftCardRedeem,
                invoice: isRewardsBankTransactionsinvoiceFactory(defaultData.invoice) ? {
                    create: await defaultData.invoice.build()
                } : defaultData.invoice,
                pointReward: isRewardsBankTransactionspointRewardFactory(defaultData.pointReward) ? {
                    create: await defaultData.pointReward.build()
                } : defaultData.pointReward,
                agencyBalanceTransaction: isRewardsBankTransactionsagencyBalanceTransactionFactory(defaultData.agencyBalanceTransaction) ? {
                    create: await defaultData.agencyBalanceTransaction.build()
                } : defaultData.agencyBalanceTransaction
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().rewardsBankTransactions.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "RewardsBankTransactions",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link RewardsBankTransactions} model.
 *
 * @param options
 * @returns factory {@link RewardsBankTransactionsFactoryInterface}
 */
exports.defineRewardsBankTransactionsFactory = ((options) => {
    return defineRewardsBankTransactionsFactoryInternal(options, {});
});
exports.defineRewardsBankTransactionsFactory.withTransientFields = defaultTransientFieldValues => options => defineRewardsBankTransactionsFactoryInternal(options, defaultTransientFieldValues);
function autoGenerateRoleScalarsOrEnums({ seq }) {
    return {
        name: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "Role", fieldName: "name", isId: false, isUnique: false, seq })
    };
}
function defineRoleFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("Role", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateRoleScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver ?? {});
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {};
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().role.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "Role",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link Role} model.
 *
 * @param options
 * @returns factory {@link RoleFactoryInterface}
 */
exports.defineRoleFactory = ((options) => {
    return defineRoleFactoryInternal(options ?? {}, {});
});
exports.defineRoleFactory.withTransientFields = defaultTransientFieldValues => options => defineRoleFactoryInternal(options ?? {}, defaultTransientFieldValues);
function isRoleAgencyAdminsagencyAdminFactory(x) {
    return x?._factoryFor === "AgencyAdmin";
}
function isRoleAgencyAdminsroleFactory(x) {
    return x?._factoryFor === "Role";
}
function autoGenerateRoleAgencyAdminsScalarsOrEnums({ seq }) {
    return {};
}
function defineRoleAgencyAdminsFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("RoleAgencyAdmins", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateRoleAgencyAdminsScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                agencyAdmin: isRoleAgencyAdminsagencyAdminFactory(defaultData.agencyAdmin) ? {
                    create: await defaultData.agencyAdmin.build()
                } : defaultData.agencyAdmin,
                role: isRoleAgencyAdminsroleFactory(defaultData.role) ? {
                    create: await defaultData.role.build()
                } : defaultData.role
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            roleId: inputData.roleId,
            agencyAdminId: inputData.agencyAdminId
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().roleAgencyAdmins.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "RoleAgencyAdmins",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link RoleAgencyAdmins} model.
 *
 * @param options
 * @returns factory {@link RoleAgencyAdminsFactoryInterface}
 */
exports.defineRoleAgencyAdminsFactory = ((options) => {
    return defineRoleAgencyAdminsFactoryInternal(options, {});
});
exports.defineRoleAgencyAdminsFactory.withTransientFields = defaultTransientFieldValues => options => defineRoleAgencyAdminsFactoryInternal(options, defaultTransientFieldValues);
function isRoleGroupsgroupFactory(x) {
    return x?._factoryFor === "Group";
}
function isRoleGroupsroleFactory(x) {
    return x?._factoryFor === "Role";
}
function autoGenerateRoleGroupsScalarsOrEnums({ seq }) {
    return {};
}
function defineRoleGroupsFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("RoleGroups", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateRoleGroupsScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                group: isRoleGroupsgroupFactory(defaultData.group) ? {
                    create: await defaultData.group.build()
                } : defaultData.group,
                role: isRoleGroupsroleFactory(defaultData.role) ? {
                    create: await defaultData.role.build()
                } : defaultData.role
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            roleId: inputData.roleId,
            groupId: inputData.groupId
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().roleGroups.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "RoleGroups",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link RoleGroups} model.
 *
 * @param options
 * @returns factory {@link RoleGroupsFactoryInterface}
 */
exports.defineRoleGroupsFactory = ((options) => {
    return defineRoleGroupsFactoryInternal(options, {});
});
exports.defineRoleGroupsFactory.withTransientFields = defaultTransientFieldValues => options => defineRoleGroupsFactoryInternal(options, defaultTransientFieldValues);
function autoGenerateRtpRoutingNumberScalarsOrEnums({ seq }) {
    return {
        routingNumber: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "RtpRoutingNumber", fieldName: "routingNumber", isId: false, isUnique: true, seq })
    };
}
function defineRtpRoutingNumberFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("RtpRoutingNumber", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateRtpRoutingNumberScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver ?? {});
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {};
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().rtpRoutingNumber.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "RtpRoutingNumber",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link RtpRoutingNumber} model.
 *
 * @param options
 * @returns factory {@link RtpRoutingNumberFactoryInterface}
 */
exports.defineRtpRoutingNumberFactory = ((options) => {
    return defineRtpRoutingNumberFactoryInternal(options ?? {}, {});
});
exports.defineRtpRoutingNumberFactory.withTransientFields = defaultTransientFieldValues => options => defineRtpRoutingNumberFactoryInternal(options ?? {}, defaultTransientFieldValues);
function isSmsLinkprofileFactory(x) {
    return x?._factoryFor === "Profile";
}
function autoGenerateSmsLinkScalarsOrEnums({ seq }) {
    return {
        token: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "SmsLink", fieldName: "token", isId: false, isUnique: false, seq })
    };
}
function defineSmsLinkFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("SmsLink", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateSmsLinkScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                profile: isSmsLinkprofileFactory(defaultData.profile) ? {
                    create: await defaultData.profile.build()
                } : defaultData.profile
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().smsLink.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "SmsLink",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link SmsLink} model.
 *
 * @param options
 * @returns factory {@link SmsLinkFactoryInterface}
 */
exports.defineSmsLinkFactory = ((options) => {
    return defineSmsLinkFactoryInternal(options, {});
});
exports.defineSmsLinkFactory.withTransientFields = defaultTransientFieldValues => options => defineSmsLinkFactoryInternal(options, defaultTransientFieldValues);
function isSurveyResultprofileFactory(x) {
    return x?._factoryFor === "Profile";
}
function isSurveyResultsurveyFactory(x) {
    return x?._factoryFor === "Survey";
}
function autoGenerateSurveyResultScalarsOrEnums({ seq }) {
    return {
        publicId: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "SurveyResult", fieldName: "publicId", isId: false, isUnique: true, seq })
    };
}
function defineSurveyResultFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("SurveyResult", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateSurveyResultScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                profile: isSurveyResultprofileFactory(defaultData.profile) ? {
                    create: await defaultData.profile.build()
                } : defaultData.profile,
                survey: isSurveyResultsurveyFactory(defaultData.survey) ? {
                    create: await defaultData.survey.build()
                } : defaultData.survey
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().surveyResult.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "SurveyResult",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link SurveyResult} model.
 *
 * @param options
 * @returns factory {@link SurveyResultFactoryInterface}
 */
exports.defineSurveyResultFactory = ((options) => {
    return defineSurveyResultFactoryInternal(options, {});
});
exports.defineSurveyResultFactory.withTransientFields = defaultTransientFieldValues => options => defineSurveyResultFactoryInternal(options, defaultTransientFieldValues);
function isTaskLogprofileFactory(x) {
    return x?._factoryFor === "Profile";
}
function isTaskLogvisitFactory(x) {
    return x?._factoryFor === "Visit";
}
function autoGenerateTaskLogScalarsOrEnums({ seq }) {
    return {
        externalId: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "TaskLog", fieldName: "externalId", isId: false, isUnique: true, seq }),
        name: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "TaskLog", fieldName: "name", isId: false, isUnique: false, seq }),
        description: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "TaskLog", fieldName: "description", isId: false, isUnique: false, seq }),
        status: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "TaskLog", fieldName: "status", isId: false, isUnique: false, seq }),
        startDate: (0, internal_1.getScalarFieldValueGenerator)().DateTime({ modelName: "TaskLog", fieldName: "startDate", isId: false, isUnique: false, seq })
    };
}
function defineTaskLogFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("TaskLog", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateTaskLogScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                profile: isTaskLogprofileFactory(defaultData.profile) ? {
                    create: await defaultData.profile.build()
                } : defaultData.profile,
                visit: isTaskLogvisitFactory(defaultData.visit) ? {
                    create: await defaultData.visit.build()
                } : defaultData.visit
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().taskLog.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "TaskLog",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link TaskLog} model.
 *
 * @param options
 * @returns factory {@link TaskLogFactoryInterface}
 */
exports.defineTaskLogFactory = ((options) => {
    return defineTaskLogFactoryInternal(options, {});
});
exports.defineTaskLogFactory.withTransientFields = defaultTransientFieldValues => options => defineTaskLogFactoryInternal(options, defaultTransientFieldValues);
function isVisitagencyFactory(x) {
    return x?._factoryFor === "Agency";
}
function isVisitprofileFactory(x) {
    return x?._factoryFor === "Profile";
}
function isVisitemrFactory(x) {
    return x?._factoryFor === "Emr";
}
function autoGenerateVisitScalarsOrEnums({ seq }) {
    return {
        publicId: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "Visit", fieldName: "publicId", isId: false, isUnique: true, seq }),
        externalVisitId: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "Visit", fieldName: "externalVisitId", isId: false, isUnique: true, seq }),
        startDate: (0, internal_1.getScalarFieldValueGenerator)().DateTime({ modelName: "Visit", fieldName: "startDate", isId: false, isUnique: false, seq }),
        endDate: (0, internal_1.getScalarFieldValueGenerator)().DateTime({ modelName: "Visit", fieldName: "endDate", isId: false, isUnique: false, seq })
    };
}
function defineVisitFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("Visit", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateVisitScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                agency: isVisitagencyFactory(defaultData.agency) ? {
                    create: await defaultData.agency.build()
                } : defaultData.agency,
                profile: isVisitprofileFactory(defaultData.profile) ? {
                    create: await defaultData.profile.build()
                } : defaultData.profile,
                emr: isVisitemrFactory(defaultData.emr) ? {
                    create: await defaultData.emr.build()
                } : defaultData.emr
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().visit.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "Visit",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link Visit} model.
 *
 * @param options
 * @returns factory {@link VisitFactoryInterface}
 */
exports.defineVisitFactory = ((options) => {
    return defineVisitFactoryInternal(options, {});
});
exports.defineVisitFactory.withTransientFields = defaultTransientFieldValues => options => defineVisitFactoryInternal(options, defaultTransientFieldValues);
function isVisitChangeLogagencyFactory(x) {
    return x?._factoryFor === "Agency";
}
function autoGenerateVisitChangeLogScalarsOrEnums({ seq }) {
    return {
        externalVisitId: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "VisitChangeLog", fieldName: "externalVisitId", isId: false, isUnique: false, seq }),
        payload: (0, internal_1.getScalarFieldValueGenerator)().Json({ modelName: "VisitChangeLog", fieldName: "payload", isId: false, isUnique: false, seq }),
        createdAt: (0, internal_1.getScalarFieldValueGenerator)().DateTime({ modelName: "VisitChangeLog", fieldName: "createdAt", isId: false, isUnique: false, seq })
    };
}
function defineVisitChangeLogFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("VisitChangeLog", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateVisitChangeLogScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                agency: isVisitChangeLogagencyFactory(defaultData.agency) ? {
                    create: await defaultData.agency.build()
                } : defaultData.agency
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().visitChangeLog.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "VisitChangeLog",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link VisitChangeLog} model.
 *
 * @param options
 * @returns factory {@link VisitChangeLogFactoryInterface}
 */
exports.defineVisitChangeLogFactory = ((options) => {
    return defineVisitChangeLogFactoryInternal(options, {});
});
exports.defineVisitChangeLogFactory.withTransientFields = defaultTransientFieldValues => options => defineVisitChangeLogFactoryInternal(options, defaultTransientFieldValues);
function isIntervalAuditLogagencyFactory(x) {
    return x?._factoryFor === "Agency";
}
function isIntervalAuditLogprofileFactory(x) {
    return x?._factoryFor === "Profile";
}
function autoGenerateIntervalAuditLogScalarsOrEnums({ seq }) {
    return {
        actionDescription: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "IntervalAuditLog", fieldName: "actionDescription", isId: false, isUnique: false, seq }),
        actionByEmail: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "IntervalAuditLog", fieldName: "actionByEmail", isId: false, isUnique: false, seq }),
        actionType: "CREATE"
    };
}
function defineIntervalAuditLogFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("IntervalAuditLog", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateIntervalAuditLogScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver ?? {});
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                agency: isIntervalAuditLogagencyFactory(defaultData.agency) ? {
                    create: await defaultData.agency.build()
                } : defaultData.agency,
                profile: isIntervalAuditLogprofileFactory(defaultData.profile) ? {
                    create: await defaultData.profile.build()
                } : defaultData.profile
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().intervalAuditLog.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "IntervalAuditLog",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link IntervalAuditLog} model.
 *
 * @param options
 * @returns factory {@link IntervalAuditLogFactoryInterface}
 */
exports.defineIntervalAuditLogFactory = ((options) => {
    return defineIntervalAuditLogFactoryInternal(options ?? {}, {});
});
exports.defineIntervalAuditLogFactory.withTransientFields = defaultTransientFieldValues => options => defineIntervalAuditLogFactoryInternal(options ?? {}, defaultTransientFieldValues);
function isProfilePointBalanceprofileFactory(x) {
    return x?._factoryFor === "Profile";
}
function isProfilePointBalancepointRewardFactory(x) {
    return x?._factoryFor === "PointReward";
}
function isProfilePointBalancegiftCardRedeemFactory(x) {
    return x?._factoryFor === "GiftCardRedeem";
}
function isProfilePointBalanceprofilePointBalanceParentFactory(x) {
    return x?._factoryFor === "ProfilePointBalance";
}
function isProfilePointBalanceprofilePointBalanceSunkCostFactory(x) {
    return x?._factoryFor === "ProfilePointBalanceSunkCost";
}
function autoGenerateProfilePointBalanceScalarsOrEnums({ seq }) {
    return {
        amount: (0, internal_1.getScalarFieldValueGenerator)().Int({ modelName: "ProfilePointBalance", fieldName: "amount", isId: false, isUnique: false, seq }),
        transactionType: "Debit",
        source: "PointsIssued"
    };
}
function defineProfilePointBalanceFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("ProfilePointBalance", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateProfilePointBalanceScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                profile: isProfilePointBalanceprofileFactory(defaultData.profile) ? {
                    create: await defaultData.profile.build()
                } : defaultData.profile,
                pointReward: isProfilePointBalancepointRewardFactory(defaultData.pointReward) ? {
                    create: await defaultData.pointReward.build()
                } : defaultData.pointReward,
                giftCardRedeem: isProfilePointBalancegiftCardRedeemFactory(defaultData.giftCardRedeem) ? {
                    create: await defaultData.giftCardRedeem.build()
                } : defaultData.giftCardRedeem,
                profilePointBalanceParent: isProfilePointBalanceprofilePointBalanceParentFactory(defaultData.profilePointBalanceParent) ? {
                    create: await defaultData.profilePointBalanceParent.build()
                } : defaultData.profilePointBalanceParent,
                profilePointBalanceSunkCost: isProfilePointBalanceprofilePointBalanceSunkCostFactory(defaultData.profilePointBalanceSunkCost) ? {
                    create: await defaultData.profilePointBalanceSunkCost.build()
                } : defaultData.profilePointBalanceSunkCost
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().profilePointBalance.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "ProfilePointBalance",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link ProfilePointBalance} model.
 *
 * @param options
 * @returns factory {@link ProfilePointBalanceFactoryInterface}
 */
exports.defineProfilePointBalanceFactory = ((options) => {
    return defineProfilePointBalanceFactoryInternal(options, {});
});
exports.defineProfilePointBalanceFactory.withTransientFields = defaultTransientFieldValues => options => defineProfilePointBalanceFactoryInternal(options, defaultTransientFieldValues);
function isProfilePointBalanceSunkCostprofileFactory(x) {
    return x?._factoryFor === "Profile";
}
function isProfilePointBalanceSunkCostprofilePointBalanceFactory(x) {
    return x?._factoryFor === "ProfilePointBalance";
}
function autoGenerateProfilePointBalanceSunkCostScalarsOrEnums({ seq }) {
    return {
        amountPoints: (0, internal_1.getScalarFieldValueGenerator)().Int({ modelName: "ProfilePointBalanceSunkCost", fieldName: "amountPoints", isId: false, isUnique: false, seq })
    };
}
function defineProfilePointBalanceSunkCostFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("ProfilePointBalanceSunkCost", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateProfilePointBalanceSunkCostScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                profile: isProfilePointBalanceSunkCostprofileFactory(defaultData.profile) ? {
                    create: await defaultData.profile.build()
                } : defaultData.profile,
                profilePointBalance: isProfilePointBalanceSunkCostprofilePointBalanceFactory(defaultData.profilePointBalance) ? {
                    create: await defaultData.profilePointBalance.build()
                } : defaultData.profilePointBalance
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().profilePointBalanceSunkCost.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "ProfilePointBalanceSunkCost",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link ProfilePointBalanceSunkCost} model.
 *
 * @param options
 * @returns factory {@link ProfilePointBalanceSunkCostFactoryInterface}
 */
exports.defineProfilePointBalanceSunkCostFactory = ((options) => {
    return defineProfilePointBalanceSunkCostFactoryInternal(options, {});
});
exports.defineProfilePointBalanceSunkCostFactory.withTransientFields = defaultTransientFieldValues => options => defineProfilePointBalanceSunkCostFactoryInternal(options, defaultTransientFieldValues);
function isProfilePointBalanceRecalculationprofileFactory(x) {
    return x?._factoryFor === "Profile";
}
function autoGenerateProfilePointBalanceRecalculationScalarsOrEnums({ seq }) {
    return {};
}
function defineProfilePointBalanceRecalculationFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("ProfilePointBalanceRecalculation", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateProfilePointBalanceRecalculationScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                profile: isProfilePointBalanceRecalculationprofileFactory(defaultData.profile) ? {
                    create: await defaultData.profile.build()
                } : defaultData.profile
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().profilePointBalanceRecalculation.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "ProfilePointBalanceRecalculation",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link ProfilePointBalanceRecalculation} model.
 *
 * @param options
 * @returns factory {@link ProfilePointBalanceRecalculationFactoryInterface}
 */
exports.defineProfilePointBalanceRecalculationFactory = ((options) => {
    return defineProfilePointBalanceRecalculationFactoryInternal(options, {});
});
exports.defineProfilePointBalanceRecalculationFactory.withTransientFields = defaultTransientFieldValues => options => defineProfilePointBalanceRecalculationFactoryInternal(options, defaultTransientFieldValues);
function isAgencyAdminNotificationagencyAdminFactory(x) {
    return x?._factoryFor === "AgencyAdmin";
}
function autoGenerateAgencyAdminNotificationScalarsOrEnums({ seq }) {
    return {
        method: "EMAIL",
        type: "Transactional"
    };
}
function defineAgencyAdminNotificationFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("AgencyAdminNotification", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateAgencyAdminNotificationScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                agencyAdmin: isAgencyAdminNotificationagencyAdminFactory(defaultData.agencyAdmin) ? {
                    create: await defaultData.agencyAdmin.build()
                } : defaultData.agencyAdmin
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().agencyAdminNotification.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "AgencyAdminNotification",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link AgencyAdminNotification} model.
 *
 * @param options
 * @returns factory {@link AgencyAdminNotificationFactoryInterface}
 */
exports.defineAgencyAdminNotificationFactory = ((options) => {
    return defineAgencyAdminNotificationFactoryInternal(options, {});
});
exports.defineAgencyAdminNotificationFactory.withTransientFields = defaultTransientFieldValues => options => defineAgencyAdminNotificationFactoryInternal(options, defaultTransientFieldValues);
function isInvoicingConfigurationlastInvoiceFactory(x) {
    return x?._factoryFor === "Invoice";
}
function isInvoicingConfigurationagencyFactory(x) {
    return x?._factoryFor === "Agency";
}
function autoGenerateInvoicingConfigurationScalarsOrEnums({ seq }) {
    return {
        targetRewardBudgetCents: (0, internal_1.getScalarFieldValueGenerator)().Int({ modelName: "InvoicingConfiguration", fieldName: "targetRewardBudgetCents", isId: false, isUnique: false, seq }),
        rewardsBudgetPerEmployeeCents: (0, internal_1.getScalarFieldValueGenerator)().Int({ modelName: "InvoicingConfiguration", fieldName: "rewardsBudgetPerEmployeeCents", isId: false, isUnique: false, seq }),
        platformFeePerUserCents: (0, internal_1.getScalarFieldValueGenerator)().Int({ modelName: "InvoicingConfiguration", fieldName: "platformFeePerUserCents", isId: false, isUnique: false, seq })
    };
}
function defineInvoicingConfigurationFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("InvoicingConfiguration", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateInvoicingConfigurationScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                lastInvoice: isInvoicingConfigurationlastInvoiceFactory(defaultData.lastInvoice) ? {
                    create: await defaultData.lastInvoice.build()
                } : defaultData.lastInvoice,
                agency: isInvoicingConfigurationagencyFactory(defaultData.agency) ? {
                    create: await defaultData.agency.build()
                } : defaultData.agency
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().invoicingConfiguration.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "InvoicingConfiguration",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link InvoicingConfiguration} model.
 *
 * @param options
 * @returns factory {@link InvoicingConfigurationFactoryInterface}
 */
exports.defineInvoicingConfigurationFactory = ((options) => {
    return defineInvoicingConfigurationFactoryInternal(options, {});
});
exports.defineInvoicingConfigurationFactory.withTransientFields = defaultTransientFieldValues => options => defineInvoicingConfigurationFactoryInternal(options, defaultTransientFieldValues);
function isAgencyRewardBalanceTargetChangeagencyFactory(x) {
    return x?._factoryFor === "Agency";
}
function autoGenerateAgencyRewardBalanceTargetChangeScalarsOrEnums({ seq }) {
    return {
        targetAmountCents: (0, internal_1.getScalarFieldValueGenerator)().Int({ modelName: "AgencyRewardBalanceTargetChange", fieldName: "targetAmountCents", isId: false, isUnique: false, seq }),
        scheduledAt: (0, internal_1.getScalarFieldValueGenerator)().DateTime({ modelName: "AgencyRewardBalanceTargetChange", fieldName: "scheduledAt", isId: false, isUnique: false, seq })
    };
}
function defineAgencyRewardBalanceTargetChangeFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("AgencyRewardBalanceTargetChange", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateAgencyRewardBalanceTargetChangeScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                agency: isAgencyRewardBalanceTargetChangeagencyFactory(defaultData.agency) ? {
                    create: await defaultData.agency.build()
                } : defaultData.agency
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().agencyRewardBalanceTargetChange.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "AgencyRewardBalanceTargetChange",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link AgencyRewardBalanceTargetChange} model.
 *
 * @param options
 * @returns factory {@link AgencyRewardBalanceTargetChangeFactoryInterface}
 */
exports.defineAgencyRewardBalanceTargetChangeFactory = ((options) => {
    return defineAgencyRewardBalanceTargetChangeFactoryInternal(options, {});
});
exports.defineAgencyRewardBalanceTargetChangeFactory.withTransientFields = defaultTransientFieldValues => options => defineAgencyRewardBalanceTargetChangeFactoryInternal(options, defaultTransientFieldValues);
function isAgencyInvoiceCreditagencyFactory(x) {
    return x?._factoryFor === "Agency";
}
function autoGenerateAgencyInvoiceCreditScalarsOrEnums({ seq }) {
    return {
        balanceCents: (0, internal_1.getScalarFieldValueGenerator)().Int({ modelName: "AgencyInvoiceCredit", fieldName: "balanceCents", isId: false, isUnique: false, seq }),
        transactionAmountCents: (0, internal_1.getScalarFieldValueGenerator)().Int({ modelName: "AgencyInvoiceCredit", fieldName: "transactionAmountCents", isId: false, isUnique: false, seq })
    };
}
function defineAgencyInvoiceCreditFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("AgencyInvoiceCredit", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateAgencyInvoiceCreditScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                agency: isAgencyInvoiceCreditagencyFactory(defaultData.agency) ? {
                    create: await defaultData.agency.build()
                } : defaultData.agency
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().agencyInvoiceCredit.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "AgencyInvoiceCredit",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link AgencyInvoiceCredit} model.
 *
 * @param options
 * @returns factory {@link AgencyInvoiceCreditFactoryInterface}
 */
exports.defineAgencyInvoiceCreditFactory = ((options) => {
    return defineAgencyInvoiceCreditFactoryInternal(options, {});
});
exports.defineAgencyInvoiceCreditFactory.withTransientFields = defaultTransientFieldValues => options => defineAgencyInvoiceCreditFactoryInternal(options, defaultTransientFieldValues);
function isAgencyPointMultiplieragencyFactory(x) {
    return x?._factoryFor === "Agency";
}
function isAgencyPointMultiplierpreviousMultiplierFactory(x) {
    return x?._factoryFor === "AgencyPointMultiplier";
}
function isAgencyPointMultipliernextMultiplierFactory(x) {
    return x?._factoryFor === "AgencyPointMultiplier";
}
function autoGenerateAgencyPointMultiplierScalarsOrEnums({ seq }) {
    return {
        multiple: (0, internal_1.getScalarFieldValueGenerator)().Decimal({ modelName: "AgencyPointMultiplier", fieldName: "multiple", isId: false, isUnique: false, seq }),
        targetPointAmount: (0, internal_1.getScalarFieldValueGenerator)().Int({ modelName: "AgencyPointMultiplier", fieldName: "targetPointAmount", isId: false, isUnique: false, seq }),
        lastTimeFrameIssuedPointAmount: (0, internal_1.getScalarFieldValueGenerator)().Int({ modelName: "AgencyPointMultiplier", fieldName: "lastTimeFrameIssuedPointAmount", isId: false, isUnique: false, seq }),
        timeframeStart: (0, internal_1.getScalarFieldValueGenerator)().DateTime({ modelName: "AgencyPointMultiplier", fieldName: "timeframeStart", isId: false, isUnique: false, seq }),
        timeframeEnd: (0, internal_1.getScalarFieldValueGenerator)().DateTime({ modelName: "AgencyPointMultiplier", fieldName: "timeframeEnd", isId: false, isUnique: false, seq })
    };
}
function defineAgencyPointMultiplierFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("AgencyPointMultiplier", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateAgencyPointMultiplierScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                agency: isAgencyPointMultiplieragencyFactory(defaultData.agency) ? {
                    create: await defaultData.agency.build()
                } : defaultData.agency,
                previousMultiplier: isAgencyPointMultiplierpreviousMultiplierFactory(defaultData.previousMultiplier) ? {
                    create: await defaultData.previousMultiplier.build()
                } : defaultData.previousMultiplier,
                nextMultiplier: isAgencyPointMultipliernextMultiplierFactory(defaultData.nextMultiplier) ? {
                    create: await defaultData.nextMultiplier.build()
                } : defaultData.nextMultiplier
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().agencyPointMultiplier.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "AgencyPointMultiplier",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link AgencyPointMultiplier} model.
 *
 * @param options
 * @returns factory {@link AgencyPointMultiplierFactoryInterface}
 */
exports.defineAgencyPointMultiplierFactory = ((options) => {
    return defineAgencyPointMultiplierFactoryInternal(options, {});
});
exports.defineAgencyPointMultiplierFactory.withTransientFields = defaultTransientFieldValues => options => defineAgencyPointMultiplierFactoryInternal(options, defaultTransientFieldValues);
function isSurveyagencyFactory(x) {
    return x?._factoryFor === "Agency";
}
function autoGenerateSurveyScalarsOrEnums({ seq }) {
    return {
        link: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "Survey", fieldName: "link", isId: false, isUnique: true, seq }),
        title: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "Survey", fieldName: "title", isId: false, isUnique: false, seq }),
        time: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "Survey", fieldName: "time", isId: false, isUnique: false, seq }),
        points: (0, internal_1.getScalarFieldValueGenerator)().Int({ modelName: "Survey", fieldName: "points", isId: false, isUnique: false, seq })
    };
}
function defineSurveyFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("Survey", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateSurveyScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                agency: isSurveyagencyFactory(defaultData.agency) ? {
                    create: await defaultData.agency.build()
                } : defaultData.agency
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().survey.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "Survey",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link Survey} model.
 *
 * @param options
 * @returns factory {@link SurveyFactoryInterface}
 */
exports.defineSurveyFactory = ((options) => {
    return defineSurveyFactoryInternal(options, {});
});
exports.defineSurveyFactory.withTransientFields = defaultTransientFieldValues => options => defineSurveyFactoryInternal(options, defaultTransientFieldValues);
function autoGenerateEmrScalarsOrEnums({ seq }) {
    return {
        identifier: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "Emr", fieldName: "identifier", isId: false, isUnique: true, seq })
    };
}
function defineEmrFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("Emr", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateEmrScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver ?? {});
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {};
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().emr.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "Emr",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link Emr} model.
 *
 * @param options
 * @returns factory {@link EmrFactoryInterface}
 */
exports.defineEmrFactory = ((options) => {
    return defineEmrFactoryInternal(options ?? {}, {});
});
exports.defineEmrFactory.withTransientFields = defaultTransientFieldValues => options => defineEmrFactoryInternal(options ?? {}, defaultTransientFieldValues);
function isProfileUpdateHistoryprofileFactory(x) {
    return x?._factoryFor === "Profile";
}
function autoGenerateProfileUpdateHistoryScalarsOrEnums({ seq }) {
    return {
        updateInfo: (0, internal_1.getScalarFieldValueGenerator)().Json({ modelName: "ProfileUpdateHistory", fieldName: "updateInfo", isId: false, isUnique: false, seq }),
        type: "STATUS_CHANGED",
        updatedAt: (0, internal_1.getScalarFieldValueGenerator)().DateTime({ modelName: "ProfileUpdateHistory", fieldName: "updatedAt", isId: false, isUnique: false, seq })
    };
}
function defineProfileUpdateHistoryFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("ProfileUpdateHistory", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateProfileUpdateHistoryScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                profile: isProfileUpdateHistoryprofileFactory(defaultData.profile) ? {
                    create: await defaultData.profile.build()
                } : defaultData.profile
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().profileUpdateHistory.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "ProfileUpdateHistory",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link ProfileUpdateHistory} model.
 *
 * @param options
 * @returns factory {@link ProfileUpdateHistoryFactoryInterface}
 */
exports.defineProfileUpdateHistoryFactory = ((options) => {
    return defineProfileUpdateHistoryFactoryInternal(options, {});
});
exports.defineProfileUpdateHistoryFactory.withTransientFields = defaultTransientFieldValues => options => defineProfileUpdateHistoryFactoryInternal(options, defaultTransientFieldValues);
function isNotificationSettingagencyFactory(x) {
    return x?._factoryFor === "Agency";
}
function autoGenerateNotificationSettingScalarsOrEnums({ seq }) {
    return {};
}
function defineNotificationSettingFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("NotificationSetting", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateNotificationSettingScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                agency: isNotificationSettingagencyFactory(defaultData.agency) ? {
                    create: await defaultData.agency.build()
                } : defaultData.agency
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().notificationSetting.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "NotificationSetting",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link NotificationSetting} model.
 *
 * @param options
 * @returns factory {@link NotificationSettingFactoryInterface}
 */
exports.defineNotificationSettingFactory = ((options) => {
    return defineNotificationSettingFactoryInternal(options, {});
});
exports.defineNotificationSettingFactory.withTransientFields = defaultTransientFieldValues => options => defineNotificationSettingFactoryInternal(options, defaultTransientFieldValues);
function isNotificationEventagencyFactory(x) {
    return x?._factoryFor === "Agency";
}
function isNotificationEventprofileFactory(x) {
    return x?._factoryFor === "Profile";
}
function autoGenerateNotificationEventScalarsOrEnums({ seq }) {
    return {
        notificationSettingId: (0, internal_1.getScalarFieldValueGenerator)().Int({ modelName: "NotificationEvent", fieldName: "notificationSettingId", isId: false, isUnique: false, seq }),
        type: "EMAIL",
        content: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "NotificationEvent", fieldName: "content", isId: false, isUnique: false, seq })
    };
}
function defineNotificationEventFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("NotificationEvent", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateNotificationEventScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                agency: isNotificationEventagencyFactory(defaultData.agency) ? {
                    create: await defaultData.agency.build()
                } : defaultData.agency,
                profile: isNotificationEventprofileFactory(defaultData.profile) ? {
                    create: await defaultData.profile.build()
                } : defaultData.profile
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().notificationEvent.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "NotificationEvent",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link NotificationEvent} model.
 *
 * @param options
 * @returns factory {@link NotificationEventFactoryInterface}
 */
exports.defineNotificationEventFactory = ((options) => {
    return defineNotificationEventFactoryInternal(options, {});
});
exports.defineNotificationEventFactory.withTransientFields = defaultTransientFieldValues => options => defineNotificationEventFactoryInternal(options, defaultTransientFieldValues);
function isAgencyLocationSpotRewardLedgerAgencyLocationFactory(x) {
    return x?._factoryFor === "AgencyLocation";
}
function autoGenerateAgencyLocationSpotRewardLedgerScalarsOrEnums({ seq }) {
    return {
        previousRewardBalance: (0, internal_1.getScalarFieldValueGenerator)().Int({ modelName: "AgencyLocationSpotRewardLedger", fieldName: "previousRewardBalance", isId: false, isUnique: false, seq }),
        newRewardBalance: (0, internal_1.getScalarFieldValueGenerator)().Int({ modelName: "AgencyLocationSpotRewardLedger", fieldName: "newRewardBalance", isId: false, isUnique: false, seq })
    };
}
function defineAgencyLocationSpotRewardLedgerFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("AgencyLocationSpotRewardLedger", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateAgencyLocationSpotRewardLedgerScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                AgencyLocation: isAgencyLocationSpotRewardLedgerAgencyLocationFactory(defaultData.AgencyLocation) ? {
                    create: await defaultData.AgencyLocation.build()
                } : defaultData.AgencyLocation
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().agencyLocationSpotRewardLedger.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "AgencyLocationSpotRewardLedger",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link AgencyLocationSpotRewardLedger} model.
 *
 * @param options
 * @returns factory {@link AgencyLocationSpotRewardLedgerFactoryInterface}
 */
exports.defineAgencyLocationSpotRewardLedgerFactory = ((options) => {
    return defineAgencyLocationSpotRewardLedgerFactoryInternal(options, {});
});
exports.defineAgencyLocationSpotRewardLedgerFactory.withTransientFields = defaultTransientFieldValues => options => defineAgencyLocationSpotRewardLedgerFactoryInternal(options, defaultTransientFieldValues);
function isAgencyRewardLedgerAgencyRewardFactory(x) {
    return x?._factoryFor === "AgencyReward";
}
function autoGenerateAgencyRewardLedgerScalarsOrEnums({ seq }) {
    return {
        previousRewardBalance: (0, internal_1.getScalarFieldValueGenerator)().Int({ modelName: "AgencyRewardLedger", fieldName: "previousRewardBalance", isId: false, isUnique: false, seq }),
        newRewardBalance: (0, internal_1.getScalarFieldValueGenerator)().Int({ modelName: "AgencyRewardLedger", fieldName: "newRewardBalance", isId: false, isUnique: false, seq })
    };
}
function defineAgencyRewardLedgerFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("AgencyRewardLedger", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateAgencyRewardLedgerScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                AgencyReward: isAgencyRewardLedgerAgencyRewardFactory(defaultData.AgencyReward) ? {
                    create: await defaultData.AgencyReward.build()
                } : defaultData.AgencyReward
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().agencyRewardLedger.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "AgencyRewardLedger",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link AgencyRewardLedger} model.
 *
 * @param options
 * @returns factory {@link AgencyRewardLedgerFactoryInterface}
 */
exports.defineAgencyRewardLedgerFactory = ((options) => {
    return defineAgencyRewardLedgerFactoryInternal(options, {});
});
exports.defineAgencyRewardLedgerFactory.withTransientFields = defaultTransientFieldValues => options => defineAgencyRewardLedgerFactoryInternal(options, defaultTransientFieldValues);
function isNotificationProfileSettingprofileFactory(x) {
    return x?._factoryFor === "Profile";
}
function autoGenerateNotificationProfileSettingScalarsOrEnums({ seq }) {
    return {};
}
function defineNotificationProfileSettingFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("NotificationProfileSetting", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateNotificationProfileSettingScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                profile: isNotificationProfileSettingprofileFactory(defaultData.profile) ? {
                    create: await defaultData.profile.build()
                } : defaultData.profile
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().notificationProfileSetting.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "NotificationProfileSetting",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link NotificationProfileSetting} model.
 *
 * @param options
 * @returns factory {@link NotificationProfileSettingFactoryInterface}
 */
exports.defineNotificationProfileSettingFactory = ((options) => {
    return defineNotificationProfileSettingFactoryInternal(options, {});
});
exports.defineNotificationProfileSettingFactory.withTransientFields = defaultTransientFieldValues => options => defineNotificationProfileSettingFactoryInternal(options, defaultTransientFieldValues);
function isIntegrationSyncEventagencyFactory(x) {
    return x?._factoryFor === "Agency";
}
function autoGenerateIntegrationSyncEventScalarsOrEnums({ seq }) {
    return {
        runAt: (0, internal_1.getScalarFieldValueGenerator)().DateTime({ modelName: "IntegrationSyncEvent", fieldName: "runAt", isId: false, isUnique: false, seq }),
        numSynced: (0, internal_1.getScalarFieldValueGenerator)().Int({ modelName: "IntegrationSyncEvent", fieldName: "numSynced", isId: false, isUnique: false, seq }),
        type: "VISIT"
    };
}
function defineIntegrationSyncEventFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("IntegrationSyncEvent", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateIntegrationSyncEventScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                agency: isIntegrationSyncEventagencyFactory(defaultData.agency) ? {
                    create: await defaultData.agency.build()
                } : defaultData.agency
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().integrationSyncEvent.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "IntegrationSyncEvent",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link IntegrationSyncEvent} model.
 *
 * @param options
 * @returns factory {@link IntegrationSyncEventFactoryInterface}
 */
exports.defineIntegrationSyncEventFactory = ((options) => {
    return defineIntegrationSyncEventFactoryInternal(options, {});
});
exports.defineIntegrationSyncEventFactory.withTransientFields = defaultTransientFieldValues => options => defineIntegrationSyncEventFactoryInternal(options, defaultTransientFieldValues);
function isRewardEventprofileFactory(x) {
    return x?._factoryFor === "Profile";
}
function isRewardEventpointRewardFactory(x) {
    return x?._factoryFor === "PointReward";
}
function isRewardEventmissionFactory(x) {
    return x?._factoryFor === "RewardMission";
}
function isRewardEventrewardConfigFactory(x) {
    return x?._factoryFor === "RewardConfig";
}
function autoGenerateRewardEventScalarsOrEnums({ seq }) {
    return {
        data: (0, internal_1.getScalarFieldValueGenerator)().Json({ modelName: "RewardEvent", fieldName: "data", isId: false, isUnique: false, seq }),
        reference: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "RewardEvent", fieldName: "reference", isId: false, isUnique: true, seq }),
        isValid: (0, internal_1.getScalarFieldValueGenerator)().Boolean({ modelName: "RewardEvent", fieldName: "isValid", isId: false, isUnique: false, seq }),
        eventDate: (0, internal_1.getScalarFieldValueGenerator)().DateTime({ modelName: "RewardEvent", fieldName: "eventDate", isId: false, isUnique: false, seq })
    };
}
function defineRewardEventFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("RewardEvent", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateRewardEventScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                profile: isRewardEventprofileFactory(defaultData.profile) ? {
                    create: await defaultData.profile.build()
                } : defaultData.profile,
                pointReward: isRewardEventpointRewardFactory(defaultData.pointReward) ? {
                    create: await defaultData.pointReward.build()
                } : defaultData.pointReward,
                mission: isRewardEventmissionFactory(defaultData.mission) ? {
                    create: await defaultData.mission.build()
                } : defaultData.mission,
                rewardConfig: isRewardEventrewardConfigFactory(defaultData.rewardConfig) ? {
                    create: await defaultData.rewardConfig.build()
                } : defaultData.rewardConfig
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().rewardEvent.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "RewardEvent",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link RewardEvent} model.
 *
 * @param options
 * @returns factory {@link RewardEventFactoryInterface}
 */
exports.defineRewardEventFactory = ((options) => {
    return defineRewardEventFactoryInternal(options, {});
});
exports.defineRewardEventFactory.withTransientFields = defaultTransientFieldValues => options => defineRewardEventFactoryInternal(options, defaultTransientFieldValues);
function isRewardConfigsponsorFactory(x) {
    return x?._factoryFor === "Agency";
}
function autoGenerateRewardConfigScalarsOrEnums({ seq }) {
    return {
        configData: (0, internal_1.getScalarFieldValueGenerator)().Json({ modelName: "RewardConfig", fieldName: "configData", isId: false, isUnique: false, seq }),
        createdAt: (0, internal_1.getScalarFieldValueGenerator)().DateTime({ modelName: "RewardConfig", fieldName: "createdAt", isId: false, isUnique: false, seq }),
        updatedAt: (0, internal_1.getScalarFieldValueGenerator)().DateTime({ modelName: "RewardConfig", fieldName: "updatedAt", isId: false, isUnique: false, seq }),
        pointAmount: (0, internal_1.getScalarFieldValueGenerator)().Int({ modelName: "RewardConfig", fieldName: "pointAmount", isId: false, isUnique: false, seq }),
        name: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "RewardConfig", fieldName: "name", isId: false, isUnique: false, seq }),
        visibility: "PRIVATE",
        triggerType: "SURVEY"
    };
}
function defineRewardConfigFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("RewardConfig", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateRewardConfigScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                sponsor: isRewardConfigsponsorFactory(defaultData.sponsor) ? {
                    create: await defaultData.sponsor.build()
                } : defaultData.sponsor
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().rewardConfig.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "RewardConfig",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link RewardConfig} model.
 *
 * @param options
 * @returns factory {@link RewardConfigFactoryInterface}
 */
exports.defineRewardConfigFactory = ((options) => {
    return defineRewardConfigFactoryInternal(options, {});
});
exports.defineRewardConfigFactory.withTransientFields = defaultTransientFieldValues => options => defineRewardConfigFactoryInternal(options, defaultTransientFieldValues);
function autoGenerateRewardTemplateScalarsOrEnums({ seq }) {
    return {
        createdAt: (0, internal_1.getScalarFieldValueGenerator)().DateTime({ modelName: "RewardTemplate", fieldName: "createdAt", isId: false, isUnique: false, seq }),
        updatedAt: (0, internal_1.getScalarFieldValueGenerator)().DateTime({ modelName: "RewardTemplate", fieldName: "updatedAt", isId: false, isUnique: false, seq })
    };
}
function defineRewardTemplateFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("RewardTemplate", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateRewardTemplateScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver ?? {});
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {};
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().rewardTemplate.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "RewardTemplate",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link RewardTemplate} model.
 *
 * @param options
 * @returns factory {@link RewardTemplateFactoryInterface}
 */
exports.defineRewardTemplateFactory = ((options) => {
    return defineRewardTemplateFactoryInternal(options ?? {}, {});
});
exports.defineRewardTemplateFactory.withTransientFields = defaultTransientFieldValues => options => defineRewardTemplateFactoryInternal(options ?? {}, defaultTransientFieldValues);
function isRewardMissionprofileFactory(x) {
    return x?._factoryFor === "Profile";
}
function isRewardMissionmissionConfigFactory(x) {
    return x?._factoryFor === "MissionConfig";
}
function isRewardMissionpointRewardFactory(x) {
    return x?._factoryFor === "PointReward";
}
function autoGenerateRewardMissionScalarsOrEnums({ seq }) {
    return {
        createdAt: (0, internal_1.getScalarFieldValueGenerator)().DateTime({ modelName: "RewardMission", fieldName: "createdAt", isId: false, isUnique: false, seq }),
        updatedAt: (0, internal_1.getScalarFieldValueGenerator)().DateTime({ modelName: "RewardMission", fieldName: "updatedAt", isId: false, isUnique: false, seq }),
        startDate: (0, internal_1.getScalarFieldValueGenerator)().DateTime({ modelName: "RewardMission", fieldName: "startDate", isId: false, isUnique: false, seq })
    };
}
function defineRewardMissionFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("RewardMission", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateRewardMissionScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                profile: isRewardMissionprofileFactory(defaultData.profile) ? {
                    create: await defaultData.profile.build()
                } : defaultData.profile,
                missionConfig: isRewardMissionmissionConfigFactory(defaultData.missionConfig) ? {
                    create: await defaultData.missionConfig.build()
                } : defaultData.missionConfig,
                pointReward: isRewardMissionpointRewardFactory(defaultData.pointReward) ? {
                    create: await defaultData.pointReward.build()
                } : defaultData.pointReward
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().rewardMission.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "RewardMission",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link RewardMission} model.
 *
 * @param options
 * @returns factory {@link RewardMissionFactoryInterface}
 */
exports.defineRewardMissionFactory = ((options) => {
    return defineRewardMissionFactoryInternal(options, {});
});
exports.defineRewardMissionFactory.withTransientFields = defaultTransientFieldValues => options => defineRewardMissionFactoryInternal(options, defaultTransientFieldValues);
function autoGenerateMissionConfigScalarsOrEnums({ seq }) {
    return {
        missionEvaluationConfig: (0, internal_1.getScalarFieldValueGenerator)().Json({ modelName: "MissionConfig", fieldName: "missionEvaluationConfig", isId: false, isUnique: false, seq }),
        expiryConfig: (0, internal_1.getScalarFieldValueGenerator)().Json({ modelName: "MissionConfig", fieldName: "expiryConfig", isId: false, isUnique: false, seq }),
        createdAt: (0, internal_1.getScalarFieldValueGenerator)().DateTime({ modelName: "MissionConfig", fieldName: "createdAt", isId: false, isUnique: false, seq }),
        updatedAt: (0, internal_1.getScalarFieldValueGenerator)().DateTime({ modelName: "MissionConfig", fieldName: "updatedAt", isId: false, isUnique: false, seq }),
        pointAmount: (0, internal_1.getScalarFieldValueGenerator)().Int({ modelName: "MissionConfig", fieldName: "pointAmount", isId: false, isUnique: false, seq })
    };
}
function defineMissionConfigFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("MissionConfig", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateMissionConfigScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver ?? {});
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {};
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().missionConfig.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "MissionConfig",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link MissionConfig} model.
 *
 * @param options
 * @returns factory {@link MissionConfigFactoryInterface}
 */
exports.defineMissionConfigFactory = ((options) => {
    return defineMissionConfigFactoryInternal(options ?? {}, {});
});
exports.defineMissionConfigFactory.withTransientFields = defaultTransientFieldValues => options => defineMissionConfigFactoryInternal(options ?? {}, defaultTransientFieldValues);
function isReconciliationProfileprofileFactory(x) {
    return x?._factoryFor === "Profile";
}
function autoGenerateReconciliationProfileScalarsOrEnums({ seq }) {
    return {
        externalProfileId: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "ReconciliationProfile", fieldName: "externalProfileId", isId: false, isUnique: true, seq }),
        agencyId: (0, internal_1.getScalarFieldValueGenerator)().Int({ modelName: "ReconciliationProfile", fieldName: "agencyId", isId: false, isUnique: true, seq }),
        firstName: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "ReconciliationProfile", fieldName: "firstName", isId: false, isUnique: false, seq }),
        surname: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "ReconciliationProfile", fieldName: "surname", isId: false, isUnique: false, seq }),
        phoneNumber: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "ReconciliationProfile", fieldName: "phoneNumber", isId: false, isUnique: true, seq }),
        onboardingDate: (0, internal_1.getScalarFieldValueGenerator)().DateTime({ modelName: "ReconciliationProfile", fieldName: "onboardingDate", isId: false, isUnique: false, seq })
    };
}
function defineReconciliationProfileFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("ReconciliationProfile", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateReconciliationProfileScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                profile: isReconciliationProfileprofileFactory(defaultData.profile) ? {
                    create: await defaultData.profile.build()
                } : defaultData.profile
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().reconciliationProfile.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "ReconciliationProfile",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link ReconciliationProfile} model.
 *
 * @param options
 * @returns factory {@link ReconciliationProfileFactoryInterface}
 */
exports.defineReconciliationProfileFactory = ((options) => {
    return defineReconciliationProfileFactoryInternal(options, {});
});
exports.defineReconciliationProfileFactory.withTransientFields = defaultTransientFieldValues => options => defineReconciliationProfileFactoryInternal(options, defaultTransientFieldValues);
function isSpotRewardTypeagencyFactory(x) {
    return x?._factoryFor === "Agency";
}
function isSpotRewardTypeagencyAdminFactory(x) {
    return x?._factoryFor === "AgencyAdmin";
}
function autoGenerateSpotRewardTypeScalarsOrEnums({ seq }) {
    return {
        name: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "SpotRewardType", fieldName: "name", isId: false, isUnique: false, seq }),
        pointValue: (0, internal_1.getScalarFieldValueGenerator)().Int({ modelName: "SpotRewardType", fieldName: "pointValue", isId: false, isUnique: false, seq })
    };
}
function defineSpotRewardTypeFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("SpotRewardType", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateSpotRewardTypeScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                agency: isSpotRewardTypeagencyFactory(defaultData.agency) ? {
                    create: await defaultData.agency.build()
                } : defaultData.agency,
                agencyAdmin: isSpotRewardTypeagencyAdminFactory(defaultData.agencyAdmin) ? {
                    create: await defaultData.agencyAdmin.build()
                } : defaultData.agencyAdmin
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().spotRewardType.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "SpotRewardType",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link SpotRewardType} model.
 *
 * @param options
 * @returns factory {@link SpotRewardTypeFactoryInterface}
 */
exports.defineSpotRewardTypeFactory = ((options) => {
    return defineSpotRewardTypeFactoryInternal(options, {});
});
exports.defineSpotRewardTypeFactory.withTransientFields = defaultTransientFieldValues => options => defineSpotRewardTypeFactoryInternal(options, defaultTransientFieldValues);
function isAdmissionWithReferralDataagencyFactory(x) {
    return x?._factoryFor === "Agency";
}
function isAdmissionWithReferralDataprofileFactory(x) {
    return x?._factoryFor === "Profile";
}
function isAdmissionWithReferralDataemrFactory(x) {
    return x?._factoryFor === "Emr";
}
function autoGenerateAdmissionWithReferralDataScalarsOrEnums({ seq }) {
    return {
        externalId: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "AdmissionWithReferralData", fieldName: "externalId", isId: false, isUnique: true, seq }),
        admissionDate: (0, internal_1.getScalarFieldValueGenerator)().DateTime({ modelName: "AdmissionWithReferralData", fieldName: "admissionDate", isId: false, isUnique: false, seq })
    };
}
function defineAdmissionWithReferralDataFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("AdmissionWithReferralData", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateAdmissionWithReferralDataScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                agency: isAdmissionWithReferralDataagencyFactory(defaultData.agency) ? {
                    create: await defaultData.agency.build()
                } : defaultData.agency,
                profile: isAdmissionWithReferralDataprofileFactory(defaultData.profile) ? {
                    create: await defaultData.profile.build()
                } : defaultData.profile,
                emr: isAdmissionWithReferralDataemrFactory(defaultData.emr) ? {
                    create: await defaultData.emr.build()
                } : defaultData.emr
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().admissionWithReferralData.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "AdmissionWithReferralData",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link AdmissionWithReferralData} model.
 *
 * @param options
 * @returns factory {@link AdmissionWithReferralDataFactoryInterface}
 */
exports.defineAdmissionWithReferralDataFactory = ((options) => {
    return defineAdmissionWithReferralDataFactoryInternal(options, {});
});
exports.defineAdmissionWithReferralDataFactory.withTransientFields = defaultTransientFieldValues => options => defineAdmissionWithReferralDataFactoryInternal(options, defaultTransientFieldValues);
function isCommunicationNotesagencyFactory(x) {
    return x?._factoryFor === "Agency";
}
function isCommunicationNotesprofileFactory(x) {
    return x?._factoryFor === "Profile";
}
function isCommunicationNotesemrFactory(x) {
    return x?._factoryFor === "Emr";
}
function autoGenerateCommunicationNotesScalarsOrEnums({ seq }) {
    return {
        externalId: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "CommunicationNotes", fieldName: "externalId", isId: false, isUnique: true, seq }),
        communicationDate: (0, internal_1.getScalarFieldValueGenerator)().DateTime({ modelName: "CommunicationNotes", fieldName: "communicationDate", isId: false, isUnique: false, seq }),
        communicationType: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "CommunicationNotes", fieldName: "communicationType", isId: false, isUnique: false, seq }),
        communicationNotes: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "CommunicationNotes", fieldName: "communicationNotes", isId: false, isUnique: false, seq })
    };
}
function defineCommunicationNotesFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("CommunicationNotes", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateCommunicationNotesScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                agency: isCommunicationNotesagencyFactory(defaultData.agency) ? {
                    create: await defaultData.agency.build()
                } : defaultData.agency,
                profile: isCommunicationNotesprofileFactory(defaultData.profile) ? {
                    create: await defaultData.profile.build()
                } : defaultData.profile,
                emr: isCommunicationNotesemrFactory(defaultData.emr) ? {
                    create: await defaultData.emr.build()
                } : defaultData.emr
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().communicationNotes.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "CommunicationNotes",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link CommunicationNotes} model.
 *
 * @param options
 * @returns factory {@link CommunicationNotesFactoryInterface}
 */
exports.defineCommunicationNotesFactory = ((options) => {
    return defineCommunicationNotesFactoryInternal(options, {});
});
exports.defineCommunicationNotesFactory.withTransientFields = defaultTransientFieldValues => options => defineCommunicationNotesFactoryInternal(options, defaultTransientFieldValues);
function isVerbalOrderSentagencyFactory(x) {
    return x?._factoryFor === "Agency";
}
function isVerbalOrderSentprofileFactory(x) {
    return x?._factoryFor === "Profile";
}
function isVerbalOrderSentemrFactory(x) {
    return x?._factoryFor === "Emr";
}
function autoGenerateVerbalOrderSentScalarsOrEnums({ seq }) {
    return {
        externalId: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "VerbalOrderSent", fieldName: "externalId", isId: false, isUnique: true, seq }),
        verbalOrderDate: (0, internal_1.getScalarFieldValueGenerator)().DateTime({ modelName: "VerbalOrderSent", fieldName: "verbalOrderDate", isId: false, isUnique: false, seq }),
        verbalOrderEffectiveDate: (0, internal_1.getScalarFieldValueGenerator)().DateTime({ modelName: "VerbalOrderSent", fieldName: "verbalOrderEffectiveDate", isId: false, isUnique: false, seq }),
        verbalOrderStatus: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "VerbalOrderSent", fieldName: "verbalOrderStatus", isId: false, isUnique: false, seq }),
        verbalOrderSentDate: (0, internal_1.getScalarFieldValueGenerator)().DateTime({ modelName: "VerbalOrderSent", fieldName: "verbalOrderSentDate", isId: false, isUnique: false, seq })
    };
}
function defineVerbalOrderSentFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("VerbalOrderSent", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateVerbalOrderSentScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                agency: isVerbalOrderSentagencyFactory(defaultData.agency) ? {
                    create: await defaultData.agency.build()
                } : defaultData.agency,
                profile: isVerbalOrderSentprofileFactory(defaultData.profile) ? {
                    create: await defaultData.profile.build()
                } : defaultData.profile,
                emr: isVerbalOrderSentemrFactory(defaultData.emr) ? {
                    create: await defaultData.emr.build()
                } : defaultData.emr
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().verbalOrderSent.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "VerbalOrderSent",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link VerbalOrderSent} model.
 *
 * @param options
 * @returns factory {@link VerbalOrderSentFactoryInterface}
 */
exports.defineVerbalOrderSentFactory = ((options) => {
    return defineVerbalOrderSentFactoryInternal(options, {});
});
exports.defineVerbalOrderSentFactory.withTransientFields = defaultTransientFieldValues => options => defineVerbalOrderSentFactoryInternal(options, defaultTransientFieldValues);
function isAgencyAdminSpotRewardLedgeragencyAdminFactory(x) {
    return x?._factoryFor === "AgencyAdmin";
}
function autoGenerateAgencyAdminSpotRewardLedgerScalarsOrEnums({ seq }) {
    return {
        previousRewardBalance: (0, internal_1.getScalarFieldValueGenerator)().Int({ modelName: "AgencyAdminSpotRewardLedger", fieldName: "previousRewardBalance", isId: false, isUnique: false, seq }),
        newRewardBalance: (0, internal_1.getScalarFieldValueGenerator)().Int({ modelName: "AgencyAdminSpotRewardLedger", fieldName: "newRewardBalance", isId: false, isUnique: false, seq })
    };
}
function defineAgencyAdminSpotRewardLedgerFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("AgencyAdminSpotRewardLedger", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateAgencyAdminSpotRewardLedgerScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                agencyAdmin: isAgencyAdminSpotRewardLedgeragencyAdminFactory(defaultData.agencyAdmin) ? {
                    create: await defaultData.agencyAdmin.build()
                } : defaultData.agencyAdmin
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().agencyAdminSpotRewardLedger.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "AgencyAdminSpotRewardLedger",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link AgencyAdminSpotRewardLedger} model.
 *
 * @param options
 * @returns factory {@link AgencyAdminSpotRewardLedgerFactoryInterface}
 */
exports.defineAgencyAdminSpotRewardLedgerFactory = ((options) => {
    return defineAgencyAdminSpotRewardLedgerFactoryInternal(options, {});
});
exports.defineAgencyAdminSpotRewardLedgerFactory.withTransientFields = defaultTransientFieldValues => options => defineAgencyAdminSpotRewardLedgerFactoryInternal(options, defaultTransientFieldValues);
function autoGenerateUnenrolledProfilesScalarsOrEnums({ seq }) {
    return {
        profileId: (0, internal_1.getScalarFieldValueGenerator)().Int({ modelName: "UnenrolledProfiles", fieldName: "profileId", isId: true, isUnique: false, seq }),
        firstName: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "UnenrolledProfiles", fieldName: "firstName", isId: false, isUnique: false, seq }),
        phoneNumber: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "UnenrolledProfiles", fieldName: "phoneNumber", isId: false, isUnique: false, seq }),
        agencyName: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "UnenrolledProfiles", fieldName: "agencyName", isId: false, isUnique: false, seq }),
        url: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "UnenrolledProfiles", fieldName: "url", isId: false, isUnique: false, seq }),
        pointName: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "UnenrolledProfiles", fieldName: "pointName", isId: false, isUnique: false, seq })
    };
}
function defineUnenrolledProfilesFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("UnenrolledProfiles", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateUnenrolledProfilesScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver ?? {});
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {};
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            profileId: inputData.profileId
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().unenrolledProfiles.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "UnenrolledProfiles",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link UnenrolledProfiles} model.
 *
 * @param options
 * @returns factory {@link UnenrolledProfilesFactoryInterface}
 */
exports.defineUnenrolledProfilesFactory = ((options) => {
    return defineUnenrolledProfilesFactoryInternal(options ?? {}, {});
});
exports.defineUnenrolledProfilesFactory.withTransientFields = defaultTransientFieldValues => options => defineUnenrolledProfilesFactoryInternal(options ?? {}, defaultTransientFieldValues);
function autoGenerateUsersNoGiftCardRedeemedScalarsOrEnums({ seq }) {
    return {
        profileId: (0, internal_1.getScalarFieldValueGenerator)().Int({ modelName: "UsersNoGiftCardRedeemed", fieldName: "profileId", isId: true, isUnique: false, seq }),
        firstName: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "UsersNoGiftCardRedeemed", fieldName: "firstName", isId: false, isUnique: false, seq }),
        pointName: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "UsersNoGiftCardRedeemed", fieldName: "pointName", isId: false, isUnique: false, seq }),
        phoneNumber: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "UsersNoGiftCardRedeemed", fieldName: "phoneNumber", isId: false, isUnique: false, seq }),
        rewardsUrl: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "UsersNoGiftCardRedeemed", fieldName: "rewardsUrl", isId: false, isUnique: false, seq })
    };
}
function defineUsersNoGiftCardRedeemedFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("UsersNoGiftCardRedeemed", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateUsersNoGiftCardRedeemedScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver ?? {});
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {};
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            profileId: inputData.profileId
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().usersNoGiftCardRedeemed.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "UsersNoGiftCardRedeemed",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link UsersNoGiftCardRedeemed} model.
 *
 * @param options
 * @returns factory {@link UsersNoGiftCardRedeemedFactoryInterface}
 */
exports.defineUsersNoGiftCardRedeemedFactory = ((options) => {
    return defineUsersNoGiftCardRedeemedFactoryInternal(options ?? {}, {});
});
exports.defineUsersNoGiftCardRedeemedFactory.withTransientFields = defaultTransientFieldValues => options => defineUsersNoGiftCardRedeemedFactoryInternal(options ?? {}, defaultTransientFieldValues);
function autoGenerateCampaignConfigurationScalarsOrEnums({ seq }) {
    return {
        name: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "CampaignConfiguration", fieldName: "name", isId: false, isUnique: false, seq }),
        type: "REENGAGEMENT",
        messageTemplate: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "CampaignConfiguration", fieldName: "messageTemplate", isId: false, isUnique: false, seq }),
        templateSuffix: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "CampaignConfiguration", fieldName: "templateSuffix", isId: false, isUnique: false, seq }),
        profilesTableName: (0, internal_1.getScalarFieldValueGenerator)().String({ modelName: "CampaignConfiguration", fieldName: "profilesTableName", isId: false, isUnique: false, seq })
    };
}
function defineCampaignConfigurationFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("CampaignConfiguration", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateCampaignConfigurationScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver ?? {});
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {};
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().campaignConfiguration.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "CampaignConfiguration",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link CampaignConfiguration} model.
 *
 * @param options
 * @returns factory {@link CampaignConfigurationFactoryInterface}
 */
exports.defineCampaignConfigurationFactory = ((options) => {
    return defineCampaignConfigurationFactoryInternal(options ?? {}, {});
});
exports.defineCampaignConfigurationFactory.withTransientFields = defaultTransientFieldValues => options => defineCampaignConfigurationFactoryInternal(options ?? {}, defaultTransientFieldValues);
function isCampaignAgencycampaignFactory(x) {
    return x?._factoryFor === "CampaignConfiguration";
}
function isCampaignAgencyagencyFactory(x) {
    return x?._factoryFor === "Agency";
}
function autoGenerateCampaignAgencyScalarsOrEnums({ seq }) {
    return {};
}
function defineCampaignAgencyFactoryInternal({ defaultData: defaultDataResolver, onAfterBuild, onBeforeCreate, onAfterCreate, traits: traitsDefs = {} }, defaultTransientFieldValues) {
    const getFactoryWithTraits = (traitKeys = []) => {
        const seqKey = {};
        const getSeq = () => (0, internal_1.getSequenceCounter)(seqKey);
        const screen = (0, internal_1.createScreener)("CampaignAgency", modelFieldDefinitions);
        const handleAfterBuild = (0, internal_1.createCallbackChain)([
            onAfterBuild,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterBuild),
        ]);
        const handleBeforeCreate = (0, internal_1.createCallbackChain)([
            ...traitKeys.slice().reverse().map(traitKey => traitsDefs[traitKey]?.onBeforeCreate),
            onBeforeCreate,
        ]);
        const handleAfterCreate = (0, internal_1.createCallbackChain)([
            onAfterCreate,
            ...traitKeys.map(traitKey => traitsDefs[traitKey]?.onAfterCreate),
        ]);
        const build = async (inputData = {}) => {
            const seq = getSeq();
            const requiredScalarData = autoGenerateCampaignAgencyScalarsOrEnums({ seq });
            const resolveValue = (0, internal_1.normalizeResolver)(defaultDataResolver);
            const [transientFields, filteredInputData] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const resolverInput = { seq, ...transientFields };
            const defaultData = await traitKeys.reduce(async (queue, traitKey) => {
                const acc = await queue;
                const resolveTraitValue = (0, internal_1.normalizeResolver)(traitsDefs[traitKey]?.data ?? {});
                const traitData = await resolveTraitValue(resolverInput);
                return {
                    ...acc,
                    ...traitData,
                };
            }, resolveValue(resolverInput));
            const defaultAssociations = {
                campaign: isCampaignAgencycampaignFactory(defaultData.campaign) ? {
                    create: await defaultData.campaign.build()
                } : defaultData.campaign,
                agency: isCampaignAgencyagencyFactory(defaultData.agency) ? {
                    create: await defaultData.agency.build()
                } : defaultData.agency
            };
            const data = { ...requiredScalarData, ...defaultData, ...defaultAssociations, ...filteredInputData };
            await handleAfterBuild(data, transientFields);
            return data;
        };
        const buildList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => build(data)));
        const pickForConnect = (inputData) => ({
            id: inputData.id
        });
        const create = async (inputData = {}) => {
            const [transientFields] = (0, internal_1.destructure)(defaultTransientFieldValues, inputData);
            const data = await build(inputData).then(screen);
            await handleBeforeCreate(data, transientFields);
            const createdData = await getClient().campaignAgency.create({ data });
            await handleAfterCreate(createdData, transientFields);
            return createdData;
        };
        const createList = (...args) => Promise.all((0, internal_1.normalizeList)(...args).map(data => create(data)));
        const createForConnect = (inputData = {}) => create(inputData).then(pickForConnect);
        return {
            _factoryFor: "CampaignAgency",
            build,
            buildList,
            buildCreateInput: build,
            pickForConnect,
            create,
            createList,
            createForConnect,
        };
    };
    const factory = getFactoryWithTraits();
    const useTraits = (name, ...names) => {
        return getFactoryWithTraits([name, ...names]);
    };
    return {
        ...factory,
        use: useTraits,
    };
}
/**
 * Define factory for {@link CampaignAgency} model.
 *
 * @param options
 * @returns factory {@link CampaignAgencyFactoryInterface}
 */
exports.defineCampaignAgencyFactory = ((options) => {
    return defineCampaignAgencyFactoryInternal(options, {});
});
exports.defineCampaignAgencyFactory.withTransientFields = defaultTransientFieldValues => options => defineCampaignAgencyFactoryInternal(options, defaultTransientFieldValues);
