import { apiRoutes } from '@services/constants';
import type { InvoiceResponse } from '@services/invoices/types';
import { get, post } from '../../utils/fetch';
import type { InvoiceObject } from './types';

export const getAllPendingInvoices = async (): Promise<InvoiceObject[]> => {
  const response = await get<InvoiceResponse>(apiRoutes.invoices.getAllPending);
  return response.invoices;
};

export const payAllInvoices = async (): Promise<boolean> => {
  const response = await post<{}, { scheduled: boolean }>(apiRoutes.invoices.submitAutoPay, {});
  return response.scheduled;
};
