import PageTitle from '@components/PageTitle';
import { Delete, Restore } from '@mui/icons-material';
import {
  getKeyValue,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tooltip,
} from '@nextui-org/react';
import { useInfiniteScroll } from '@nextui-org/use-infinite-scroll';
import { useAsyncList } from '@react-stately/data';
import { getJobPosts, updateJobPostDeleteStatus } from '@services/jobPosts';
import type { JobPostObject } from '@services/jobPosts/types';
import { formatDate } from '@utils/date';
import type { FC, Key } from 'react';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

const JobPost: FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);

  const params = useParams();
  const { agencyId } = params;

  const jobPostColumns = [
    {
      key: 'title',
      label: 'TITLE',
      align: 'start',
    },
    {
      key: 'created',
      label: 'CREATED',
      align: 'start',
    },
    {
      key: 'status',
      label: 'STATUS',
      align: 'start',
    },
    {
      key: 'location',
      label: 'LOCATION',
      align: 'start',
    },
    {
      key: 'agency',
      label: 'AGENCY',
      align: 'start',
    },
    {
      key: 'actions',
      label: 'ACTIONS',
      align: 'center',
    },
  ];

  const list = useAsyncList<JobPostObject>({
    async load({ cursor }) {
      if (cursor) {
        setIsLoading(false);
      }

      setIsLoading(true);

      const res = await getJobPosts(cursor ?? '0', '25', agencyId ?? '');

      setIsLoading(false);

      setHasMore(res.length === 25);

      return {
        items: res,
        cursor: cursor ? (parseInt(cursor, 10) + 1).toString() : '2',
      };
    },
  });

  const [loaderRef, scrollerRef] = useInfiniteScroll({
    hasMore,
    onLoadMore: list.loadMore,
  });

  const handleToggleDeleteStatus = async (jobPost: JobPostObject) => {
    await updateJobPostDeleteStatus(jobPost.publicId, !jobPost.isDeleted);
    list.reload();
  };

  const renderCell = useCallback((jobPost: JobPostObject, columnKey: Key) => {
    switch (columnKey) {
      case 'status':
        return jobPost.acceptingApplications ? 'Open' : 'Closed';

      case 'created':
        return formatDate(jobPost.created);

      case 'location':
        return jobPost.agencyLocation?.name;

      case 'agency':
        return jobPost.agency?.name;

      case 'actions':
        return (
          <div className="flex justify-center">
            <Tooltip content={jobPost.isDeleted ? 'Restore' : 'Delete'}>
              {jobPost.isDeleted ? (
                <Restore
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleToggleDeleteStatus(jobPost)}
                />
              ) : (
                <Delete
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleToggleDeleteStatus(jobPost)}
                />
              )}
            </Tooltip>
          </div>
        );

      default:
        return getKeyValue(jobPost, columnKey.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="flex flex-row justify-between">
        <PageTitle>Job Post</PageTitle>
      </div>
      <Table
        baseRef={scrollerRef}
        aria-label="Agencies table"
        isStriped
        shadow="md"
        bottomContent={
          hasMore ? (
            <div className="flex justify-center w-full">
              <Spinner ref={loaderRef} color="primary" />
            </div>
          ) : null
        }
        classNames={{
          base: 'max-h-[70vh] overflow-auto p-3',
          table: 'max-h-[50vh]',
        }}
      >
        <TableHeader columns={jobPostColumns}>
          {(column) => (
            <TableColumn
              align={column.align as 'center' | 'start'}
              className="text-white bg-purple-500"
              key={column.key}
            >
              {column.label}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody
          isLoading={isLoading}
          items={list.items}
          loadingContent={<Spinner color="secondary" />}
          emptyContent={'No rows to display.'}
        >
          {(item) => (
            <TableRow key={item.id} className={item.isDeleted ? 'opacity-50' : ''}>
              {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
};

export default JobPost;
