import MoreHorizOutlined from '@mui/icons-material/MoreHorizOutlined';
import { IconButton, Menu, MenuItem } from '@mui/material';
import React from 'react';
import { AgencyAutoPayModal } from './AgencyAutoPayModal';

export const AgenciesMenu = () => {
  const [autoPayOpen, setAutoPayOpen] = React.useState(false);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const anchorEl = React.useRef<HTMLButtonElement>(null);

  return (
    <>
      <IconButton onClick={() => setMenuOpen(true)} ref={anchorEl}>
        <MoreHorizOutlined />
      </IconButton>
      <Menu
        id="basic-menu"
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        style={{ marginTop: '4px' }}
        anchorEl={anchorEl.current}
      >
        <MenuItem onClick={() => setAutoPayOpen(true)}>Auto Pay Invoices</MenuItem>
      </Menu>
      <AgencyAutoPayModal open={autoPayOpen} setOpen={setAutoPayOpen} />
    </>
  );
};
