import PageTitle from '@components/PageTitle';
import { Info, Search } from '@mui/icons-material';
import {
  Button,
  getKeyValue,
  Input,
  Spinner,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tabs,
  Tooltip,
} from '@nextui-org/react';
import { CampaignStatus } from '@perry/db';
import { useAsyncList } from '@react-stately/data';
import { getCampaigns } from '@services/campaigns';
import type { CampaignConfiguration } from '@services/campaigns/types';
import type { ChangeEvent, Key } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { campaignsColumns } from './utils';

const Campaigns = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState<string | undefined>();
  const [filter, setFilter] = useState('');

  const navigate = useNavigate();

  const list = useAsyncList<CampaignConfiguration>({
    load: async () => {
      const res = await getCampaigns(status, filter);

      setIsLoading(false);
      return {
        items: res.campaigns,
      };
    },
  });

  const onChangeFilter = (e: ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
  };

  const onChangeStatus = (key: Key) => {
    if (key === 'all') {
      setStatus(undefined);
      return;
    }

    setStatus(key === 'active' ? CampaignStatus.ACTIVE : CampaignStatus.INACTIVE);
  };

  useEffect(() => {
    setIsLoading(true);
    list.reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, filter]);

  const renderCell = useCallback(
    (user: CampaignConfiguration, columnKey: Key) => {
      switch (columnKey) {
        case 'actions':
          return (
            <div>
              <Tooltip content="Details">
                <Info
                  color="secondary"
                  style={{ cursor: 'pointer' }}
                  onClick={() => navigate(`/campaigns/${user.id}`)}
                />
              </Tooltip>
            </div>
          );

        default:
          return getKeyValue(user, columnKey.toString());
      }
    },
    [navigate],
  );

  return (
    <>
      <PageTitle>Campaigns</PageTitle>
      <div className="flex content-center gap-5 my-5">
        <Input
          aria-label="Search"
          size="sm"
          startContent={<Search />}
          onChange={onChangeFilter}
          placeholder="Search..."
          className="w-72"
        />
        <Button
          color="secondary"
          onClick={() => navigate('/campaigns/new')}
          className="bg-theme-primary text-white"
        >
          New Campaign
        </Button>
      </div>
      <Tabs
        color="secondary"
        className="pl-3"
        aria-label="Tabs colors"
        size="sm"
        onSelectionChange={onChangeStatus}
      >
        <Tab key="all" title={'All'} />
        <Tab key="active" title={'Active'} />
        <Tab key="inactive" title={'Inactive'} />
      </Tabs>
      <Table
        aria-label="Users table"
        isStriped
        shadow="md"
        bottomContent={null}
        classNames={{
          base: 'max-h-[70vh] overflow-auto p-3',
          table: 'max-h-[50vh]',
        }}
      >
        <TableHeader columns={campaignsColumns}>
          {(column) => (
            <TableColumn className="text-white bg-purple-500" key={column.key}>
              {column.label}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody
          isLoading={isLoading}
          items={list.items as CampaignConfiguration[]}
          loadingContent={<Spinner color="secondary" />}
          emptyContent={'No rows to display.'}
        >
          {(item: CampaignConfiguration) => (
            <TableRow key={item.id}>
              {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
};

export default Campaigns;
