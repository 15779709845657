import PageTitle from '@components/PageTitle';
import { Button, Checkbox, Input, Select, SelectItem, Spinner, Textarea } from '@nextui-org/react';
import { CampaignStatus } from '@perry/db';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAgencies } from '../../../services/agencies';
import type { AgencyObject } from '../../../services/agencies/types';
import {
  createCampaign,
  getCampaign,
  getKpiTables,
  updateCampaign,
} from '../../../services/campaigns';
import type { CampaignConfigurationWithoutId } from '../../../services/campaigns/types';
import { CampaignType } from '../../../services/campaigns/types';

const CampaignDetails = () => {
  const { id } = useParams<{ id: string }>();
  const isNewCampaign = !id;
  const [campaign, setCampaign] = useState<CampaignConfigurationWithoutId>();
  const [sendToAllAgencies, setSendToAllAgencies] = useState<boolean>(false);
  const [kpiTables, setKpiTables] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState<CampaignConfigurationWithoutId>({
    name: '',
    status: CampaignStatus.ACTIVE,
    type: CampaignType.REENGAGEMENT,
    daysBetweenSends: 0,
    templateSuffix: '',
    messageTemplate: '',
    agencies: [],
    profilesTableName: '',
    abTestMessage: '',
    abTestEnabled: false,
  });

  const [agencies, setAgencies] = useState<AgencyObject[]>([]);

  const navigate = useNavigate();

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
      | string[]
      | boolean,
  ) => {
    if (typeof e === 'boolean') {
      setFormData((prev) => ({
        ...prev,
        abTestEnabled: e,
      }));
    } else if (Array.isArray(e)) {
      setFormData((prev) => ({
        ...prev,
        agencies: e.filter(Boolean).map((agencyId) => {
          const agency = agencies.find((a) => a.id.toString() === agencyId);
          return {
            id: Number(agencyId),
            name: agency?.name || '',
            publicId: agency?.publicId || '',
          };
        }),
      }));
    } else {
      const { name, value } = e.target;
      setFormData((prev) => ({
        ...prev,
        [name]: name === 'daysBetweenSends' ? Number(value) : value,
      }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (isNewCampaign) {
        await createCampaign(formData);
        toast.success('Campaign created successfully');
      } else {
        await updateCampaign(id, formData, sendToAllAgencies);
        toast.success('Campaign updated successfully');
      }
      navigate('/campaigns');
    } catch (error) {
      toast.error(isNewCampaign ? 'Error creating campaign' : 'Error updating campaign');
    }
  };

  const handleCancel = () => {
    navigate('/campaigns');
  };

  const getSortedAgencies = () => {
    const selectedAgencies = agencies
      .filter((agency) => formData.agencies.some((selected) => selected.id === agency.id))
      .sort((a, b) => a.id - b.id);

    const unselectedAgencies = agencies
      .filter((agency) => !formData.agencies.some((selected) => selected.id === agency.id))
      .sort((a, b) => a.id - b.id);

    return [...selectedAgencies, ...unselectedAgencies];
  };

  const getSelectedCampaign = useCallback(async () => {
    if (!id) return;

    const response = await getCampaign(id);
    if (response) setCampaign(response.campaign);
  }, [id]);

  useEffect(() => {
    if (!isNewCampaign) {
      getSelectedCampaign();
    } else {
      setIsLoading(false);
    }
  }, [getSelectedCampaign, isNewCampaign]);

  useEffect(() => {
    if (campaign) {
      setFormData({
        name: campaign.name,
        status: campaign.status as CampaignConfigurationWithoutId['status'],
        type: campaign.type,
        messageTemplate: campaign.messageTemplate,
        daysBetweenSends: campaign.daysBetweenSends,
        templateSuffix: campaign.templateSuffix,
        agencies: campaign.agencies,
        profilesTableName: campaign.profilesTableName,
        abTestEnabled: campaign.abTestEnabled,
        abTestMessage: campaign.abTestMessage,
      });
      setIsLoading(false);
    }
  }, [campaign]);

  const fetchAgencies = useCallback(async () => {
    const agenciesFetched = await getAgencies(0, '200', 'active');
    if (agenciesFetched) {
      setAgencies(agenciesFetched);
    }
  }, []);

  useEffect(() => {
    fetchAgencies();
  }, [fetchAgencies]);

  const fetchKpiTables = useCallback(async () => {
    try {
      const response = await getKpiTables();
      if (response?.tables) {
        setKpiTables(response.tables);
      }
    } catch (error) {
      toast.error('Error fetching KPI tables');
    }
  }, []);

  useEffect(() => {
    fetchKpiTables();
  }, [fetchKpiTables]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner color="secondary" />
      </div>
    );
  }

  return (
    <>
      <PageTitle>{isNewCampaign ? 'Create Campaign' : 'Campaign Edit Details'}</PageTitle>
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-12 gap-4 mt-6">
          <div className="col-span-12">
            <Input
              label="Name"
              type="text"
              labelPlacement="outside"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-span-6">
            <Select
              label="Status"
              labelPlacement="outside"
              name="status"
              selectedKeys={[formData.status]}
              onChange={handleChange}
              required
            >
              <SelectItem value={CampaignStatus.ACTIVE} key={CampaignStatus.ACTIVE}>
                Active
              </SelectItem>
              <SelectItem value={CampaignStatus.INACTIVE} key={CampaignStatus.INACTIVE}>
                Inactive
              </SelectItem>
            </Select>
          </div>
          <div className="col-span-6">
            <Input
              label="Type"
              type="text"
              labelPlacement="outside"
              isDisabled
              name="type"
              value={formData.type}
            />
          </div>
          <div className="col-span-12">
            <Textarea
              label="Message Template"
              labelPlacement="outside"
              name="messageTemplate"
              value={formData.messageTemplate}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-span-12 mb-4">
            <Checkbox
              name="abTestEnabled"
              isSelected={formData.abTestEnabled}
              onValueChange={handleChange}
            >
              AB Test Enabled
            </Checkbox>
          </div>
          {formData.abTestEnabled && (
            <div className="col-span-12">
              <Textarea
                label="AB Test Message Template"
                labelPlacement="outside"
                name="abTestMessage"
                value={formData.abTestMessage}
                onChange={handleChange}
                required
              />
            </div>
          )}
          <div className="col-span-6">
            <Input
              label="Days Between Send"
              type="number"
              labelPlacement="outside"
              name="daysBetweenSends"
              value={formData.daysBetweenSends.toString()}
              onChange={handleChange}
            />
          </div>
          <div className="col-span-6">
            <Input
              label="Template Suffix"
              type="text"
              labelPlacement="outside"
              name="templateSuffix"
              value={formData.templateSuffix}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-span-6">
            <Select
              label="Agencies"
              labelPlacement="outside"
              selectionMode="multiple"
              selectedKeys={formData.agencies.map((agency) => agency.id.toString())}
              onChange={(keys) => handleChange(keys.target.value.split(','))}
              isDisabled={sendToAllAgencies}
              required
            >
              {getSortedAgencies().map((agency) => (
                <SelectItem key={agency.id.toString()} value={agency.id.toString()}>
                  {`${agency.id} - ${agency.name}`}
                </SelectItem>
              ))}
            </Select>
            <div className="mt-2">
              <Checkbox
                name="sendToAllAgencies"
                isSelected={sendToAllAgencies}
                onValueChange={setSendToAllAgencies}
              >
                Select all agencies
              </Checkbox>
            </div>
          </div>
          <div className="col-span-6">
            <Select
              label="Table Name"
              labelPlacement="outside"
              name="profilesTableName"
              selectedKeys={[formData.profilesTableName]}
              onChange={handleChange}
              required
              isDisabled={!isNewCampaign}
            >
              {kpiTables.map((tableName) => (
                <SelectItem key={tableName} value={tableName}>
                  {tableName}
                </SelectItem>
              ))}
            </Select>
          </div>
        </div>
        <div className="flex justify-center gap-6 mt-6">
          <Button type="button" variant="bordered" className="w-56" onClick={handleCancel}>
            Cancel
          </Button>
          <Button type="submit" variant="solid" className="w-56 bg-theme-primary text-white">
            {isNewCampaign ? 'Create' : 'Save'}
          </Button>
        </div>
      </form>
    </>
  );
};

export default CampaignDetails;
